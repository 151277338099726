import React from 'react'

const ProgramaDoce = () => {
    return(
        <div className='container-page-html'>
            <div>
                Hola y bienvenido a DOCE,
            </div>
            <div>
                Como sabes nuestra intención es darte las mejores herramientas posibles para que consigas tus objetivos. Aquí vas a encontrar el protocolo básico de DOCE.
            </div>
            <div>
                Por favor: léelo completo para interiorizar el funcionamiento del Plan y poderle sacar el mayor partido posible.
            </div>
            <div style={{color: '#98070a'}}>
                <b>
                    EVALUACIÓN CADA DOS SEMANAS
                </b>
            </div>
            <div>
                Para conocer tu progreso y poder darte un servicio más cercano, haremos evaluaciones cada dos semanas. ¿Cómo será el procedimiento?
            </div>
            <div>
                <ol>
                    <li>
                        Os llegará una notificación en el email y otra en la app para rellenar los datos necesarios en esta evaluación.
                    </li>
                    <li>
                        ¿Qué datos os pediremos? Depende de cada fase, pero lo común a todas será: peso, medida de cintura, NEAT, adherencia al plan nutricional y fotos.
                    </li>
                </ol>
            </div>
            <div>
                Una vez que lo recibamos, el equipo estudiaremos tu perfil y tomaremos las medidas necesarias para seguir progresando al mejor ritmo posible.  Tardaremos entre 48 y 72 horas laborables en darte nuestro feedback.
            </div>
            <div style={{color: '#98070a'}}>
                <b>
                  GESTIONES DE FINES DE FASE Y EVALUACIONES CADA DOS SEMANAS
                </b>
            </div>
            <div>
                Las evaluaciones se gestionan y responden lunes, miércoles y viernes. Lo hacemos de esta forma para ser más eficientes con nuestro tiempo, y poder trabajar exclusivamente sobre vuestras evaluaciones el resto de días de la semana.
            </div>
            <div>
                <b>Pro tip</b>: si estáis a punto de finalizar vuestra fase y queréis empezar la siguiente fase el lunes siguiente, es vital que rellenéis la evaluación de fin de fase el jueves por la mañana. De esta forma nos dará tiempo a poder procesar vuestro perfil antes del lunes.
            </div>
            <div>
                Si lo enviáis el viernes, o en fin de semana, no será procesado hasta el lunes. Por favor, recordad que no ofrecemos un servicio de atención al cliente 24/7.
            </div>
            <div style={{color: '#98070a'}}>
                <b>
                 CONSULTA DE DUDAS
                </b>
            </div>
            <div>
                En la app puedes encontrar la sección "Ponte en contacto con nosotros" con tres opciones:
            </div>
            <div>
                <ol>
                    <li>
                        Preguntas y respuestas: aquí podrás encontrar resueltas la gran mayoría de preguntas típicas de un Espartano o Guerrera de DOCE.
                    </li>
                    <li>
                        Grupo de Facebook: es la vía principal de resoluciones de dudas. Es fundamental que cualquier duda sobre entrenamiento, nutrición, NEAT, etc, sea a través del grupo, y por favor recordad que el grupo tiene un “buscador” dónde podéis buscar palabras clave para encontrar cualquier post que se haya subido desde su
                    </li>
                    <li>
                        Whatsapp: solo se debe usar en caso de urgencia técnica.
                    </li>
                </ol>
            </div>
            <div style={{color: '#98070a'}}>
                <b>
                    ¿NO HE RECIBIDO RESPUESTA EN 48 A 72 HORAS LABORABLES?                
                </b>
            </div>
            <div>
                Nuestro plazo de respuesta para dudas es de 48 horas laborables. Para evaluaciones es de 72 horas laborables.   
            </div>
            <div>
                Remarcamos la palabra laborables, ya que no son horas naturales, y aunque siempre intentamos contestar lo antes posible dentro de este plazo, no siempre es posible.
            </div>
            <div>
                Si no has recibido respuesta a una evaluación o a un post de Facebook en 72 horas laborables, escríbenos por Whatsapp al siguiente teléfono: 633515837. Ten en cuenta que se pueden dar los siguientes casos:
            </div>
            <div>
                <ol>
                    <li>
                        No hemos visto tu post en Facebook, porque aunque nos hayas etiquetado esto puede pasar.
                    </li>
                    <li>
                        No hemos contestado al post en FB porque ya te han dado respuesta otros compañeros del grupo (para eso estan los grupos, para crear comunidad).
                    </li>
                    <li>
                        O incluso que haya habido un fallo humano por nuestra parte al procesar tu evaluación.
                    </li>
                </ol>
            </div>
            <div>
                Es importante también que no dupliques las dudas. ¿Qué quiere decir esto?
            </div>
            <div>
                Que por favor no nos mandéis por correo una duda que ya has puesto en el grupo de Facebook. Y por favor piensa de antemano si tu tipo de duda debe ser resuelta en el grupo (o tal vez ya esté resuelta con anterioridad en otro post, y sólo tienes que usar el buscador) o si es una duda de índole personal/privado que es mejor resolver en una evaluación.
            </div>
            <div style={{color: '#98070a'}}>
                <b>
                    CONTEXTO                
                </b>
            </div>
            <div>
                Entendemos que el ritmo de vida de cada uno es distinto, y en muchos casos estás muy ocupado. Pero es importante que cuando nos escribas dudas, nos pongas en contexto sobre nuestras conversaciones anteriores.
            </div>
            <div>
                ¿Por qué? Porque agiliza infinitamente el proceso y recibirás respuesta mucho antes, y si todos lo hacéis así, el proceso será mucho más fluido. Por favor recuerda que sois muchos clientes y es imposible recordar cada uno de vuestros detalles, excepciones o conversaciones pasadas. Y al darnos contexto nos ayudarás muchísimo a agilizar el servicio y salir así todos beneficiados.
            </div>
            <div style={{color: '#98070a'}}>
                <b>
                    DATOS EN LAS EVALUACIONES                
                </b>
            </div>
            <div>
                Tanto en los fines de fase como en las evaluaciones/check-ins a mitad de fase, te pedimos que rellenes una serie de datos. Por favor revisa siempre que has rellenado todo.
            </div>
            <div>
                Si no lo haces, no contamos con los datos necesarios para poder darte un servicio excelente (en DOCE buscamos la excelencia) en el menor tiempo posible, y se retrasa mucho el proceso tanto para ti como para nosotros.
            </div>
            <div>
                Por favor, revisa siempre que has rellenado todos los datos que te pedimos. Por último, te pedimos por favor que leas detenidamente este texto, y lo releas si es necesario para interiorizar bien el protocolo de funcionamiento de DOCE.
            </div>
            <div>
                Como siempre darte las gracias por confiar en nosotros para ayudarte a conseguir tus objetivos, es algo que no nos tomamos a la ligera. ¡GRACIAS!
            </div>


        </div>
    )
}

export default ProgramaDoce