class User {

  isAuthenticated=false
  id = ""
  email = ""
  name = ""
  surname = ""
  roles = []

  isAdmin=false


  constructor(obj){
    if(obj){
      this.isAuthenticated = obj.isAuthenticated ?? false
      this.id = obj.id
      this.company = obj.company ?? ""
      this.country = obj.country ?? ""
      this.createDate = obj.createDate ?? ""
      this.email = obj.email ?? ''
      this.enabled = obj.enabled ?? false
      this.name = obj.name ?? ''
      this.surname = obj.surname ?? ''
      this.roles = obj.roles ?? []
      if(obj.roles){
        this.isAdmin = obj.roles.includes("ADMIN")
      }
    }
  }

}

export default User