import { userTypes } from '../actions/userActions';
import User from '../../models/User'

let initUser = {
  currentUser: new User()
}

const reducer = (state = initUser, action) => {
  switch (action.type) {
    case userTypes.SET_CURRENT_USER:{
      return {...state, currentUser: action.currentUser}
    }
    default: {
      return state
    }
  }
}

export default reducer;