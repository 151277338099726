const ethosClient = require('doce-client').createClient()

export const clientsTypes = {
	SET_BREADCRUMS: 'CLIENTS_SET_BREADCRUMS',
	SET_PANEL_SELECTED: 'CLIENTS_SET_PANEL_SELECTED',
	SET_CLIENT: 'CLIENT_SET_CLIENT',
	MODIFY_CLIENT: 'CLIENT_MODIFY_CLIENT',
	SET_CLIENT_SELECTED_ID: 'CLIENT_SET_CLIENT_SELECTED_ID',
	SET_CLIENT_SELECTED: 'CLIENT_SET_CLIENT_SELECTED',
}
export function setBreadcrums(payload) {
	return { type: clientsTypes.SET_BREADCRUMS, payload }
}

export function setPanelSelected(payload) {
	return { type: clientsTypes.SET_PANEL_SELECTED, payload }
}

export const setClient = (payload) => {
	return { type: clientsTypes.SET_CLIENT, payload }
}

export const modifyClient = (payload) => {
	return { type: clientsTypes.MODIFY_CLIENT, payload }
}

export const setClientSelected = (payload) => ({
	type: clientsTypes.SET_CLIENT_SELECTED,
	payload,
})
export const setClientSelectedId = (payload) => ({
	type: clientsTypes.SET_CLIENT_SELECTED_ID,
	payload,
})

export const signIn = ({ data }) => async (dispatch) => {
	try {
		const { body } = await ethosClient.loginClient({ data })
		const { client } = body
		return { idClient: client.idClient } //dispatch(setClientSelectedId(client.idClient))
	} catch (error) {
		console.log(error)
		return error
	}
}

export const createClient = (data) => {
	return async (dispatch) => {
		try {
			const { body } = await ethosClient.createClient(data)
			dispatch(setClient(body.client))
			return Promise.resolve(body)
		} catch (error) {
			return Promise.reject(error)
		}
	}
}

export const getClient = ({ idClient, email }) => {
	return async (dispatch) => {
		let params = {}
		if (idClient) params = { id: idClient }
		else params = { email: email }

		try {
			const { body } = await ethosClient.getClient({ params })
			dispatch(setClient(body.client))
			return Promise.resolve(body.client)
		} catch (error) {
			return Promise.reject(error)
		}
	}
}

export const updateClient = ({ id, data }) => async (dispatch) => {
	try {
		const { body } = await ethosClient.updateClient({ id, data })
		return Promise.resolve(body)
	} catch (error) {
		console.log(error)
	}
}

export const resetPassword = ({ data }) => async (dispatch) => {
	try {
		const { body } = await ethosClient.resetPassword({ data })
		return Promise.resolve(body)
	} catch (error) {
		console.log(error)
	}
}

export const resetNewPassword = ({ data }) => async (dispatch) => {
	try {
		const { body } = await ethosClient.resetNewPassword({ data })
		return Promise.resolve(body)
	} catch (error) {
		console.log(error)
	}
}

export const getPhases = ({ id }) => async (dispatch) => {
	try {
		const { body } = await ethosClient.getPhases({ id })
		return body
	} catch (error) {}
}
