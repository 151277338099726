import { feedbackTypes } from '../actions/feedbackActions'
var initialState = {
	forceGetFeedbacks: Date.now(),
	feedbacks: [],
	feedbackSelected: null,
}

export default function (state = initialState, action) {
	switch (action.type) {
		case feedbackTypes.FORCE_GET_FEEDBACKS: {
			return { ...state, forceGetFeedbacks: Date.now() }
		}
		case feedbackTypes.SET_FEEDBACKS: {
			return { ...state, feedbacks: action.payload }
		}
		case feedbackTypes.SET_FEEDBACK_SELECTED: {
			return { ...state, feedbackSelected: action.payload }
		}
		default: {
			return state
		}
	}
}
