import React from 'react'
//import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
/* import ReactFullpage from "@fullpage/react-fullpage"; */
import PageForm from '../InitialForm/PageForm';
import form from '../../utils/checkForm'
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom'
import { scrollNextPage } from '../../utils/functions'
import { optionsMFPInitialForm } from '../../utils/constans'

/* const anchors = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38"]; */


const CheckForm = ({ clientSelected, /* clientSelectedId */ }) => {
  const moveSectionDown = () => {
    scrollNextPage()
  }

  const isMFPQuestions = (idPregunta) => {
    if(idPregunta === 'questions.kcal' 
      || idPregunta === 'questions.protein'
      || idPregunta === 'questions.carbohydrates'
      || idPregunta === 'questions.fats'
      || idPregunta === 'questions.macrosQuality'
    ) {
      return true
    } else {
      return false
    }
  }

  return(
    !clientSelected ? <Redirect to="/" /> : 
    <div className="content-form-scroll" style={{overflow: "hidden"}}>
      <div id="fullpage">
        <>
          {form.fields.map((field, key) => isMFPQuestions(field.ref) ? clientSelected?.isMfp === optionsMFPInitialForm[0] 
          ? 
          (
            <PageForm id={`id-${key+1}`} field={field} key={key} moveSectionDown={moveSectionDown} setAllowScrolling={ value => {}} /*setAllowScrolling={ value => setAllowScrolling(value)}*/ />
          ) : 
          null 
          : 
          ( <PageForm id={`id-${key+1}`} field={field} key={key} moveSectionDown={moveSectionDown} setAllowScrolling={ value => {}} /> ))
          }

          <PageForm id={"send_info"} field={form['send_info']} moveSectionDown={moveSectionDown} setAllowScrolling={ value => {}} /*setAllowScrolling={ value => setAllowScrolling(value)}*/ />
          <PageForm id={"confirm"} field={form['confirm']} moveSectionDown={moveSectionDown} setAllowScrolling={ value => {}} /*setAllowScrolling={ value => setAllowScrolling(value)}*/ />
          
        </>
      </div>
      <div className="block-form" style={{display: "none"}}></div>
    </div>
  )
}

const mapStateToProps = ({clients}) => ({
  clientSelected: clients.clientSelected,
  clientSelectedId: clients.clientSelectedId
});

export default connect(mapStateToProps)(CheckForm);