import React, { useState } from 'react'
import NextButton from '../NextButton'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { updateClient, setClientSelected } from '../../../redux/actions/clientsActions'
import { getPathUpdate, getPathClientUpdate, blockForm } from '../../../utils/functions'

/* const dia = [1, 2, 3 , 4 , 5 , 6 ,7, 8 , 9 ,10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25 ,26 ,27, 28, 29, 30, 31]
const mes = [1, 2, 3, 4, 5, 6,, 7, 8, 9, 10, 11, 12] */

const DateForm = ({ field, initialValue, /* setAllowScrolling, */ moveSectionDown, actions, clientSelected}) => {

  const [value, setValue] = useState(initialValue)

/*   const handleChangeValue = (value) => {
    setValue(value)
    //handleClickNext(value)
  } */
  
  const handleClickNext = () => {
    if(!field?.ref) return moveSectionDown()
    if(value.trim() && initialValue === value) return moveSectionDown()

    const payload = {
      id: clientSelected.idClient,
      data: { 
        body: { 
          [field.ref === "initialForm.questions" ? `${field.ref}.${field.id}` : field.ref]: value,
        } 
      }
    }
    blockForm()
    actions.updateClient(payload)
    .then( client => {
      const path = getPathUpdate(field.id, field.ref)
      const value = getPathClientUpdate(client, field.id, field.ref)
      actions.setClientSelected({path , value})
      moveSectionDown()
    })
    .catch(error => {
      console.log(error);
    })
  }
  
  const handleChange = (valueInput) => {
    var date = valueInput.split("/"); 
    var day = date[0];
    var month = date[1];
    var year = date[2];

    if(+day<0 || +day>31) return;
    if(+month<0 || +month>12) return;
    if(+year>2030) return;

    if(value.endsWith('/') && value.length > valueInput.length){
      return setValue(valueInput.substring(0, valueInput.length-1))
    }else if(valueInput.endsWith('/') ){
      return setValue(valueInput)
    }else if(!valueInput.trim()){
      return setValue("")
    }

    switch (date.length){
  
      case 1:
        if (day.length > 0) setValue(valueInput)
        if (day.length > 1) setValue(valueInput + "/")
        else if(day.length === 1 && parseInt(day) > 3) setValue("0"+day+"/")
        break; 
      case 2:
        if (day.length >0) setValue(valueInput)
        if (month.length > 1) setValue(valueInput + "/");
        else if(month.length === 1 && parseInt(month) > 1 ) setValue(day + "/0"+month+"/")
        break; 
      case 3:
        if (year.length>0) setValue(valueInput)
        if (day.length > 3) setValue(valueInput + "/")
        if (month.length === 1) setValue(day + "/0"+month+"/" + year)
        break; 
      default:
        setValue(valueInput)
      break;
      
  }

}
return(
  <>
    <div className="pr">
   
      <input type="text" placeholder="DD/MM/YYYY" value={value} className="test_css" onChange={e=>handleChange(e.target.value)} maxLength="10"/>

    </div>
    <NextButton onClick={handleClickNext} visibility={Boolean(value.length === 10)} buttonText={field?.properties?.button_text} hideMarks={field?.properties?.hide_marks}/>
  </>
)
}

const mapStateToProps = ({clients}) => ({
  clientSelected: clients.clientSelected
});
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      updateClient,
      setClientSelected
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(DateForm);