import React, { useState } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { updateClient, setClientSelected } from '../../../redux/actions/clientsActions'
import { updatePhase } from '../../../redux/actions/phasesActions' 
import { getPathUpdate, getPathClientUpdate, blockForm } from '../../../utils/functions'

const OptionScale = ({ field, steps, labels, initialValue = 0, setAllowScrolling, moveSectionDown, actions, clientSelected, historySelected }) => {
  
  const [value, setValue] = useState(initialValue)

  const handleClickNext = (value) => {
    if(field.update === "phase") return handlePhase(value)
    if(!field?.ref) return false
    if(+initialValue === +value){
      return moveSectionDown()
    }

    const payload = {
      id: clientSelected.idClient,
      data: { 
        body: { 
          [field.ref === "initialForm.questions" ? `${field.ref}.${field.id}` : field.ref]: +value,
        } 
      }
    }

    blockForm()
    actions.updateClient(payload)
    .then( client => {
      const path = getPathUpdate(field.id, field.ref)
      const value = getPathClientUpdate(client, field.id, field.ref)
      actions.setClientSelected({path , value})
      moveSectionDown()
    })
    .catch(error => {
      console.log(error);
    })
  }

  const handlePhase = (value) => {
    const { idPhase, step } = historySelected.metadata
    if(!idPhase || !step) return 
    let multiPath = {}
    multiPath[`${step}.${field.ref}`] = +value
    const payload = {
      id: idPhase,
      data: { 
        body: { 
          ...multiPath
        } 
      }
    }
    blockForm()
    actions.updatePhase(payload)
    .then( client => {
      const path = getPathUpdate(field.id, field.ref)
      const value = getPathClientUpdate(client, field.id, field.ref)
      actions.setClientSelected({path , value})
      moveSectionDown()
    })
    .catch(error => {
      console.log(error);
    })
  }

  const handleChangeValue = (value) => {
    setValue(value)
    setAllowScrolling(true)
    handleClickNext(value)
  }

  return(
    <div className="option-scale-container">
      <div className="container-scale">
        {[...Array(steps).keys()].map((key, index)=>{
          return(
            <div key={index} className={`${value === key+1 ? 'active-option-scale' : ''}`} onClick={() => handleChangeValue(key+1)}>{key+1}</div>
          )
        })}
      </div>
      <div className="info-scale">
        {Object.entries(labels).map(([id, label])=>{
          return(
            <span key={id} className={`opinion-scale-label-${id}`}>
              <div>{label}</div>
            </span>
          )
        })}
      </div>
    </div>
  )
}

const mapStateToProps = ({clients, history}) => ({
  clientSelected: clients.clientSelected,
  historySelected: history.historySelected

});
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      updateClient,
      setClientSelected,
      updatePhase
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(OptionScale);