import React, { useState, useEffect, useRef } from 'react'
import { connect } from "react-redux";
import { deleteTags } from '../../utils/functions'
import { ClearRounded, KeyboardArrowRightRounded, ChevronLeftRounded, DoneRounded, SendRounded } from '@material-ui/icons'
import { Button } from '@material-ui/core';

import renderHTML from 'react-render-html';

import moment from 'moment'
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setClientSelected } from '../../redux/actions/clientsActions'
import { reloadFeedbacks, setFeedbackSelected } from '../../redux/actions/feedbackActions'
import styled from 'styled-components'

const Chat = ({ onClose, active = false, actions, feedbacks}) => {

  const [showOptions, setShowOptions] = useState(false)
  const [feedbackSelected, setFeedbackSelected] = useState(false)
  const [redirectTo, setRedirectTo] = useState(false)
  const [visibleChatInput, setVisibleChatInput] = useState(false)
  const [textResponse, setTextResponse] = useState("")
  const inputRef = useRef()

  useEffect(() => {
    if(visibleChatInput){
      inputRef.current.focus()
    }
  }, [visibleChatInput])

  useEffect(() => {
    if(active){
      document.getElementById("root").style.overflow = "hidden";
      setTimeout(()=>setShowOptions(true), 200)
    }else{
      document.getElementById("root").style.overflow = "auto";
    }
  }, [active]);

  useEffect(() => {
    scrollToBottom()
  }, [feedbackSelected])
  
  useEffect(() => {
    if(feedbackSelected){
      handleHistory(feedbacks.find(history => history.idHistory === feedbackSelected.idHistory))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbacks])
  
  const scrollToBottom = () => {
    //const elem = $("#chat-container")?.[0]
    //if(elem){
      //$("#chat-container").scrollTop(elem.scrollHeight);
    //}
  }

  const onCloseOptions = () => {
    setShowOptions(false)
    setTimeout(()=>onClose(), 200)

  }

  const handleHistory = (history) => {
    //actualizar a leido los mensajes del employee
    // readNotifications(history)
    setFeedbackSelected(history)
    actions.setFeedbackSelected(history)

  }
  const handleClickButton = (button, idHistory) => {
    if(button.action === "showInitialForm"){
      setRedirectTo(`/initial-form?wrongForm=true&idHistory=123456`)
    }else if(["showCheck", "showEndPhase"].includes(button.action)){
      setRedirectTo(`/check-form?idHistory=${feedbackSelected.idHistory}`)
    }
  }

  const handleOnBlur = () => {
    if(!textResponse.trim()){
      setVisibleChatInput(false)
    }
  }

  const sendNotification = () => {
    if(!textResponse.trim()) return
    
    const payload = {
      id: feedbackSelected.idHistory,
      data: { 
        body: { 
          message: textResponse,
          from: "CLIENT",
          to: "EMPLOYEE",
        } 
      }
    }
    actions.updateHistory(payload)
    .then(({ history }) => {
      setTextResponse("")
      actions.reloadFeedbacks()
    })
    .catch( error => {
    })
  }

  const handleBackNotifications = () => {
    setFeedbackSelected(null)
    actions.reloadFeedbacks()
  }

  const handleFaq = () => {
    setRedirectTo('/doce-faq')
  }

  return( 
    
    redirectTo ? <Redirect to={redirectTo} /> :
    active && 

    <div className="block-panel chat-panel" onClick={onCloseOptions}>
      
      
      <div  className={`content-chat btns-download-pdf ${showOptions?'active-view':''}`} onClick={e=>e.stopPropagation()}>
        {!feedbackSelected &&
          <>
            <div className="close-chat" onClick={onCloseOptions}><ClearRounded /></div>
            <div className="title-popup">Notificaciones</div>
            <div className="notification-list">
              {feedbacks.map((history, index) => {
                const content = deleteTags(history?.metadata?.content)
                let mensajes = history?.messages?.filter(message => !message.seenEmployee && message.from === "EMPLOYEE")?.length
                return(
                  <div className="item-chat" key={index} onClick={() => handleHistory(history)}>
                    <div className="container-read-it">
                    { mensajes > 0 && <div className="dot-read">{mensajes}</div>}
                    </div>
                    <div className="text-item-chat">
                      <div>{history.metadata.subject}<span className="date-item-chat">{moment(history.createdAt).format('DD/MM')}</span></div>
                      <div>{content.length>100 ? content.substring(0, 100) + "..." : content }</div>
                      {/* <div>{content}</div> */}
                    </div>
                    <div className="arrow-item-chat">
                      <div><KeyboardArrowRightRounded/></div>
                    </div>
                  </div>
                )
              })

              }
            </div>
          </>
        }
        {feedbackSelected && 
          <ContainerNotification>

            <div className="close-chat history-back" onClick={()=>handleBackNotifications()}><ChevronLeftRounded /></div>
            <div className="container-history" id="chat-container">
              
              <div className="title-history">{feedbackSelected.metadata.subject}</div>
              <div className="content-history">{renderHTML(feedbackSelected.metadata.content)}</div>

              {feedbackSelected?.metadata?.button && 
                  <div className="btn-bottom-panel square-btn" onClick={()=>handleClickButton(feedbackSelected.metadata.button)}>
                    {feedbackSelected.metadata.button.text}
                  </div>
              }

              {feedbackSelected?.messages && 
                <div>
                  {feedbackSelected.messages.map((message) => {
                    return(
                      <div className={`item-container-message-${message.from}`}>
                        
                        <div className="message-chat">
                          <div>{message.message}</div>
                          {/* <div className="info-date-chat">
                            <span className="date-chat">13:54</span>
                            <span className={`${message.seen ? 'message-seen' : 'message-not-seen'}`}><DoneAllRounded /></span>
                          </div> */}
                        </div>
                      </div>
                    )
                  })}
                </div>
              }              
            </div>
            <div className="container-btn-bottom-panel" >
              {!visibleChatInput && 
                <>
                  { feedbackSelected?.metadata?.visibleFaq && <Button onClick={handleFaq}><div className="btn-bottom-panel">FAQ</div></Button>}
                  { !feedbackSelected?.metadata?.hiddenOkButton && <Button><div className="btn-bottom-panel">OK, leida<DoneRounded /></div></Button>}
                  { !feedbackSelected?.metadata?.hiddenResponse && <Button><div className="btn-bottom-panel chat-btn" onClick={()=>setVisibleChatInput(true)}>Responder <SendRounded /></div></Button>}
                </>
              }
              {visibleChatInput && 
                <div className="container-input-chat">
                  <input ref={inputRef} onBlur={() => handleOnBlur() } value={textResponse} onChange={e => setTextResponse(e.target.value)}/>
                  <Button onClick={() => sendNotification()}><SendRounded /></Button>
                </div>
              }
            </div>
            
          </ContainerNotification>
        }
      
      </div>
  
    
    
    
    </div>
  )
}

const mapStateToProps = ({ clients, history, feedback }) => ({
  clientSelected: clients.clientSelected,
  histories: history.histories,
  feedbacks: feedback.feedbacks,
})

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setClientSelected,
      reloadFeedbacks,
      setFeedbackSelected
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(Chat);

const ContainerNotification = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 25px) !important;
`