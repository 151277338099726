import React, { useLayoutEffect } from 'react'
import Notification from '../Shared/Notification'
import Loading from '../Shared/Loading'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { getTemplates } from '../../redux/actions/templatesActions'

const Layout = (props) => {

  useLayoutEffect(() => {
    const getData = () => {
      props.actions.getTemplates()
    }
    getData()
  }, [props.actions])

  return(
    <div>
      <Notification />
      {props.children}
      <Loading />
    </div>
  )
}

const matchDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getTemplates
  }, dispatch)
})


export default connect(null, matchDispatchToProps)(Layout);