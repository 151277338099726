import { dashboardTypes } from '../actions/dashboardActions'
import { setItemLocalStorage } from '../../utils/functions'

const dashboardBreadcrumsInitial = { text: "Dashboard" }

let initialState = {
  indicatorsDashboard: {
  
    situacionGeneral: {
      text: "Situación general",
      indicators : {
        pagosAlCorriente:{
          keyIndicator: 'pagosAlCorriente',
          text: "Pagos al corriente",
          value: 20,
        },
        congelaciones:{
          keyIndicator: "congelaciones",
          text: "Congelaciones",
          value: 5,
        },
        pagosCaducados:{
          keyIndicator: "pagosCaducados",
          text: "Pagos caducados",
          value: 11,
        },
        cuentasCanceladas:{
          keyIndicator: "cuentasCanceladas",
          text: "Cuentas canceladas",
          value: 2,
        },
      }
    },

    gestionesPendientes: {
      text: "Gestiones pendientes",
      indicators:{
        finesDeFase:{
          keyIndicator: "finesDeFase",
          text: "Fines de fase",
          value: 10,
        },
        updatesDosSemanas:{
          keyIndicator: "updatesDosSemanas",
          text: "Updates de 2 semanas",
          value: 73,
        },
        mensajesDudas: {
          keyIndicator: "mensajesDudas",
          text: "Mensajes/dudas",
          value: 45,
        },
        nuevasAltas: {
          keyIndicator: "nuevasAltas",
          text: "Altas nuevas",
          value: 3,
        }
      }
    },

    facturacion: {
      text: "Facturación",
      indicators: {
        nuevosPedidos: {
          keyIndicator: "nuevosPedidos",
          text: "Nuevos pedidos DOCE",
          value: 34,
        },
        renovaciones: {
          keyIndicator: "renovaciones",
          text: "Renovaciones DOCE",
          value: 65,
        },
        ingresosUltimoMes: {
          keyIndicator: "ingresosUltimoMes",
          text: "Ingresos último mes",
          value: 1,
        }
      }
    }

  },
  
  breadcrums: [dashboardBreadcrumsInitial],




}

if (typeof (Storage) !== 'undefined') {
  initialState = { 
    ...initialState, 
    //breadcrums: localStorage.getItem('breadcrumsDashboard') ??  initialState.breadcrums
  }
}

const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case dashboardTypes.SET_BREADCRUMS: {
      const { keyPanel, newPanel } = action.payload
      let newBreadcrums = []
      state.breadcrums.some( (panel, key) => {
        if(key<keyPanel){
          newBreadcrums.push(panel)
        }else if(key===keyPanel){
          newBreadcrums.push(newPanel)
          return true
        }
        return false
      })
      if(newBreadcrums.length===1){
        newBreadcrums.push(newPanel)
      }

      setItemLocalStorage('breadcrumsDashboard', action.payload)
      return { ...state, breadcrums: newBreadcrums }
    }
    default:
      return state
  }
}

export default reducer

