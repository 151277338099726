import React from 'react'
import { useState, useEffect, useLayoutEffect } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { setClient, getClient, updateClient, setClientSelectedId, setClientSelected } from '../../redux/actions/clientsActions'
import { setLoading, setNotification } from '../../redux/actions/globalActions'
import { Redirect, Link } from 'react-router-dom'

import logo from '../../Images/logo.png'
import backgroundSignIn from '../../Images/backgroundSignIn.png'
import AlertDialog from '../AlertDialog'
import { TextField, InputAdornment, IconButton, Button } from '@material-ui/core';
import { VisibilityRounded, PersonRounded, VisibilityOffRounded, LockRounded} from "@material-ui/icons";



const SignIn = ({ clientSelected, clientSelectedId, actions}) => {
  const [email, setEmail] = useState(clientSelected?.personalData?.email)
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showAlertDialog, setShowAlertDialog] = useState(false)
  
  useLayoutEffect(() => { actions.setLoading(false) }, [actions])

  useEffect(() => {
    if(clientSelectedId && clientSelectedId !== clientSelected?.idClient){
      actions.getClient({ idClient: clientSelectedId })
      .then( client => {
        actions.setClientSelected({value: client})
      })
    }
  }, [actions, clientSelected, clientSelectedId])

  const handleMouseDownPassword = event => event.preventDefault();

  const handleCloseDialog = () => setShowAlertDialog(false);

  const handleRegister = () => {
    actions.setLoading(true)
    if(password===repeatPassword && email.trim()) {
      actions.getClient({ email })
      .then((client)=>{

        if(client && client?.status === "NEW") {
          let multiPhath = {}
          multiPhath[`personalData.password`] = password
          const payload = {
            id: client.idClient,
            data: { 
              body: { 
                ...multiPhath
              } 
            }
          }
          actions.updateClient(payload)
          .then(({client}) => {
            actions.setClientSelectedId(client.idClient)
            actions.setLoading(false)
          })
        } else {
          setShowAlertDialog(true)
          actions.setLoading(false)
        }
      })
    }else if(password!==repeatPassword){
      actions.setLoading(false)
      actions.setNotification({ visibility: true, text: "Las contraseñas no coinciden", status: "close", moment: Date.now() })
    }else if(!email.trim()){
      actions.setLoading(false)
      actions.setNotification({ visibility: true, text: "Introduce un email", status: "close", moment: Date.now() })
    }
  }

  return(
      
      clientSelectedId && clientSelected?.idClient ? <Redirect to="/" /> :

      <div className="panel-register"> 

        <img className="imageBackground" alt="background" src={backgroundSignIn} />

        <div className="container-logo-inputs pr">
          <div className="container-logo">
            <div className="top-logo-info-signin">
              <div className="container-logo">
                <img src={logo} alt="logo"/>
              </div>
            </div>
          </div>

          <div className="container-inputs">
            <h1>Register</h1>
            <TextField
              value={email}
              onChange={e=>setEmail(e.target.value)}
              className="input-style bg-dark mg-bottom-30"
              label="Email"
              InputLabelProps={{ shrink: true }}
              InputProps={{startAdornment:<InputAdornment position="start"><PersonRounded /></InputAdornment>}}
              variant="outlined"
            />

            <TextField
              className="input-style bg-dark mg-bottom-30"
              label="Password" InputLabelProps={{ shrink: true}}
              value={password}
              onChange={e=>setPassword(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              InputProps={{ 
                startAdornment:<InputAdornment position="start"><LockRounded /></InputAdornment>,
                endAdornment: 
                <InputAdornment position="end">
                  <IconButton onClick={()=>setShowPassword(!showPassword)} onMouseDown={handleMouseDownPassword} aria-label="toggle password visibility" edge="end">
                    {showPassword ? <VisibilityRounded /> : <VisibilityOffRounded />}
                  </IconButton>
                </InputAdornment>,
              }}
              variant="outlined"
            />

            <TextField
              className="input-style bg-dark repeat"
              label="Repeat Password" InputLabelProps={{ shrink: true}}
              value={repeatPassword}
              onChange={e=>setRepeatPassword(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              InputProps={{ 
                startAdornment:<InputAdornment position="start"><LockRounded /></InputAdornment>,
                endAdornment: 
                <InputAdornment position="end">
                  <IconButton onClick={()=>setShowPassword(!showPassword)} onMouseDown={handleMouseDownPassword} aria-label="toggle password visibility" edge="end">
                    {showPassword ? <VisibilityRounded /> : <VisibilityOffRounded />}
                  </IconButton>
                </InputAdornment>,
              }}
              variant="outlined"
            />

            <div className="link-register">
              <span>Already have an account?</span>
              <Link to="/signin">
                <div>Login here</div>
              </Link>
            </div>

            <div className="btns-signin">
              <Button className="btn-material-form" variant="contained" onClick={handleRegister}>
                <div>REGISTER</div>
              </Button>
            </div>
          </div>
          <AlertDialog open={showAlertDialog} close={handleCloseDialog} texto={'Antes de registrarte, debes solicitar acceso.'}/>
        </div>
      </div>
  )
}


const mapStateToProps = ({clients}) => ({
  clientSelected: clients.clientSelected,
  clientSelectedId: clients.clientSelectedId
});

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      getClient,
      setClient,
      updateClient,
      setLoading, 
      setNotification,
      setClientSelectedId,
      setClientSelected
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(SignIn);
