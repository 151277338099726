import ContenidosExclusivos from '../Images/ContenidosExclusivos.jpg'
import MealPlanner from '../Images/MealPlanner.jpg'
import PlanDeEntrenamiento from '../Images/PlanDeEntrenamiento.jpg'
import PlanNutricional from '../Images/PlanNutricional.jpg'
import ProtocoloDOCE from '../Images/ProtocoloDOCE.jpg'
import ContactWithUs from '../Images/ContactWithUs.jpg'
import GuiaDoce from '../Images/guiaDoce.jpg'
require('dotenv').config()

export const modulesQuill = {
	toolbar: [
		['bold', 'italic', 'underline', 'strike'],
		[
			{ list: 'ordered' },
			{ list: 'bullet' },
			{ indent: '-1' },
			{ indent: '+1' },
		],
		//[{ header: '1' }, { header: '2' }],
	],
}

export const formatsQuill = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'list',
	'indent',
	'bullet',
	'link',
]

export const DASHBOARD = 'dashboard'
export const CLIENTS = 'clients'
export const SERVER_URL = 'https://api.programadoce.com/'

export const panelsClients = {
	LIST: 'LIST',
	INFORMATION: 'INFORMATION',
}
export const optionsValidateInitialForm = [
	'Sin validar',
	'Validado correctamente',
	'Validación errónea',
]

export const optionsMFPInitialForm = ['Si', 'No']

export const buttonsHome = {
	feedback: {
		titular: 'Feedback del equipo DOCE',
		texto:
			'En esta sección tienes recogido un histórico del feedback que te hemos ido dando en cada evaluación que has hecho con nosotros.',
		img: ContenidosExclusivos,
		click: {
			action: 'actionFeedbackHistory',
		},
	},
	planEntrenamiento: {
		titular: 'Plan de entrenamiento',
		texto:
			'Accede a tu plan de entrenamiento y a la página privada con los vídeos de cada ejercicio.',
		img: PlanDeEntrenamiento,
		click: {
			action: 'optionsPanel',
			data: {
				title: 'Entrenamiento',
				buttons: [
					{ text: 'Vídeos de entrenamiento' },
					{ text: 'Entrenamiento en PDF' },
				],
			},
		},
	},
	planesNutricionales: {
		titular: 'Plan de nutrición',
		texto:
			'En esta sección puedes descargarte los planes nutricionales para días de entrenamiento y para días de descanso.',
		img: PlanNutricional,
		click: {
			action: 'optionsPanel',
			data: {
				title: 'Plan Nutricional',
				buttons: [
					{ text: 'Descarga tu plan' },
					// {text: "Para días de entrenamiento"},
					// {text: "Para días de descanso"}
				],
			},
		},
	},
	mealPlanners: {
		titular: 'Planificador de comidas',
		texto:
			'Descarga tus planificadores de comidas, calendarios semanales en los que apuntarás lo que vas a comer cada día.',
		img: MealPlanner,
		click: {
			action: 'optionsPanel',
			data: {
				title: 'Meal Planners',
				buttons: [
					{ text: 'Para cuatro comidas al día' },
					{ text: 'Para tres comidas al día' },
				],
			},
		},
	},
	guiaDoce: {
		titular: 'Guía DOCE',
		texto:
			'Descarga la Guía DOCE, un documento fundamental para poder sacarle el máximo partido a tu Plan DOCE.',
		img: GuiaDoce,
		click: {
			action: 'redirectTo',
			redirectTo: '/guia-doce',
		},
	},
	protocoloDoce: {
		titular: 'Protocolo DOCE',
		texto:
			'Descarga el Protocolo DOCE para conocer el funcionamiento de tu Plan DOCE: evaluaciones, toma de contacto con el equipo, etc.',
		img: ProtocoloDOCE,
		click: {
			action: 'redirectTo',
			redirectTo: '/protocolo-doce',
		},
	},
	contacto: {
		titular: '¿Cómo ponerte en contacto con nosotros?',
		texto: '',
		img: ContactWithUs,
		click: {
			action: 'redirectTo',
			redirectTo: '/contact',
		},
	},
}

export const preguntasCuestionario = {
	general: [
		{
			idPregunta: 1,
			type: 'radiobutton',
			required: true,
			pregunta: '¿Vas a entrenar en un gimnasio o en casa?',
			options: [
				{ text: 'Gimnasio' },
				{ text: 'Casa' },
				{ text: 'Box de crossfit' },
			],
		},

		{
			idPregunta: 2,
			type: 'radiobutton',
			required: true,
			pregunta:
				'¿Ha tenido alguna lesión previa / problemas médicos que puedan tener repercusiones en un programa de ejercicio?',
			options: [{ text: 'Si' }, { text: 'No' }],
		},

		{
			idPregunta: 2.1,
			dependencies: {
				idPregunta: 2,
				ifValue: 'Si',
			},
			required: true,
			pregunta:
				'Por favor, descríbenos tu lesión, cuándo la tuviste, si tienes un alta médica y todos los detalles que puedan ser relevantes',
		},

		{
      idPregunta: 3,
      required: false,
			pregunta:
				'¿Usas o has usado drogas recreacionales, suplementos para mejorar el rendimiento o alguna medicina prescrita por tu médico?',
		},

		{
			idPregunta: 4,
			required: true,
			pregunta:
				'¿Qué le gustaría conseguir trabajando con un entrenador y por qué es importante para usted?',
			type: 'reorder',
			options: [
				{ text: 'Ganar músculo' },
				{ text: 'Perder grasa' },
				{ text: 'Tonificar' },
				{ text: 'Ganar fuerza' },
				{ text: 'Mejorar mis hábitos nutricionales y de ejercicio' },
			],
		},

		{
			idPregunta: 5,
			required: true,
			pregunta:
				'Para ayudarme a conceptualizar sus metas en relación a la composición corporal, ¿hay alguna persona a la que le gustaría parecerse fisicamente (deportista / celebridad)?',
		},

		{
			idPregunta: 6,
			required: true,
			pregunta: '¿Qué está dispuesto a hacer para convertir esto en realidad?',
			type: 'checkbox',
			options: [
				{ text: 'Seguir el plan nutricional al pie de la letra' },
				{
					text:
						'Seguir el plan nutricional pero los fines de semana tener mis caprichos',
				},
				{ text: 'Disminuir mi consumo de alcohol pero no dejar de beber.' },
			],
		},

		{
			idPregunta: 7,
			required: true,
			pregunta:
				'¿Cuánto tiempo está preparado para dedicarle al entrenamiento semanalmente?',
			type: 'radiobutton',
			options: [
				{ text: 'Menos de 3 horas' },
				{ text: 'de 3 a 4 horas' },
				{ text: 'de 4 a 6 horas' },
				{ text: 'Más de 6 horas' },
			],
		},

		{
      idPregunta: 8,
      required: false,
			pregunta:
				'¿Se ve a sí mismo como una persona capaz de ejercer autodisciplina? Escala del 1 al 10',
		},

		{
      idPregunta: 9,
      required: false,
			pregunta:
				'¿Qué vicios cree usted que pueden dificultarle conseguir sus metas o que se lo hayan dificultado en el pasado?',
			type: 'checkbox',
			options: [
				{ text: 'Salir de fiesta' },
				{ text: 'Antojos alimenticios' },
				{ text: 'Eventos laborales' },
				{ text: 'Reuniones familiares y sociales' },
			],
		},

		{
			idPregunta: 10,
			required: true,
			pregunta: 'Cuénteme su historial previo con el ejercicio',
			type: 'radiobutton',
			options: [
				{
					text:
						'Nunca he entrenado en el gimnasio, ni he hecho deporte de forma regular',
				},
				{
					text:
						'Nunca he entrenado en el gimnasio pero he hecho deporte de forma regular',
				},
				{
					text:
						'He entrenado en el gimnasio y he hecho deporte a intervalos (irregular)',
				},
				{
					text: 'Llevo entrenando regularmente en el gimnasio menos de 6 meses',
				},
				{
					text: 'Llevo entrenando regularmente en el gimnasio de 6 a 18 meses',
				},
				{
					text: 'Llevo entrenando regularmente en el gimnasio más de 18 meses',
				},
			],
		},

		{
			idPregunta: 11,
			required: true,
			pregunta:
				'¿Hay algo que físicamente no pueda hacer y que siempre haya querido hacer?',
		},
	],

	nutricion: [
		{
			idPregunta: 12,
			required: true,
			pregunta:
				'¿Tienes alguna intolerancia o alergia a algún alimento o algún requisito nutricional especial de tipo religioso?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }],
		},
		{
			idPregunta: 12.1,
			dependencies: {
				idPregunta: 12,
				ifValue: 'Si',
			},
			required: true,
			pregunta:
				'Por favor, descríbenos tu lesión, cuándo la tuviste, si tienes un alta médica y todos los detalles que puedan ser relevantes',
		},
		{
			idPregunta: 13,
			required: true,
			pregunta: '¿Estás familiarizado con el conteo de Macros?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }],
		},
		{
			idPregunta: 14,
			required: true,
			pregunta:
				'Si tu objetivo es perder peso, contesta esta pregunta (NO OBLIGATORIA) ¿Has perdido peso antes? Si es así, ¿Seguiste alguna dieta específica? ¿Qué hiciste?',
		},
		{
			idPregunta: 15,
			required: true,
			pregunta:
				'Si tu objetivo es subir de peso, contesta esta pregunta (NO OBLIGATORIA) ¿Has subido de peso antes? Si es así, ¿Seguiste alguna dieta específica? ¿Qué hiciste?',
		},
		{
			idPregunta: 16,
			required: true,
			pregunta: '¿Cuántas veces comes al día? Incluyendo snacks / picar.',
			type: 'radiobutton',
			options: [
				{ text: 'Dos comidas al día' },
				{ text: 'Dos comidas y un snack al día' },
				{ text: 'Dos comidas y dos snacks al día' },
				{ text: 'Tres comidas' },
				{ text: 'Tres comidas y un snack' },
				{ text: 'Tres comidas y dos snacks' },
				{ text: 'Cuatro comidas' },
				{ text: 'Cuatro comidas y un snack' },
				{ text: 'Cuatro comidas y dos snacks' },
				{ text: 'Cinco comidas' },
			],
		},
		{
      idPregunta: 17,
      required: false,
			pregunta: '¿Desayunas habitualmente?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }],
		},
		{
      idPregunta: 18,
      required: false,
			pregunta: '¿A qué hora es tu última comida?',
			type: 'radiobutton',
			options: [
				{ text: 'De 20:00 a 21:00' },
				{ text: 'De 21:00 a 22:00' },
				{ text: 'De 22:00 a 23:00' },
				{ text: 'De 23:00 a 00:00' },
				{ text: 'Más tarde de las 00:00' },
			],
		},
		{
      idPregunta: 19,
      required: false,
			pregunta:
				'¿Cómo te sientes de 30 a 90 minutos después de comer muchos carbohidratos? Especialmente productos con gluten / a base de trigo (ej: pasta, cereales etc).',
			type: 'radiobutton',
			options: [
				{ text: 'Somnoliento' },
				{ text: 'Aletargado (me cuesta concentrarme)' },
				{ text: 'Normal' },
				{ text: 'Energizado' },
			],
		},
		{
      idPregunta: 20,
      required: false,
			pregunta: '¿Dirías que te gusta más lo salado o lo dulce?',
			type: 'radiobutton',
			options: [{ text: 'Salado' }, { text: 'Dulce' }, { text: 'Indiferente' }],
		},
		{
      idPregunta: 21,
      required: false,
			pregunta: '¿Te entran antojos?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }],
		},
		{
      idPregunta: 22,
      required: false,
			pregunta: '¿Cocinas tu propia comida a menudo?',
			type: 'radiobutton',
			options: [
				{ text: 'Menos del 50% de mis comidas' },
				{ text: 'Del 50% al 60% de mis comidas' },
				{ text: 'Del 60% al 80% de mis comidas' },
				{ text: 'Más del 80% de mis comidas' },
			],
		},
		{
      idPregunta: 23,
      required: false,
			pregunta: '¿Sueles comer más comida casera o precocinada / de lata?',
			type: 'radiobutton',
			options: [
				{ text: 'Comida casera' },
				{ text: 'Comida precocinada de lata' },
			],
		},
		{
      idPregunta: 24,
      required: false,
			pregunta: '¿Cada cuánto comes en restaurantes o sitios de comida rápida?',
			type: 'radiobutton',
			options: [
				{ text: '1 vez cada dos semanas' },
				{ text: '1 vez a la semana' },
				{ text: 'De 2 a 3 veces por semana' },
				{ text: 'Más de 3 veces por semana' },
			],
		},
		{
      idPregunta: 25,
      required: false,
			pregunta:
				'¿Cuantas tazas de café / té / bebidas energéticas tomas al día?',
			type: 'radiobutton',
			options: [
				{ text: 'Ninguna' },
				{ text: 'Una' },
				{ text: 'Dos' },
				{ text: 'Tres' },
				{ text: 'Más de tres' },
			],
		},
		{
      idPregunta: 26,
      required: false,
			pregunta:
				'¿Estas tazas de de café / té / bebidas energéticas son con leche y/o azúcar?',
			type: 'radiobutton',
			options: [{ text: 'Leche' }, { text: 'Azúcar' }, { text: 'Ambas' }],
		},
		{
      idPregunta: 27,
      required: false,
			pregunta: '¿Bebes alcohol?',
			type: 'radiobutton',
			options: [
				{ text: 'No bebo' },
				{ text: 'Solo vino o cerveza, una o dos por semana' },
				{ text: 'Solo vino o cerveza, tres o más por semana' },
				{ text: 'Dos o tres copas de bebidas “duras” por semana' },
				{
					text:
						'Por supuesto, me gusta el Rock&amp;Roll (cuatro o cinco bebidas “duras” por semana o más)',
				},
			],
		},
		{
      idPregunta: 28,
      required: false,
			pregunta: '¿Cuánta agua bebes al día? Usemos vasos de 250ml',
			type: 'radiobutton',
			options: [
				{ text: 'Cuatro vasos o menos' },
				{ text: 'De cuatro a seis vasos' },
				{ text: 'De seis a ocho' },
				{ text: 'Más de ocho' },
			],
		},
		{
      idPregunta: 29,
      required: false,
			pregunta: '¿Dirías que tu estado emocional afecta tu forma de comer?',
			type: 'checkbox',
			options: [
				{ text: 'Sí, como más' },
				{ text: 'Sí, como menos' },
				{ text: 'Sí, como peor' },
				{ text: 'No' },
			],
		},
		{
      idPregunta: 30,
      required: false,
			pregunta:
				'Por favor explicame como sería un día de comidas habitual para ti',
		},
	],

	varios: [
		{
      idPregunta: 31,
      required: false,
			pregunta: '¿Cada cuánto vas al cuarto de baño?',
			type: 'radiobutton',
			options: [
				{ text: 'Dos o tres veces por semana' },
				{ text: 'De tres a cinco veces por semana' },
				{ text: 'Una vez al día' },
				{ text: 'Dos veces al día' },
				{ text: 'Más de dos veces al día' },
			],
		},
		{
      idPregunta: 32,
      required: false,
			pregunta: '¿A qué te dedicas?',
		},
		{
      idPregunta: 33,
      required: false,
			pregunta: '¿Cuántas horas trabajas al día?',
		},
		{
      idPregunta: 34,
      required: false,
			pregunta: '¿Cuál es tu nivel de actividad física en el trabajo?',
			type: 'radiobutton',
			options: [
				{ text: 'sedentario (trabajo de oficina)' },
				{
					text:
						'medianamente activo (entrenador personal, camarero, guardia jurado que hace rondas, etc.)',
				},
				{ text: 'Muy activo (obrero, jornalero, monitor de spinning, etc.)' },
			],
		},
		{
      idPregunta: 35,
      required: false,
			pregunta: '¿Cómo percibes tu nivel de estrés? (1 es bajo, 5 es alto)',
			type: 'radiobutton',
			options: [
				{ text: '1' },
				{ text: '2' },
				{ text: '3' },
				{ text: '4' },
				{ text: '5' },
			],
		},
		{
      idPregunta: 36,
      required: false,
			pregunta:
				'¿Implica tu trabajo que comas y bebas fuera a menudo (cenas / comidas con clientes, cervezas con colegas de trabajo.. etc)?',
			type: 'radiobutton',
			options: [
				{ text: 'No' },
				{ text: 'Sí, una o dos veces por semana' },
				{ text: 'Más de dos veces por semana' },
			],
		},
		{
      idPregunta: 37,
      required: false,
			pregunta: '¿Tienes más energía por la mañana o por la noche?',
			type: 'radiobutton',
			options: [{ text: 'mañana' }, { text: 'noche' }],
		},
		{
      idPregunta: 38,
      required: false,
			pregunta: '¿Te cuesta desconectar por la noche?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }, { text: 'A veces' }],
		},
		{
      idPregunta: 39,
      required: false,
			pregunta: '¿Te cuesta dormir por la noche?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }, { text: 'A veces' }],
		},
		{
      idPregunta: 40,
      required: false,
			pregunta: '¿Te cuesta levantarte por la mañana?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }, { text: 'A veces' }],
		},
		{
      idPregunta: 41,
      required: false,
			pregunta: '¿Cuántas horas sueles dormir por la noche?',
			type: 'radiobutton',
			options: [
				{ text: 'menos de 6 horas' },
				{ text: 'De 6 a 7 horas' },
				{ text: 'De 7 a 8 horas' },
				{ text: 'Más de 8 horas' },
			],
		},
		{
      idPregunta: 42,
      required: false,
			pregunta: '¿Te vas a la cama más tarde de las 11 de la noche?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }, { text: 'A veces' }],
		},
		{
      idPregunta: 43,
      required: false,
			pregunta: '¿Usas alguna medicación o suplemento para ayudarte a dormir?',
		},
		{
      idPregunta: 44,
      required: false,
			pregunta: '¿Te despiertas antes de las 6 de la mañana?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }, { text: 'A veces' }],
		},
		{
      idPregunta: 45,
      required: false,
			pregunta: '¿Te falta la energía a medio día?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }, { text: 'A veces' }],
		},
		{
      idPregunta: 46,
      required: false,
			pregunta: '¿Estado civil?',
			type: 'radiobutton',
			options: [
				{ text: 'Soltero' },
				{ text: 'Con pareja' },
				{ text: 'Casado' },
			],
		},
		{
      idPregunta: 47,
      required: false,
			pregunta: '¿Tienes hijos?',
			type: 'radiobutton',
			options: [{ text: 'Si' }, { text: 'No' }],
		},
		{
      idPregunta: 48,
      required: false,
			pregunta: '¿Qué edad tienen tus hijos?',
			type: 'radiobutton',
			options: [
				{ text: 'Menos de tres' },
				{ text: 'De cuatro a 10' },
				{ text: 'De 10 a 14' },
				{ text: 'De 14 a 18' },
				{ text: 'Más de 18' },
			],
		},
	],
}

/* export const paisesCodes = [
  {
    name: "Afghanistan",
    code: "+93",
    src: 
  },
  {
    name: "Albania",
    code: "+355",
    src: 
  },
  {
    name: "Alemania",
    code: "+49",
    src: 
  },
  {
    name: "Andorra",
    code: "+376",
    src: 
  },
  {
    name: "Angola",
    code: "+244",
    src: 
  },
  {
    name: "Anguila",
    code: "+1",
    src: 
  },
  {
    name: "Antigua y Barbuda",
    code: "+1",
    src: 
  },
  {
    name: "Arabia Saudí",
    code: "+966",
    src: 
  },
  {
    name: "Argelia",
    code: "+213",
    src: 
  },
  {
    name: "Argentina",
    code: "+54",
    src: 
  },
  {
    name: "Armenia",
    code: "+374",
    src: 
  },
  {
    name: "Aruba",
    code: "+297",
    src: 
  },
  {
    name: "Australia",
    code: "+61",
    src: 
  },
  {
    name: "Austria",
    code: "+43",
    src: 
  },
  {
    name: "Azerbaiyán",
    code: "+994",
    src: 
  },
  {
    name: "Bahamas",
    code: "+1",
    src: 
  },
  {
    name: "Bangladés",
    code: "+880",
    src: 
  },
  {
    name: "Barbados",
    code: "+1",
    src: 
  },
  {
    name: "Baréin",
    code: "+973",
    src: 
  },
  {
    name: "Bélgica",
    code: "+32",
    src: 
  },
  {
    name: "Belice",
    code: "+501",
    src: 
  },
  {
    name: "Benín",
    code: "+229",
    src: 
  },
  {
    name: "Bermudas",
    code: "+1",
    src: 
  },
  {
    name: "Bielorrusia",
    code: "+375",
    src: 
  },
  {
    name: "Bolivia",
    code: "+591",
    src: 
  },
  {
    name: "Bosnia y Herzegovina",
    code: "+387",
    src: 
  },
  {
    name: "Botsuana",
    code: "+267",
    src: 
  },
  {
    name: "Brasil",
    code: "+55",
    src: 
  },
  {
    name: "Brunéi",
    code: "+673",
    src: 
  },
  {
    name: "Bulgaria",
    code: "+359",
    src: 
  },
  {
    name: "Burkina Faso",
    code: "+226",
    src: 
  },
  {
    name: "Burundi",
    code: "+257",
    src: 
  },
  {
    name: "Bután",
    code: "+975",
    src: 
  },
  {
    name: "Cabo Verde",
    code: "+238",
    src: 
  },
  {
    name: "Camboya",
    code: "+855",
    src: 
  },
  {
    name: "Camerún",
    code: "+237",
    src: 
  },
  {
    name: "Canadá",
    code: "+1",
    src: 
  },
  {
    name: "Catar",
    code: "+974",
    src: 
  },
  {
    name: "Chad",
    code: "+235",
    src: 
  },
  {
    name: "Chequia",
    code: "+420",
    src: 
  },
  {
    name: "Chile",
    code: "+56",
    src: 
  },
  {
    name: "China",
    code: "+86",
    src: 
  },
  {
    name: "Chipre",
    code: "+357",
    src: 
  },
  {
    name: "Colombia",
    code: "+57",
    src: 
  },
  {
    name: "Comoras",
    code: "+269",
    src: 
  },
  {
    name: "Corea del Norte",
    code: "+850",
    src: 
  },
  {
    name: "Corea del Sur",
    code: "+82",
    src: 
  },
  {
    name: "Costa Rica",
    code: "+506",
    src: 
  },
  {
    name: "Cote d'Ivoire",
    code: "+225",
    src: 
  },
  {
    name: "Croacia",
    code: "+385",
    src: 
  },
  {
    name: "Cuba",
    code: "+53",
    src: 
  },
  {
    name: "Curazao",
    code: "+599",
    src: 
  },
  {
    name: "Dinamarca",
    code: "+45",
    src: 
  },
  {
    name: "Dominica",
    code: "+1",
    src: 
  },
  {
    name: "Ecuador",
    code: "+593",
    src: 
  },
  {
    name: "Egipto",
    code: "+20",
    src: 
  },
  {
    name: "El Salvador",
    code: "+503",
    src: 
  },
  {
    name: "Emiratos Árabes Unidos",
    code: "+971",
    src: 
  },
  {
    name: "Eritrea",
    code: "+291",
    src: 
  },
  {
    name: "Eslovaquia",
    code: "+421",
    src: 
  },
  {
    name: "Eslovenia",
    code: "+386",
    src: 
  },
  {
    name: "España",
    code: "+34",
    src: 
  },
  {
    name: "Estados Unidos",
    code: "+1",
    src: 
  },
  {
    name: "Estonia",
    code: "+372",
    src: 
  },
  {
    name: "Etiopía",
    code: "+251",
    src: 
  },
  {
    name: "Filipinas",
    code: "+63",
    src: 
  },
  {
    name: "Finlandia",
    code: "+358",
    src: 
  },
  {
    name: "Fiyi",
    code: "+679",
    src: 
  },
  {
    name: "Francia",
    code: "+33",
    src: 
  },
  {
    name: "Gabón",
    code: "+241",
    src: 
  },
  {
    name: "Gambia",
    code: "+220",
    src: 
  },
  {
    name: "Georgia",
    code: "+995",
    src: 
  },
  {
    name: "Ghana",
    code: "+233",
    src: 
  },
  {
    name: "Gibraltar",
    code: "+350",
    src: 
  },
  {
    name: "Granada",
    code: "+1",
    src: 
  },
  {
    name: "Grecia",
    code: "+30",
    src: 
  },
  {
    name: "Guatemala",
    code: "+502",
    src: 
  },
  {
    name: "Guernsey",
    code: "+44",
    src: 
  },
  {
    name: "Guinea",
    code: "+224",
    src: 
  },
  {
    name: "Guinea Ecuatorial",
    code: "+240",
    src: 
  },
  {
    name: "Guinea-Bisáu",
    code: "+245",
    src: 
  },
  {
    name: "Guyana",
    code: "+592",
    src: 
  },
  {
    name: "Haiti",
    code: "+509",
    src: 
  },
  {
    name: "Honduras",
    code: "+504",
    src: 
  },
  {
    name: "Hungría",
    code: "+36",
    src: 
  },
  {
    name: "India",
    code: "+91",
    src: 
  },
  {
    name: "Indonesia",
    code: "+62",
    src: 
  },
  {
    name: "Irán",
    code: "+98",
    src: 
  },
  {
    name: "Irak",
    code: "+964",
    src: 
  },
  {
    name: "Irlanda",
    code: "+353",
    src: 
  },
  {
    name: "Isla de Man",
    code: "+44",
    src: 
  },
  {
    name: "Islandia",
    code: "+354",
    src: 
  },
  {
    name: "Islas Aland",
    code: "+358",
    src: 
  },
  {
    name: "Islas Caimán",
    code: "+1",
    src: 
  },
  {
    name: "Islas Feroe",
    code: "+298",
    src: 
  },
  {
    name: "Islas Malvinas",
    code: "+500",
    src: 
  },
  {
    name: "Islas Salomón",
    code: "+677",
    src: 
  },
  {
    name: "Islas Turcas y Caicos",
    code: "+1",
    src: 
  },
  {
    name: "Islas Vírgenes Británicas",
    code: "+1",
    src: 
  },
  {
    name: "Israel",
    code: "+972",
    src: 
  },
  {
    name: "Italia",
    code: "+39",
    src: 
  },
  {
    name: "Jamaica",
    code: "+1",
    src: 
  },
  {
    name: "Japón",
    code: "+81",
    src: 
  },
  {
    name: "Jersey",
    code: "+44",
    src: 
  },
  {
    name: "Jordania",
    code: "+962",
    src: 
  },
  {
    name: "Kazajistán",
    code: "+7",
    src: 
  },
  {
    name: "Kenia",
    code: "+254",
    src: 
  },
  {
    name: "Kirguistán",
    code: "+996",
    src: 
  },
  {
    name: "Kuwait",
    code: "+965",
    src: 
  },
  {
    name: "Laos",
    code: "+856",
    src: 
  },
  {
    name: "Lesoto",
    code: "+266",
    src: 
  },
  {
    name: "Letonia",
    code: "+371",
    src: 
  },
  {
    name: "Libano",
    code: "+961",
    src: 
  },
  {
    name: "Liberia",
    code: "+231",
    src: 
  },
  {
    name: "Libia",
    code: "+218",
    src: 
  },
  {
    name: "Liechtenstein",
    code: "+423",
    src: 
  },
  {
    name: "Lituania",
    code: "+370",
    src: 
  },
  {
    name: "Luxemburgo",
    code: "+352",
    src: 
  },
  {
    name: "Macedonia",
    code: "+389",
    src: 
  },
  {
    name: "Madagascar",
    code: "+261",
    src: 
  },
  {
    name: "Malasia",
    code: "+60",
    src: 
  },
  {
    name: "Malaui",
    code: "+265",
    src: 
  },
  {
    name: "Maldivas",
    code: "+960",
    src: 
  },
  {
    name: "Mali",
    code: "+223",
    src: 
  },
  {
    name: "Malta",
    code: "+356",
    src: 
  },
  {
    name: "Marruecos",
    code: "+212",
    src: 
  },
  {
    name: "Mauricio",
    code: "+230",
    src: 
  },
  {
    name: "Mauritania",
    code: "+222",
    src: 
  },
  {
    name: "Mexico",
    code: "+52",
    src: 
  },
  {
    name: "Micronesia",
    code: "+691",
    src: 
  },
  {
    name: "Moldavia",
    code: "+373",
    src: 
  },
  {
    name: "Monaco",
    code: "+377",
    src: 
  },
  {
    name: "Mongolia",
    code: "+976",
    src: 
  },
  {
    name: "Montenegro",
    code: "+382",
    src: 
  },
  {
    name: "Montserrat",
    code: "+1",
    src: 
  },
  {
    name: "Mozambique",
    code: "+258",
    src: 
  },
  {
    name: "Myanmar (Birmania)",
    code: "+95",
    src: 
  },
  {
    name: "Namibia",
    code: "+264",
    src: 
  },
  {
    name: "Nepal",
    code: "+977",
    src: 
  },
  {
    name: "Nicaragua",
    code: "+505",
    src: 
  },
  {
    name: "Niger",
    code: "+227",
    src: 
  },
  {
    name: "Nigeria",
    code: "+234",
    src: 
  },
  {
    name: "Noruega",
    code: "+47",
    src: 
  },
  {
    name: "Nueva Zelanda",
    code: "+64",
    src: 
  },
  {
    name: "Omán",
    code: "+968",
    src: 
  },
  {
    name: "Países Bajos",
    code: "+31",
    src: 
  },
  {
    name: "Pakistan",
    code: "+92",
    src: 
  },
  {
    name: "Palos",
    code: "+680",
    src: 
  },
  {
    name: "Panama",
    code: "+507",
    src: 
  },
  {
    name: "Papúa Nueva Guinea",
    code: "+675",
    src: 
  },
  {
    name: "Paraguay",
    code: "+595",
    src: 
  },
  {
    name: "Perú",
    code: "+51",
    src: 
  },
  {
    name: "Polinesia Francesa",
    code: "+689",
    src: 
  },
  {
    name: "Polonia",
    code: "+48",
    src: 
  },
  {
    name: "Portugal",
    code: "+351",
    src: 
  },
  {
    name: "Puerto Rico",
    code: "+1",
    src: 
  },
  {
    name: "RAE de Honk Kong (China)",
    code: "+852",
    src: 
  },
  {
    name: "RAE de Macao (China)",
    code: "+853",
    src: 
  },
  {
    name: "Reino Unido",
    code: "+44",
    src: 
  },
  {
    name: "República Centroafricana",
    code: "+236",
    src: 
  },
  {
    name: "República del Congo",
    code: "+242",
    src: 
  },
  {
    name: "República Democrática del Congo",
    code: "+243",
    src: 
  },
  {
    name: "República Dominicana",
    code: "+1",
    src: 
  },
  {
    name: "Reunión",
    code: "+262",
    src: 
  },
  {
    name: "Ruanda",
    code: "+250",
    src: 
  },
  {
    name: "Rumanía",
    code: "+40",
    src: 
  },
  {
    name: "Rusia",
    code: "+7",
    src: 
  },
  {
    name: "Samoa",
    code: "+685",
    src: 
  },
  {
    name: "San Cristóbal y Nieves",
    code: "+1",
    src: 
  },
  {
    name: "San Marino",
    code: "+378",
    src: 
  },
  {
    name: "San Vicente y las Granadinas",
    code: "+1",
    src: 
  },
  {
    name: "Santa Elena",
    code: "+290",
    src: 
  },
  {
    name: "Santa Lucia",
    code: "+1",
    src: 
  },
  {
    name: "Santo Tomé y Principe",
    code: "+239",
    src: 
  },
  {
    name: "Senegal",
    code: "+221",
    src: 
  },
  {
    name: "Serbia",
    code: "+381",
    src: 
  },
  {
    name: "Seychelles",
    code: "+248",
    src: 
  },
  {
    name: "Sierra Leona",
    code: "+232",
    src: 
  },
  {
    name: "Singapur",
    code: "+65",
    src: 
  },
  {
    name: "Siria",
    code: "+963",
    src: 
  },
  {
    name: "Somalia",
    code: "+252",
    src: 
  },
  {
    name: "Sri Lanka",
    code: "+94",
    src: 
  },
  {
    name: "Suazilandia",
    code: "+268",
    src: 
  },
  {
    name: "Sudáfrica",
    code: "+27",
    src: 
  },
  {
    name: "Sudán",
    code: "+249",
    src: 
  },
  {
    name: "Suecia",
    code: "+46",
    src: 
  },
  {
    name: "Suiza",
    code: "+41",
    src: 
  },
  {
    name: "Surinam",
    code: "+597",
    src: 
  },
  {
    name: "Tailandia",
    code: "+66",
    src: 
  },
  {
    name: "Taiwán",
    code: "+886",
    src: 
  },
  {
    name: "Tanzania",
    code: "+255",
    src: 
  },
  {
    name: "Tayikistán",
    code: "+992",
    src: 
  },
  {
    name: "Territorios Palestinos",
    code: "+970",
    src: 
  },
  {
    name: "Timor-Leste",
    code: "+670",
    src: 
  },
  {
    name: "Togo",
    code: "+228",
    src: 
  },
  {
    name: "Tonga",
    code: "+676",
    src: 
  },
  {
    name: "Trinidad y Tobago",
    code: "+1",
    src: 
  },
  {
    name: "Túnez",
    code: "+216",
    src: 
  },
  {
    name: "Turkmenistan",
    code: "+993",
    src: 
  },
  {
    name: "Turquía",
    code: "+90",
    src: 
  },
  {
    name: "Ucrania",
    code: "+380",
    src: 
  },
  {
    name: "Uganda",
    code: "+256",
    src: 
  },
  {
    name: "Uruguay",
    code: "+598",
    src: 
  },
  {
    name: "Uzbekistán",
    code: "+998",
    src: 
  },
  {
    name: "Vanuatu",
    code: "+678",
    src: 
  },
  {
    name: "Venezuela",
    code: "+58",
    src: 
  },
  {
    name: "Vietnam",
    code: "+84",
    src: 
  },
  {
    name: "Yemen",
    code: "+967",
    src: 
  },
  {
    name: "Yibuti",
    code: "+253",
    src: 
  },
  {
    name: "Zambia",
    code: "+260",
    src: 
  },
  {
    name: "Zimbabue",
    code: "+263",
    src: 
  }
]

export const  paises = [
  {
     code: "+93",
     name: "Afganistán",
     src: 
  },
  {
     code: "+355",
     name: "Albania",
     src: 
  },
  {
     code: "+49",
     name: "Alemania",
     src: 
  },
  {
     code: "+376",
     name: "Andorra",
     src: 
  },
  {
     code: "+244",
     name: "Angola",
     src: 
  },
  {
     code: "+1",
     name: "Anguila",
     src: 
  },
  {
     code: "+1",
     name: "Antigua y Barbuda",
     src: 
  },
  {
     code: "+966",
     name: "Arabia Saudí",
     src: 
  },
  {
     code: "+213",
     name: "Argelia",
     src: 
  },
  {
     code: "+54",
     name: "Argentina",
     src: 
  },
  {
     code: "+374",
     name: "Armenia",
     src: 
  },
  {
     code: "+297",
     name: "Aruba",
     src: 
  },
  {
     code: "+61",
     name: "Australia",
     src: 
  },
  {
     code: "+43",
     name: "Austria",
     src: 
  },
  {
     code: "+994",
     name: "Azerbaiyán",
     src: 
  },
  {
     code: "+1",
     name: "Bahamas",
     src: 
  },
  {
     code: "+880",
     name: "Bangladés",
     src: 
  },
  {
     code: "+1",
     name: "Barbados",
     src: 
  },
  {
     code: "+973",
     name: "Baréin",
     src: 
  },
  {
     code: "+32",
     name: "Bélgica",
     src: 
  },
  {
     code: "+501",
     name: "Belice",
     src: 
  },
  {
     code: "+229",
     name: "Benín",
     src: 
  },
  {
     code: "+1",
     name: "Bermudas",
     src: 
  },
  {
     code: "+375",
     name: "Bielorrusia",
     src: 
  },
  {
     code: "+591",
     name: "Bolivia",
     src: 
  },
  {
     code: "+387",
     name: "Bosnia-Herzegovina",
     src: 
  },
  {
     code: "+267",
     name: "Botsuana",
     src: 
  },
  {
     code: "+55",
     name: "Brasil",
     src: 
  },
  {
     code: "+673",
     name: "Brunéi",
     src: 
  },
  {
     code: "+359",
     name: "Bulgaria",
     src: 
  },
  {
     code: "+226",
     name: "Burkina Faso",
     src: 
  },
  {
     code: "+257",
     name: "Burundi",
     src: 
  },
  {
     code: "+975",
     name: "Bután",
     src: 
  },
  {
     code: "+238",
     name: "Cabo Verde",
     src: 
  },
  {
     code: "+855",
     name: "Camboya",
     src: 
  },
  {
     code: "+237",
     name: "Camerún",
     src: 
  },
  {
     code: "+1",
     name: "Canadá",
     src: 
  },
  {
     code: "+974",
     name: "Catar",
     src: 
  },
  {
     code: "+235",
     name: "Chad",
     src: 
  },
  {
     code: "+420",
     name: "Chequia",
     src: 
  },
  {
     code: "+56",
     name: "Chile",
     src: 
  },
  {
     code: "+86",
     name: "China",
     src: 
  },
  {
     code: "+357",
     name: "Chipre",
     src: 
  },
  {
     code: "+57",
     name: "Colombia",
     src: 
  },
  {
     code: "+269",
     name: "Comoras",
     src: 
  },
  {
     code: "+850",
     name: "Corea del Norte",
     src: 
  },
  {
     code: "+82",
     name: "Corea del Sur",
     src: 
  },
  {
     code: "+506",
     name: "Costa Rica",
     src: 
  },
  {
     code: "+225",
     name: "Côte d’Ivoire",
     src: 
  },
  {
     code: "+385",
     name: "Croacia",
     src: 
  },
  {
     code: "+53",
     name: "Cuba",
     src: 
  },
  {
     code: "+599",
     name: "Curazao",
     src: 
  },
  {
     code: "+45",
     name: "Dinamarca",
     src: 
  },
  {
     code: "+1",
     name: "Dominica",
     src: 
  },
  {
     code: "+593",
     name: "Ecuador",
     src: 
  },
  {
     code: "+20",
     name: "Egipto",
     src: 
  },
  {
     code: "+503",
     name: "El Salvador",
     src: 
  },
  {
     code: "+971",
     name: "Emiratos Árabes Unidos",
     src: 
  },
  {
     code: "+291",
     name: "Eritrea",
     src: 
  },
  {
     code: "+421",
     name: "Eslovaquia",
     src: 
  },
  {
     code: "+386",
     name: "Eslovenia",
     src: 
  },
  {
     code: "+34",
     name: "España",
     src: 
  },
  {
     code: "+1",
     name: "Estados Unidos",
     src: 
  },
  {
     code: "+372",
     name: "Estonia",
     src: 
  },
  {
     code: "+251",
     name: "Etiopía",
     src: 
  },
  {
     code: "+63",
     name: "Filipinas",
     src: 
  },
  {
     code: "+358",
     name: "Finlandia",
     src: 
  },
  {
     code: "+679",
     name: "Fiyi",
     src: 
  },
  {
     code: "+33",
     name: "Francia",
     src: 
  },
  {
     code: "+241",
     name: "Gabón",
     src: 
  },
  {
     code: "+220",
     name: "Gambia",
     src: 
  },
  {
     code: "+995",
     name: "Georgia",
     src: 
  },
  {
     code: "+233",
     name: "Ghana",
     src: 
  },
  {
     code: "+350",
     name: "Gibraltar",
     src: 
  },
  {
     code: "+1",
     name: "Granada",
     src: 
  },
  {
     code: "+30",
     name: "Grecia",
     src: 
  },
  {
     code: "+502",
     name: "Guatemala",
     src: 
  },
  {
     code: "+44",
     name: "Guernesey",
     src: 
  },
  {
     code: "+224",
     name: "Guinea",
     src: 
  },
  {
     code: "+240",
     name: "Guinea Ecuatorial",
     src: 
  },
  {
     code: "+245",
     name: "Guinea-Bisáu",
     src: 
  },
  {
     code: "+592",
     name: "Guyana",
     src: 
  },
  {
     code: "+509",
     name: "Haití",
     src: 
  },
  {
     code: "+504",
     name: "Honduras",
     src: 
  },
  {
     code: "+36",
     name: "Hungría",
     src: 
  },
  {
     code: "+91",
     name: "India",
     src: 
  },
  {
     code: "+62",
     name: "Indonesia",
     src: 
  },
  {
     code: "+964",
     name: "Irak",
     src: 
  },
  {
     code: "+98",
     name: "Irán",
     src: 
  },
  {
     code: "+353",
     name: "Irlanda",
     src: 
  },
  {
     code: "+44",
     name: "Isla de Man",
     src: 
  },
  {
     code: "+354",
     name: "Islandia",
     src: 
  },
  {
     code: "+358",
     name: "Islas Åland",
     src: 
  },
  {
     code: "+1",
     name: "Islas Caimán",
     src: 
  },
  {
     code: "+298",
     name: "Islas Feroe",
     src: 
  },
  {
     code: "+500",
     name: "Islas Malvinas",
     src: 
  },
  {
     code: "+677",
     name: "Islas Salomón",
     src: 
  },
  {
     code: "+1",
     name: "Islas Turcas y Caicos",
     src: 
  },
  {
     code: "+1",
     name: "Islas Vírgenes Británicas",
     src: 
  },
  {
     code: "+972",
     name: "Israel",
     src: 
  },
  {
     code: "+39",
     name: "Italia",
     src: 
  },
  {
     code: "+1",
     name: "Jamaica",
     src: 
  },
  {
     code: "+81",
     name: "Japón",
     src: 
  },
  {
     code: "+44",
     name: "Jersey",
     src: 
  },
  {
     code: "+962",
     name: "Jordania",
     src: 
  },
  {
     code: "+7",
     name: "Kazajistán",
     src: 
  },
  {
     code: "+254",
     name: "Kenia",
     src: 
  },
  {
     code: "+996",
     name: "Kirguistán",
     src: 
  },
  {
     code: "+965",
     name: "Kuwait",
     src: 
  },
  {
     code: "+856",
     name: "Laos",
     src: 
  },
  {
     code: "+266",
     name: "Lesoto",
     src: 
  },
  {
     code: "+371",
     name: "Letonia",
     src: 
  },
  {
     code: "+961",
     name: "Líbano",
     src: 
  },
  {
     code: "+231",
     name: "Liberia",
     src: 
  },
  {
     code: "+218",
     name: "Libia",
     src: 
  },
  {
     code: "+423",
     name: "Liechtenstein",
     src: 
  },
  {
     code: "+370",
     name: "Lituania",
     src: 
  },
  {
     code: "+352",
     name: "Luxemburgo",
     src: 
  },
  {
     code: "+389",
     name: "Macedonia",
     src: 
  },
  {
     code: "+261",
     name: "Madagascar",
     src: 
  },
  {
     code: "+60",
     name: "Malasia",
     src: 
  },
  {
     code: "+265",
     name: "Malaui",
     src: 
  },
  {
     code: "+960",
     name: "Maldivas",
     src: 
  },
  {
     code: "+223",
     name: "Mali",
     src: 
  },
  {
     code: "+356",
     name: "Malta",
     src: 
  },
  {
     code: "+212",
     name: "Marruecos",
     src: 
  },
  {
     code: "+230",
     name: "Mauricio",
     src: 
  },
  {
     code: "+222",
     name: "Mauritania",
     src: 
  },
  {
     code: "+52",
     name: "México",
     src: 
  },
  {
     code: "+691",
     name: "Micronesia",
     src: 
  },
  {
     code: "+373",
     name: "Moldavia",
     src: 
  },
  {
     code: "+377",
     name: "Mónaco",
     src: 
  },
  {
     code: "+976",
     name: "Mongolia",
     src: 
  },
  {
     code: "+382",
     name: "Montenegro",
     src: 
  },
  {
     code: "+1",
     name: "Montserrat",
     src: 
  },
  {
     code: "+258",
     name: "Mozambique",
     src: 
  },
  {
     code: "+95",
     name: "Myanmar (Birmania)",
     src: 
  },
  {
     code: "+264",
     name: "Namibia",
     src: 
  },
  {
     code: "+977",
     name: "Nepal",
     src: 
  },
  {
     code: "+505",
     name: "Nicaragua",
     src: 
  },
  {
     code: "+227",
     name: "Níger",
     src: 
  },
  {
     code: "+234",
     name: "Nigeria",
     src: 
  },
  {
     code: "+47",
     name: "Noruega",
     src: 
  },
  {
     code: "+64",
     name: "Nueva Zelanda",
     src: 
  },
  {
     code: "+968",
     name: "Omán",
     src: 
  },
  {
     code: "+31",
     name: "Países Bajos",
     src: 
  },
  {
     code: "+92",
     name: "Pakistán",
     src: 
  },
  {
     code: "+680",
     name: "Palaos",
     src: 
  },
  {
     code: "+507",
     name: "Panamá",
     src: 
  },
  {
     code: "+675",
     name: "Papúa Nueva Guinea",
     src: 
  },
  {
     code: "+595",
     name: "Paraguay",
     src: 
  },
  {
     code: "+51",
     name: "Perú",
     src: 
  },
  {
     code: "+689",
     name: "Polinesia Francesa",
     src: 
  },
  {
     code: "+48",
     name: "Polonia",
     src: 
  },
  {
     code: "+351",
     name: "Portugal",
     src: 
  },
  {
     code: "+1",
     name: "Puerto Rico",
     src: 
  },
  {
     code: "+852",
     name: "RAE de Hong Kong (China)",
     src: 
  },
  {
     code: "+853",
     name: "RAE de Macao (China)",
     src: 
  },
  {
     code: "+44",
     name: "Reino Unido",
     src: 
  },
  {
     code: "+236",
     name: "República Centroafricana",
     src: 
  },
  {
     code: "+242",
     name: "República del Congo",
     src: 
  },
  {
     code: "+243",
     name: "República Democrática del Congo",
     src: 
  },
  {
     code: "+1",
     name: "República Dominicana",
     src: 
  },
  {
     code: "+262",
     name: "Reunión",
     src: 
  },
  {
     code: "+250",
     name: "Ruanda",
     src: 
  },
  {
     code: "+40",
     name: "Rumanía",
     src: 
  },
  {
     code: "+7",
     name: "Rusia",
     src: 
  },
  {
     code: "+685",
     name: "Samoa",
     src: 
  },
  {
     code: "+1",
     name: "San Cristóbal y Nieves",
     src: 
  },
  {
     code: "+378",
     name: "San Marino",
     src: 
  },
  {
     code: "+1",
     name: "San Vicente y las Granadinas",
     src: 
  },
  {
     code: "+290",
     name: "Santa Elena",
     src: 
  },
  {
     code: "+1",
     name: "Santa Lucía",
     src: 
  },
  {
     code: "+239",
     name: "Santo Tomé y Príncipe",
     src: 
  },
  {
     code: "+221",
     name: "Senegal",
     src: 
  },
  {
     code: "+381",
     name: "Serbia",
     src: 
  },
  {
     code: "+248",
     name: "Seychelles",
     src: 
  },
  {
     code: "+232",
     name: "Sierra Leona",
     src: 
  },
  {
     code: "+65",
     name: "Singapur",
     src: 
  },
  {
     code: "+963",
     name: "Siria",
     src: 
  },
  {
     code: "+252",
     name: "Somalia",
     src: 
  },
  {
     code: "+94",
     name: "Sri Lanka",
     src: 
  },
  {
     code: "+268",
     name: "Suazilandia",
     src: 
  },
  {
     code: "+27",
     name: "Sudáfrica",
     src: 
  },
  {
     code: "+249",
     name: "Sudán",
     src: 
  },
  {
     code: "+46",
     name: "Suecia",
     src: 
  },
  {
     code: "+41",
     name: "Suiza",
     src: 
  },
  {
     code: "+597",
     name: "Surinam",
     src: 
  },
  {
     code: "+66",
     name: "Tailandia",
     src: 
  },
  {
     code: "+886",
     name: "Taiwán",
     src: 
  },
  {
     code: "+255",
     name: "Tanzania",
     src: 
  },
  {
     code: "+992",
     name: "Tayikistán",
     src: 
  },
  {
     code: "+970",
     name: "Territorios Palestinos",
     src: 
  },
  {
     code: "+670",
     name: "Timor-Leste",
     src: 
  },
  {
     code: "+228",
     name: "Togo",
     src: 
  },
  {
     code: "+676",
     name: "Tonga",
     src: 
  },
  {
     code: "+1",
     name: "Trinidad y Tobago",
     src: 
  },
  {
     code: "+216",
     name: "Túnez",
     src: 
  },
  {
     code: "+993",
     name: "Turkmenistán",
     src: 
  },
  {
     code: "+90",
     name: "Turquía",
     src: 
  },
  {
     code: "+380",
     name: "Ucrania",
     src: 
  },
  {
     code: "+256",
     name: "Uganda",
     src: 
  },
  {
     code: "+598",
     name: "Uruguay",
     src: 
  },
  {
     code: "+998",
     name: "Uzbekistán",
     src: 
  },
  {
     code: "+678",
     name: "Vanuatu",
     src:
  },
  {
     code: "+58",
     name: "Venezuela",
     src: 
  },
  {
     code: "+84",
     name: "Vietnam",
     src: 
  },
  {
     code: "+967",
     name: "Yemen",
     src: 
  },
  {
     code: "+253",
     name: "Yibuti",
     src: 
  },
  {
     code: "+260",
     name: "Zambia",
     src: 
  },
  {
     code: "+263",
     name: "Zimbabue",
     src: 
  }
] */
export const VALIDATED_EMPTY = 'VALIDATED_EMPTY'

export const emptyFile = {
	name: '',
	lastModified: 0,
	size: 0,
	type: '',
	data: '',
}

export const notificationEmailEmployee = 'albertoparedes@yoseomarketing.com'
export const emailsIds = {
	initialFormSent: '91fb5e64-21ab-47cd-87a9-2335d045b655',
	noTemplates: 'Nueva notificación',

	nofiticationEmail: 'b12e1e93-93e7-46b3-9f55-64297cc518aa',
	verificatedInitialForm: '82dc9b00-d172-4a44-96b4-6817ffcda195',
	phase1: '612f6fd5-e8c5-4ece-bd66-226d9420ef18',
	checkSent: '547e52c3-90f9-4f51-87b7-341144b16cd9',
	endPhaseSent: '8511b545-e75f-418b-aed3-200d13e0f663',
}

export const emailsSubjects = {
	nofiticationEmail: 'Tienes una notificación nueva',
	initialForm: 'Formulario inicial',
}

export const protocoloDoce = `
<div>Hola y bienvenido a DOCE,</div>
<br/>
<br/>
<div>Como sabes nuestra intención es darte las mejores herramientas posibles para que consigas tus objetivos. Aquí vas a encontrar el protocolo básico de DOCE.</div>
<br/>
<br/>
<div>Por favor: léelo completo para interiorizar el funcionamiento del Plan y poderle sacar el mayor partido posible.</div>
<br/>
<br/>
<div><span style="color: #98070a;"><strong>EVALUACIÓN CADA DOS SEMANAS</strong></span></div>
<br/>
<br/>
<div>Para conocer tu progreso y poder darte un servicio más cercano, haremos evaluaciones cada dos semanas. ¿Cómo será el procedimiento?</div>
<br/>
<br/>
<div><ol>
  <li>Os llegará una notificación en el email y otra en la app para rellenar los datos necesarios en esta evaluación.</li>
  <li>¿Qué datos os pediremos? Depende de cada fase, pero lo común a todas será: peso, medida de cintura, NEAT, adherencia al plan nutricional y fotos.</li>
</ol></div>
<br/>
<br/>
<div>Una vez que lo recibamos, el equipo estudiaremos tu perfil y tomaremos las medidas necesarias para seguir progresando al mejor ritmo posible.  Tardaremos entre 48 y 72 horas laborables en darte nuestro feedback.</div>
<br/>
<br/>
<div><span style="color: #98070a;"><strong>GESTIONES DE FINES DE FASE Y EVALUACIONES CADA DOS SEMANAS</strong></span></div>
<br/>
<br/>
<div>Las evaluaciones se gestionan y responden lunes, miércoles y viernes. Lo hacemos de esta forma para ser más eficientes con nuestro tiempo, y poder trabajar exclusivamente sobre vuestras evaluaciones el resto de días de la semana.</div>
<br/>
<br/>
<div><strong>Pro tip</strong>: si estáis a punto de finalizar vuestra fase y queréis empezar la siguiente fase el lunes siguiente, es vital que rellenéis la evaluación de fin de fase el jueves por la mañana. De esta forma nos dará tiempo a poder procesar vuestro perfil antes del lunes.</div>
<br/>
<br/>
<div>Si lo enviáis el viernes, o en fin de semana, no será procesado hasta el lunes. Por favor, recordad que no ofrecemos un servicio de atención al cliente 24/7.</div>
<br/>
<br/>
<br/>
<br/>

<div><span style="color: #98070a;"><strong>CONSULTA DE DUDAS</strong></span></div>
<br/>
<br/>
<div>En la app puedes encontrar la sección "Ponte en contacto con nosotros" con tres opciones:</div>
<br/>
<br/>
<div><ol>
  <li>Preguntas y respuestas: aquí podrás encontrar resueltas la gran mayoría de preguntas típicas de un Espartano o Guerrera de DOCE.</li>
  <li>Grupo de Facebook: es la vía principal de resoluciones de dudas. Es fundamental que cualquier duda sobre entrenamiento, nutrición, NEAT, etc, sea a través del grupo, y por favor recordad que el grupo tiene un “buscador” dónde podéis buscar palabras clave para encontrar cualquier post que se haya subido desde su</li>
  <li>Whatsapp: solo se debe usar en caso de urgencia técnica.</li>
</ol></div>
<br/>
<br/>
<br/>
<br/>
<div><span style="color: #98070a;"><strong>¿NO HE RECIBIDO RESPUESTA EN 48 A 72 HORAS LABORABLES?</strong></span></div>
<br/>
<br/>
<div>Nuestro plazo de respuesta para dudas es de 48 horas laborables. Para evaluaciones es de 72 horas laborables.</div>
<br/>
<br/>
<div>Remarcamos la palabra laborables, ya que no son horas naturales, y aunque siempre intentamos contestar lo antes posible dentro de este plazo, no siempre es posible.</div>
<br/>
<br/>
<div>Si no has recibido respuesta a una evaluación o a un post de Facebook en 72 horas laborables, escríbenos por Whatsapp al siguiente teléfono: 633515837. Ten en cuenta que se pueden dar los siguientes casos:</div>
<br/>
<br/>
<div><ol>
  <li>No hemos visto tu post en Facebook, porque aunque nos hayas etiquetado esto puede pasar.</li>
  <li>No hemos contestado al post en FB porque ya te han dado respuesta otros compañeros del grupo (para eso estan los grupos, para crear comunidad).</li>
  <li>O incluso que haya habido un fallo humano por nuestra parte al procesar tu evaluación.</li>
</ol></div>
<div>Es importante también que no dupliques las dudas. ¿Qué quiere decir esto?</div>
<br/>
<br/>
<div>Que por favor no nos mandéis por correo una duda que ya has puesto en el grupo de Facebook. Y por favor piensa de antemano si tu tipo de duda debe ser resuelta en el grupo (o tal vez ya esté resuelta con anterioridad en otro post, y sólo tienes que usar el buscador) o si es una duda de índole personal/privado que es mejor resolver en una evaluación.</div>
<br/>
<br/>
<br/>
<br/>

<div><strong><span style="color: #98070a;">CONTEXTO</span></strong></div>
<br/>
<br/>
<div>Entendemos que el ritmo de vida de cada uno es distinto, y en muchos casos estás muy ocupado. Pero es importante que cuando nos escribas dudas, nos pongas en contexto sobre nuestras conversaciones anteriores.</div>
<br/>
<br/>
<div>¿Por qué? Porque agiliza infinitamente el proceso y recibirás respuesta mucho antes, y si todos lo hacéis así, el proceso será mucho más fluido. Por favor recuerda que sois muchos clientes y es imposible recordar cada uno de vuestros detalles, excepciones o conversaciones pasadas. Y al darnos contexto nos ayudarás muchísimo a agilizar el servicio y salir así todos beneficiados.</div>
<br/>
<br/>
<br/>
<br/>

<div><span style="color: #98070a;"><strong>DATOS EN LAS EVALUACIONES</strong></span></div>
<br/>
<br/>
<div>Tanto en los fines de fase como en las evaluaciones/check-ins a mitad de fase, te pedimos que rellenes una serie de datos. Por favor revisa siempre que has rellenado todo.</div>
<br/>
<br/>
<div>Si no lo haces, no contamos con los da
tos necesarios para poder darte un servicio excelente (en DOCE buscamos la excelencia) en el menor tiempo posible, y se retrasa mucho el proceso tanto para ti como para nosotros.</div>
<br/>
<br/>
<div>Por favor, revisa siempre que has rellenado todos los datos que te pedimos. Por último, te pedimos por favor que leas detenidamente este texto, y lo releas si es necesario para interiorizar bien el protocolo de funcionamiento de DOCE.</div>
<br/>
<br/>
<br/>
<br/>

<div>Como siempre darte las gracias por confiar en nosotros para ayudarte a conseguir tus objetivos, es algo que no nos tomamos a la ligera. ¡GRACIAS!</div>
`

export const guiaDoce = `
<h2><strong><span style="color: #98070a; font-size: 18pt;">1. Introducción al sistema DOCE y nuestros protocolos.</span></strong> </h2>
<br/><br/>
<div>¡Ya eres parte de la familia DOCE!</div>
<br/><br/>
<div><span style="font-weight: 400;">Gracias por estar aquí, por tu confianza y enhorabuena por tomar las riendas; esto en sí mismo ya es una mini victoria que deberíamos celebrar.</span></div> 
<br/><br/>
<div><b>En esta página encontrarás </b><div><span style="font-weight: 400;">información relevante sobre nuestro camino juntos, en ellos verás desde protocolos a seguir para asegurar tu éxito hasta pequeñas “píldoras de información” con trucos y otras cosas que te ayudarán en tus hábitos, nutrición, entrenamiento y estilo de vida en general.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Además de la Guía DOCE, donde estás ahora mismo, en la app también tendrás acceso a:</span></div> 
<br/><br/>
<div><ul>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">Tu plan de entrenamiento.</span></div> </li>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">Tu plan nutricional.</span></div> </li>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">Tu </span></div> <i><div><span style="font-weight: 400;">Meal Planner</span></div> </i><div><span style="font-weight: 400;"> para este primer mes.</span></div> </li>
</ul></div>
<br/><br/>
<div><span style="font-weight: 400;">En el plan de entrenamiento verás que tienes las tablas de las próximas cuatro semanas al principio del PDF y esas mismas tablas “sin rellenar” al final. Esto es así para que puedas imprimir tus entrenamientos y rellenarlos semanalmente de forma que puedas llevar un seguimiento de tu progreso e ir ajustando semanalmente en función del mismo. </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">En el plan nutricional recibirás distintas indicaciones para el día a día de estas primeras semanas y más adelante, en esta Guía DOCE,  verás la importancia de preparar tus comidas, trucos para hacerlo fácil, cómo comer fuera… </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Con el </span></div> <i><div><span style="font-weight: 400;">Meal Planner</span></div> </i><div><span style="font-weight: 400;"> te invitamos a hacer algo distinto. Queremos que, por un tiempo limitado, veas el poder que puede tener el planificar tu nutrición con intención. Vas a practicar el apuntar lo que planeas comer esa semana por adelantado para que puedas ver cómo se compara eso con tu plan y para que puedas hacer pequeños ajustes que te acerquen a tu objetivos.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">¿Tienes preguntas?</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">A veces tenemos respuestas. :)</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Con tu plan DOCE también tienes acceso, a través de esta app, a nuestras páginas privadas con vídeos de entrenamiento, nutrición, FAQ, motivación etc que hemos creado con la experiencia de más de 1000 clientes en los últimos años y que actualizamos de forma periódica.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Por último, sabemos que esto puede ser un poco abrumador al principio. Por eso hemos creado una comunidad DOCE, nuestro grupo privado en Facebook, al que has recibido una invitación reciente.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">En el grupo podrás conectar con otros muchos miembros del programa que tienen tus mismas dudas, inquietudes y objetivos. Y que junto a nuestros entrenadores te apoyarán, te resolverán tus dudas y te ayudarán a mantener la motivación alta y a ser constante en los momentos duros.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Es importante que cualquier duda que te surja del programa la plasmes en el muro del grupo privado, de forma que otros miembros puedan ver también la respuesta que te demos a dicha duda, o dártela ellos mismos si ya se han visto en esa situación.</span></div> 
<br/><br/>
<div><b>¿Cómo funcionaremos para comunicarnos, medir el progreso y hacer ajustes cuando sea necesario?</b></div>
<br/><br/>
<div><span style="font-weight: 400;">Cada 2 semanas a través de la App DOCE nos enviarás tus fotos de progreso, medidas y peso (recibirás una notificación por correo y otra en la app) sólo tendrás que rellenarlo y enviárnoslo, para que uno de nuestros entrenadores pueda revisar contigo tu progreso y que ajustes debemos hacer a tu plan para que sigas avanzando camino a tus objetivos.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Este proceso llevará 3 días laborables en los cuales planificaremos tu plan nutricional para las siguientes semanas. Además cada cuatro semanas te asignaremos el plan de entrenamiento nuevo que mejor encaje contigo.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Si en algún momento necesitas asistencia técnica por favor no dudes en contactar con nuestro equipo a través del grupo privado de Facebook. Insistimos en que este sea el medio de comunicación para que así todas las dudas y problemas que puedan surgir queden resueltos para todos los miembros de la comunidad DOCE (¡el buscador del grupo es una mina de oro!) No obstante, si la duda no es relativa al programa y es más bien un problema técnico con la web o similar, puedes contactar con nosotros mandando un e-mail a <a href="equipo@programadoce.com">equipo@programadoce.com</a></span></div> 
<br/><br/>
<br/><br/>
<div><h2><strong><span style="color: #98070a; font-size: 18pt;">2. Principios nutricionales DOCE</span></div> <div><span style="font-weight: 400;"> </span></h2></div>
<br/><br/>
<div><span style="font-weight: 400;">En DOCE no usamos la palabra «dieta».</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">¿No te esperabas algo tan drástico?</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Lo que muchas veces no se comenta es que «dieta», del latín </span></div> <i><div><span style="font-weight: 400;">diaeta, </span></div> </i><div><span style="font-weight: 400;">no es más que una palabra usada para definir lo que comemos a diario o, si usamos la definición del latín; nuestra manera de vivir.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Si eres un competidor de culturismo, o un actor preparándote para una película que requiere que consigas una transformación física espectacular en un corto periodo de tiempo, tendrás que hacer grandes sacrificios en los meses previos a la competición o la película y eso es más posible que requiera de grandes restricciones alimenticias.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Pero si lo que buscas es cambiar tu estilo de vida y hábitos nutricionales a mejor porque quieres mejorar tu aspecto físico, tu salud, tu rendimiento deportivo (o en el gimnasio) y tu relación con la comida… Tu plan nutricional no debe estar basado en grandes restricciones que lo hagan aburrido e insoportable.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Vamos a repetir esto último.</span></div> 
<br/><br/>
<b>Si quieres cambiar tu estilo de vida y mejorar tus hábitos nutricionales, tu plan nutricional no debe estar basado en grandes restricciones que lo hagan aburrido e insoportable.</b>
<br/><br/>
<div><span style="font-weight: 400;">A pesar de lo que muchos se empeñen en decir, la comida no es sólo “combustible”. La comida es parte fundamental de nuestras relaciones sociales y laborales, obviar este hecho sería un gran error por nuestra parte.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Entonces, ¿por qué no usamos la palabra dieta?</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Como hemos visto una dieta es, en teoría, el conjunto de alimentos que ingerimos en un periodo de 24 horas. El motivo de que no nos guste usar esa palabra es que hoy en día asociamos la palabra dieta a planes alimenticios orientados a la pérdida de peso, que en muchos casos son excesivamente bajos en calorías.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Planes que por lo general son insostenibles. Planes que nos llevan de cabeza y que nos hacen sentir en una cárcel. Planes que ni podemos, ni debemos mantener en el tiempo, ya que nos llevarán a desarrollar una relación negativa con la comida, ¿por qué no tenemos en cuenta nuestra preferencia personal a la hora de comer?</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Lo que te gusta comer importa, lo que la evidencia dice sobre lo que debemos y cuánto debemos comer para mejorar nuestro físico y salud también. Encontremos ese equilibrio en el que ambos se crucen, será mucho más sencillo que puedas mantenerlo para el resto de tu vida, ¿no crees?</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Como diría nuestro compañero Alberto: «La gente piensa que debe vivir a base de ensaladas, cuando realmente con añadir una ensalada a su día a día ya harían mucho bien que podría expandirse al resto de sus hábitos diarios.»</span></div> 
<br/><br/>
<div><img class="alignnone size-full wp-image-22592" src="https://www.programadoce.com/wp-content/uploads/2020/08/Básicos-DOCE.png" alt="" width="1080" height="820" /></div>
<br/><br/>
<div><span style="font-weight: 400;">Con la información que nos proporcionaste en los cuestionarios iniciales, hemos diseñado tu plan nutricional teniendo en cuenta lo que consideramos que serán tus requisitos calóricos y de macronutrientes diarios para conseguir tus objetivos en las próximas cuatro semanas.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Cada dos semanas te pediremos que hagas un "check-in" con nuestro equipo para revisar tu progreso y, cuando proceda, hacer ajustes sobre tu plan nutricional y proporcionarte nuevas herramientas que te permitan llevar un mejor control de lo que comes.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">También irás progresando en flexibilidad para que puedas integrar tu plan cada vez mejor en un día a día que pueda incluir eventos sociales, comidas de negocios y tomarte un capricho con amigos y/o familiares… Lo dicho, aunque en principio pueda parecer algo más restrictivo, no es más que un bonito período de aprendizaje que pagará dividendos para el resto de tu vida.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">No es una dieta, es una educación nutricional que irás pincelando tú con tus gustos, preferencias y objetivos poco a poco.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">DOCE no es un plan de pérdida de grasa o ganancia muscular a corto plazo. DOCE es un plan diseñado para cambiar tu estilo de vida para mejor, mejorar tus hábitos nutricionales y mejorar tu composición corporal.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Ya sé lo que estás pensando… </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">¿Quiere esto decir que no voy a perder grasa y/o ganar músculo en las siguientes 12 semanas? No, no quiere decir eso, de hecho esperamos que consigas grandes resultados en las siguientes 12 semanas.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">En estas 12 semanas esperamos que consigas las metas que te has planteado, pero de poco serviría si luego no eres capaz de mantener los resultados conseguidos (algo que suele pasar con muchas “dietas y programas”). </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Por eso queremos proporcionarte las herramientas necesarias para que puedas tener hábitos nutricionales saludables y sostenibles a largo plazo que te permitan mantener tus resultados y seguir progresando, sin que por ello dejes de “tener vida”. Creemos –y la evidencia también parece indicar– que es importante que a medio/largo plazo exista un equilibrio que te permita disfrutar tanto del gimnasio como de la vida fuera de él.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">No queremos que te encierres en una cueva durante los siguientes meses. Podrás ir a cenar con amigos y familiares, al cine con tus hijos, de barbacoa e incluso salir a tomar algo. </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Si no pudieses nunca hacer ninguna de esas cosas ¿Merecería la pena tener abdominales? Nosotros creemos que no.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Pero esto no quiere decir que tengas “barra libre” para comer o beber cualquier cosa. Y te estaríamos mintiendo si te dijéramos que va a ser fácil. Al principio tendrás que cambiar tus hábitos nutricionales, y es importante que sepas cómo hacerlo, sobre todo en las ocasiones que te acabamos de mencionar, ya que suele ser cuando tiramos por tierra todos nuestros esfuerzos.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Para eso tendrás el apoyo de todo nuestro equipo y de los demás miembros de DOCE, que en nuestro grupo privado de Facebook te ofrecerán ayuda, consejos, mecanismos y estrategias para sobrellevar los momentos más difíciles, y por supuesto ánimo y motivación. DOCE no es sólo un programa de entrenamiento y nutrición, DOCE es una comunidad.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Gracias por estar aquí y formar parte de ella. </span></div> <div><span style="font-weight: 400;"> </span></div> 
<br/><br/>
<div><h2><strong><span style="color: #98070a; font-size: 18pt;">3. Tu día a día en DOCE</span></h2></div>
<br/><br/>
<div><span style="font-weight: 400;">Tras leer en el punto anterior lo que queremos conseguir con nuestros hábitos a medio/largo plazo es normal que tengas la típica duda que todos hemos tenido en algún momento... </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">«¿Y cómo leches se hace eso?»</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Tranquilo, vamos a ver un par de puntos comunes para que te hagas una idea de cómo será el día a día de estas primeras cuatro semanas. Recuerda, empezamos con un período de aprendizaje que te dará habilidades para toda la vida, aunque puedas tener algo de experiencia y sea tentador, no te saltes esta parte. </span></div> 
<br/><br/>
<div><b>FRECUENCIA DE COMIDAS</b></div>
<br/><br/>
<div><span style="font-weight: 400;">Diseñaremos tu plan nutricional de forma que tengas entre tres y cuatro comidas principales con la posibilidad de añadir uno o dos snacks. De esta forma no dejaremos pasar nunca demasiado tiempo entre comidas, para intentar evitar que pases hambre y los antojos. De nuevo, esto es el principio, tenemos casos de personas que viven mejor con solo dos comidas y algún snack diario y otras con seis. No te preocupes, es parte de la experimentación y el aprendizaje, encontraremos tu frecuencia de comidas favorita a lo largo de nuestra aventura para que luego tú puedas seguir practicando. Quédate con ésto: </span></div> <b>la frecuencia de comidas “perfecta” es aquella que va con tu vida y te permite conseguir tus objetivos. </b><div><span style="font-weight: 400;">Nada más y nada menos.</span></div> 
<br/><br/>
<div><b>COMIDAS</b></div>
<br/><br/>
<div><span style="font-weight: 400;">Como verás en el </span></div> <i><div><span style="font-weight: 400;">Meal Planner</span></div> </i><div><span style="font-weight: 400;">, donde podrás establecer un plan semanal de comidas, hemos decidido no ponerle “nombre” a las comidas (desayuno, comida, merienda o cena) sino simplemente les hemos puesto números. Hacemos esto para erradicar el concepto de que para desayunar hay que tomar cereales, tostadas, croissants o cosas similares. Las opciones disponibles para tus comidas serán las mismas, ya sea desayuno, comida o cena. De nuevo, </span></div> <b>tú decides lo que te gusta comer,</b><div><span style="font-weight: 400;"> nosotros simplemente compartimos la educación necesaria de lo que nuestro cuerpo necesita para rendir y lograr los objetivos que tienes en mente. </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Verás que esto se irá haciendo más y más flexible conforme progreses en el programa y vayas cumpliendo etapas. También dependerá de tu objetivo, no necesitaremos el mismo nivel de compromiso y precisión para “simplemente estar bien” que para “competir en la próxima competición para portada de revista”. </span></div> 
<br/><br/>
<div><b>CANTIDADES</b></div>
<br/><br/>
<div><span style="font-weight: 400;">A no ser que especifiquemos lo contrario, queremos que peses tus comidas siempre en seco / crudo / antes de ser cocinadas. Para la verdura lo ideal es que tomes verduras frescas, pero sabemos que a veces no es tan cómodo o económico como las verduras congeladas, si usas estas últimas la cantidad será la misma que te indiquemos en el plan.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">No, no tendrás que estar “pesando comida” para siempre, es simplemente una parte breve del aprendizaje.</span></div> 
<br/><br/>
<div><b>SUPLEMENTACIÓN INTRA Y POST-ENTRENAMIENTO</b></div>
<br/><br/>
<div><span style="font-weight: 400;">Tema que se menciona mucho por todas partes pero que puede tener menos relevancia de la que crees. Recibirás un email con todo sobre suplementos durante tu aventura en DOCE, pero por ahora quédate con que tomar algo para que haga X cosa sin estar dándole al cuerpo esa cosa a través de tu comida, movimiento y/o estilo de vida, es igual que fregar el suelo teniendo goteras.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Si tienes acceso a BCAAs sería bueno que los bebieras durante el entrenamiento, no por nada “mágico”, simplemente porque están ricos y te obligarán a beber algo más de agua y porque así tenemos aminoácidos en sangre constantemente, dándole una razón menos al cuerpo para que use los que conforman nuestros músculos y otros tejidos. (¡Esto es bueno!)</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">También tomarás proteína en polvo después de entrenar, no necesitar ir corriendo a tomarlo pero lo hemos incorporado en el plan para que lo tomes en algún momento después de tu entreno por una razón sencilla: es una forma práctica de hacer que llegues a tu requerimiento diario de proteína. Mucha gente no come suficiente proteína para los objetivos que tienen y es uno de los principales obstáculos que nos encontramos inicialmente. </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Este pequeño hábito resuelve ese obstáculo de un plumazo.</span></div> 
<br/><br/>
<div><b>BEBIDAS</b></div>
<br/><br/>
<div><span style="font-weight: 400;">«Beberte la cena»</span></div> </i><div><span style="font-weight: 400;"> suena a algo improbable o incluso nada apetecible, ¿verdad?</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Echa un vistazo a la siguiente imagen para darte una idea de la importancia de ser conscientes de lo que bebemos a diario... </span></div> 
<br/><br/>
<div><img class="alignnone size-full wp-image-22593" src="https://www.programadoce.com/wp-content/uploads/2020/08/Café-Burger.jpg" alt="" width="1032" height="894" /></div>
<br/><br/>
<div><span style="font-weight: 400;">Ese inocente “latte” de Starbucks o tu cadena de cafés favorita puede estar dándole a tu cuerpo la misma energía que tu cena sin saciarte lo más mínimo.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Impresiona, ¿verdad?</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Ahora piensa la cantidad de bebidas que consumes día a día por “rutina” y cuánta energía están aportando a tu nutrición.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Sé crítico, ¿te están ayudando en tus objetivos?</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">La respuesta, a no ser que seas un atleta de élite con requerimientos calóricos parecidos a Michael Phelps, es un rotundo “no”.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Entonces, ¿qué puedes hacer para garantizar que bebes suficiente y que disfrutas de lo que bebes sin ponerte obstáculos en el camino?</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Vamos con algunos puntos esenciales para tener todo esto cubierto durante tu aventura en DOCE:</span></div> 
<br/><br/>
<div><ul>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">No existe un número mágico de litros de líquido que debas consumir, depende mucho del contexto de cada uno, donde vivas, el tiempo, tu actividad… Podríamos volvernos locos pero vamos a simplificarlo con dos cosas: bebe cuando tengas sed y sigue esta guía visual de tu orina para saber cuándo estás bien hidratado y cuando deberías beber un poco más.</span></div> <div><span style="font-weight: 400;">
</span></div> </li>
</ul></div>
<br/><br/>
<div><img class="alignnone size-full wp-image-22594" src="https://www.programadoce.com/wp-content/uploads/2020/08/Hidratación.png" alt="" width="1342" height="328" /></div>
<br/><br/>
<div><span style="font-weight: 400;">Si tu orina se mantiene en colores del 1 al 4 es probable que estés bien hidratado, si la ves del 4 al 6 puede que sea buena idea que bebas un poco más que lo que estás haciendo hasta ahora.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">¿Entre el 7 y 8? Puede que algún suplemento –como un multivitamínico– o ciertos alimentos tengan que ver en el color, pero es también posible que necesites beber más y/o pedir opinión médica si esos colores son habituales en tu orina.</span></div> 
<br/><br/>
<div><ul>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">Intenta que la mayoría de tu ingesta de líquidos sea agua. ¿Aburrido de su sabor? Prueba con agua con gas y bebidas sin calorías, por ejemplo. Aún así, intenta siempre tener un ratio de 2:1 de agua; bebe dos vasos de agua por cada vaso de bebida zero, sin calorías o similar.</span></div> <div><span style="font-weight: 400;">
</span></div> <div><span style="font-weight: 400;">Es una forma segura de asegurar suficiente hidratación sin abusar de edulcorantes, gases, etc.</span></div> </li>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">¿Te gusta el café? A nosotros en DOCE también, pero intentamos limitar el consumo a máximo 2 tazas diarias por la mañana. Si tomas un buen café es probable que no necesites endulzarlo, pero si eres de dulce, usa un edulcorante para ello; recuerda lo que vimos de las calorías en nuestras bebidas al principio de este email.</span></div> </li>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">Olvida los zumos, come fruta de verdad. Pedir ese “café y zumito” es un ritual muy típico en algunos países como España, Italia y otras zonas del mediterráneo pero no tienes porqué hacerlo ni aporta beneficios de salud adicionales que una naranja entera no pueda darte, por ejemplo. Vuelve a ese gráfico inicial y piensa, ¿cómo crees que estarás más saciado, con un zumo de tres naranjas o con una naranja entera disfrutada bocado a bocado? </span></div> </li>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">¿Fan del alcohol? Quizás deberías empezar a pensarlo dos veces. El alcohol no solo es un compuesto que nos puede “nublar el pensamiento” y hacer que tomemos decisiones un poco “estúpidas” –¡esos kebabs a las 5 de la mañana!–, también aporta 7 calorías por cada gramo y puede hacer que con dos copas se borre nuestro progreso diario de pérdida de grasa. Veremos formas de socializar con y sin alcohol en futuros emails, pero por ahora nos gustaría que pararas y pienses cuánto alcohol bebes, por qué lo haces y si sería posible cambiar ese “sistema automático”.</span></div> </li>
</ul></div>
<br/><br/>
<div><b>Este punto en pocas palabras:</b><div><span style="font-weight: 400;"> tus primeras cuatro semanas serán un aprendizaje para el resto de tu vida, tus preferencias son importantes y verás cómo ir metiéndolas en tu plan poco a poco, pesa tu comida en crudo/sin cocinar, tómate algo de proteína después de entrenar pero no te vuelvas loco por ello y manténte hidratado; es esencial y la forma en que lo hagamos puede hacernos la vida más fácil con nuestros objetivos o convertirlo en un infierno del que no sepamos cómo salir.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Elige la vía fácil, párate un momento y crea una estrategia para disfrutar de tus bebidas siendo consciente de lo que aportan, deja que la mayoría de las calorías vengan de la comida, ¡están mucho más ricas!</span></div> 
<br/><br/>
<br/><br/>
<div><h2><strong><span style="color: #98070a; font-size: 18pt;">4.Preparación de comidas </span></h2></div>
<br/><br/>
<div><span style="font-weight: 400;">¿Una sección entera dedicada a cómo preparar tus comidas? Por supuesto</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">No prepararse adecuadamente es el equivalente a prepararse para fallar.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">La experiencia nos dice que el error más común en aquellos que no consiguen sus objetivos es que no se preparan y planifican de antemano, tanto los entrenamientos como la nutrición.  </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Sí, la nutrición también. Para el gimnasio tienes un plan de entrenamiento marcado de antemano, del cual debes llevar un seguimiento detallado de tus sesiones para progresar semana a semana.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Para progresar siguiendo un plan nutricional debes planificar cada día (o incluso cada semana) con antelación y prepararte bien. Si no te prepararas, improvisarás. Y si improvisas, durante esta fase inicial de aprendizaje, acabarás fallando.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Lo hemos visto cientos de veces. </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Hemos creado esta mini guía para que esto no te ocurra a ti y en unas semanas seas un pro de la planificación de comidas y así luego puedas improvisar y “vivir” de forma más flexible sin problema.</span></div> 
<br/><br/>
<br/><br/>

<div><b>PLANIFICA TODA LA SEMANA DE ANTEMANO</b></div>
<br/><br/>
<div><span style="font-weight: 400;">La mejor forma de hacer esto es escribir en un papel todas tus comidas de la semana, ¿piensas comer algo el martes por la tarde? Escríbelo. ¿Es eso lo mismo que el miércoles por la tarde? Genial, escríbelo también; estamos en fase de aprendizaje, ¿recuerdas?</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Para esto, hemos creado un </span></div> <i><div><span style="font-weight: 400;">Meal Planner </span></div> </i><div><span style="font-weight: 400;">semanal que consiste en un calendario con toda la semana en el que podrás apuntar lo que vas a comer cada día. Imprime el </span></div> <i><div><span style="font-weight: 400;">Meal Planner </span></div> </i><div><span style="font-weight: 400;">semanal, escribe ahí todas tus comidas de la semana, prepáralas de antemano y una vez preparadas mételas en la nevera o el congelador.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Nuestro compañero </span></div> <a href="https://themacrowizard.com/soyalberto/"><div><span style="font-weight: 400;">Alberto</span></div> </a><div><span style="font-weight: 400;"> perdió 65 kilos hace unos años y tras aprender sobre nutrición, entrenamiento y otros pilares importantes en todo esto de cambiar nuestro cuerpo, decidió escribir un libro dedicado íntegramente a cocinar.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">¡Le dió tanta importancia que dedicó un par de años de su vida a sacarlo y lo tiene como herramienta principal para todo aquel que empieza en este camino!</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Al formar parte de la familia DOCE puedes acceder al libro aquí por una fracción de su precio, te aseguramos que te ayudará inmensamente en el camino de comprar, preparar y almacenar tus comidas… así como en la aventura de comer fuera después de hacer todo esto.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Tienes más información sobre el libro </span></div> <a href="https://themacrowizard.com/cocina/"><div><span style="font-weight: 400;">aquí</span></div> </a><div><span style="font-weight: 400;">, usa el código DOCE para desbloquear tu precio especial.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Escribe tu plan semanal de comidas en tu </span></div> <i><div><span style="font-weight: 400;">Meal Planner</span></div> </i><div><span style="font-weight: 400;"> antes de hacer la compra de esa semana, de forma que sepas exactamente qué comidas e ingredientes vas a necesitar en su preparación y así hacer la compra de eso y nada más.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Lo que nos lleva al siguiente punto.</span></div> 
<br/><br/>
<br/><br/>

<div><b>HAZ UNA LISTA DE LA COMPRA… ¡Y SÍGUELA SIN DESVIARTE!</b></div>
<br/><br/>
<div><span style="font-weight: 400;">Escribe en tu lista de la compra todos los ingredientes y comidas que necesitas para esa semana y en qué cantidades. </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Ve a un supermercado con el que ya estés familiarizado de forma que no tardes mucho en hacer la compra, no te entretengas en zonas del supermercado en la que no debas estar y sobre todo nunca vayas a hacer la compra con hambre, ¡intenta ir siempre con el estómago lleno!</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Hacer la compra con el estómago vacío es una receta para el fracaso, si tienes hambre comerás y comprarás con los ojos y el estómago en lugar de con la cabeza (y con tu lista). </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Si vas a hacer la compra con hambre te aseguro que comprarás cosas que no debas comprar y si en casa tienes tentaciones sucumbirás a ellas cuando tengas hambre o por puro aburrimiento. Nuestro entorno es muy poderoso.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">También tienes ejemplos de lista de la compra, nevera y despensa en el libro que comentamos de Alberto, </span></div> <a href="https://themacrowizard.com/cocina/"><div><span style="font-weight: 400;">“Un Mago en la Cocina”.</span></div> </a>
<br/><br/>
<div><span style="font-weight: 400;">Si decides hacerte con él, no olvides usar el código DOCE y escribirle con lo que te gusta y lo que no del libro, dile que te mandamos nosotros. ;) </span></div> 
<br/><br/>
<br/><br/>

<div><b>PREPARA TODAS TUS COMIDAS DE LA SEMANA EN UNA SÓLA VEZ  (O DOS</b><div><span style="font-weight: 400;">) </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Elige un día (o dos) a la semana en los que cocinarás todas tus comidas para los siguientes días.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">De esta forma te llevará varias horas en una o dos tandas y no tendrás que preocuparte más el resto de la semana. Esto no sólo te ahorrará tiempo a lo largo de la semana, sino que además evitará que surjan tentaciones a lo largo de la semana por pereza de cocinar.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">No me digas que no te suenan esas llamadas a la pizzería del barrio al llegar a casa después de trabajar reventado… ¡Todos hemos pasado por ello!</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Te recomendamos que compres tupperwares y bolsas de plástico en las que meter tus comidas en el congelador. Todas las noches saca del congelador las comidas del día siguiente y déjalas en la nevera para que se descongelen de forma que al día siguiente sólo tardes 5 minutos en preparar tu comida.</span></div> 
<br/><br/>
<br/><br/>

<div><b>Recuerda, hacerlo fácil es clave para que lo hagas de forma semi automática.</b></div>
<br/><br/>
<div><span style="font-weight: 400;">No dejes ninguna comida en la nevera más de 2-3 días ni vuelvas a congelar comida que ya hayas descongelado.</span></div> 

<br/><br/>
<br/><br/>

<div><b>COCINA EN GRANDES CANTIDADES</b></div>
<br/><br/>
<div><span style="font-weight: 400;">Esto resulta bastante obvio si vas a cocinar sólo 1-2 veces por semana ya que te ahorrará muchísimo tiempo. Por ejemplo, puedes cocinar todo el pollo y brócoli de la semana al mismo tiempo y luego dividirlo en porciones en función de lo que hayas escrito tu </span></div> <i><div><span style="font-weight: 400;">Meal Planner.</span></div> </i>
<br/><br/>
<br/><br/>

<div><b>MIDE BIEN TUS PORCIONES</b></div>
<br/><br/>
<div><span style="font-weight: 400;">Algunas comidas son muy altas en calorías y pueden crear con facilidad un superávit calórico si no medimos bien nuestras porciones. </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">¿Alguna vez alguien te ha dicho eso de «la comida sana no engorda»? Piénsalo.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Si ingieres más calorías de las que tu cuerpo consume en sus quehaceres diarios ganarás peso, por muy sano que comas, es así de simple.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Echa un ojo al siguiente gráfico para ver un ejemplo de lo poco que se necesita para añadir cientos de calorías sin darnos cuenta.</span></div> 
<br/><br/>
<div><img class="alignnone size-full wp-image-22595" src="https://www.programadoce.com/wp-content/uploads/2020/08/Porciones-Tostada.jpg" alt="" width="1358" height="970" /></div>
<br/><br/>
<div><span style="font-weight: 400;">Los frutos secos (y sus cremas) son muy fáciles de comer en exceso, sobre todo si medimos “a ojo” en lugar de pesarlos. Y aunque son muy nutritivos, también son muy altos en calorías y debemos tenerlo en cuenta. Volvemos al aprendizaje que tanto nos gusta mencionar, intenta aprender cómo se ve una ración que mencionamos en tu plan nutricional para que luego tengas esa habilidad en tu mente.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Te servirá tanto a la hora de cocinar como al salir fuera a comer.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Asegúrate de medir bien las porciones antes de meter la comida en los tupperwares de forma que luego no tengas que preocuparte a lo largo de la semana. En el caso de los frutos secos usa pequeños recipientes de plástico o pequeños tarritos de cristal en los que metas las porciones del día, si comes directamente de la bolsa lo más probable es que te excedas.</span></div> 
<br/><br/>
<i><div><span style="font-weight: 400;">Pro tip:</span></div> </i><div><span style="font-weight: 400;"> cuando sigues un plan nutricional de pérdida de grasa es conveniente que elijas tupperwares y platos pequeños para comer ya que de esa forma potencialmente engañaras a tu cerebro y creerá que está comiendo más al ver el plato “rebosante”. </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Planear y preparar tus comidas de antemano es la clave del éxito, así que deja de lado de las excusas, póntelo lo más fácil posible y ¡empieza hoy!</span></div> 
<br/><br/>
<div><h2><strong><span style="color: #98070a; font-size: 18pt;">5. Cómo la preparación de comidas te convierte en un “pro” comiendo fuera (“breve”, el artículo tocho sobre esto está en la semana 10 de las DOCE semanas, DOCE emails: “¿Cómo puedo flexibilizar mi dieta? Estrategias para comer fuera como un “pro”.”)</span></h2></div>
<div><span style="font-weight: 400;">¿Recuerdas cómo te hablamos de cómo cocinar y comer en casa te iba a convertir en un </span></div> <i><div><span style="font-weight: 400;">pro </span></div> </i><div><span style="font-weight: 400;">a la hora de salir a comer fuera?</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Este punto trata todo sobre ello, vamos al lío.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Comer fuera siempre puede ser un reto cuando estás empezando en esto de “intentar comer algo mejor” y perseguir tus objetivos. En casa simplemente tienes que seguir tu plan nutricional e ir paso a paso:</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">“Peso 150 gramos de pechuga de pollo, caliento la sartén, añado la pechuga, le pongo un poco de especias, añado las verduras y el resto de cosas para el plato… Boom, listo.”</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Lo bueno que tiene el hacer esto durante 4-6 semanas inicialmente es que podrás “memorizar” lo que son 150 gramos de pollo, sabrás que “un poquito de aceite” es realmente unos 30-40 gramos si no lo mides y verás que los postres, dulces y otras cosas densas en calorías pueden aportarte energía suficiente para toda una semana si no eres consciente de las porciones.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Aplicando este aprendizaje, cuando salgamos fuera podemos usar una combinación de estrategias para “hackear el sistema” y seguir encarrilados en el objetivo.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">¿Recuerdas eso de “las porciones”? Echa un ojo a este gráfico.</span></div> 
<br/><br/>
<div><img class="alignnone size-full wp-image-22596" src="https://www.programadoce.com/wp-content/uploads/2020/08/Porciones.jpg" alt="" width="1452" height="1322" /></div>
<br/><br/>
<div><span style="font-weight: 400;">Con esta guía visual puedes hacerte una idea de cómo puedes usar tus manos para estimar de forma aproximada cuánto vas a comer con lo que tienes delante en el plato.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">De nuevo, esto es un arte que debe ser perfeccionado poco a poco. Mientras lo haces, tus señales internas (saciedad, hambre, etc) también irán perfeccionándose. La idea es que llegue el día en el que puedas “comer de forma intuitiva” lo que necesitas y quieres, sabiendo escuchar a ese “tengo hambre” o “ya estoy saciado” que tan poco escuchamos en esta era moderna debido a una gran cantidad de factores.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">¿Algo más a tener en cuenta? Por supuesto, y va en línea con lo que decimos siempre de ponértelo fácil.</span></div> 
<br/><br/>
<div><ul>
  <li><b>Tu prioridad debe ser la proteína.</b><div><span style="font-weight: 400;"> Recuerda que tienes un objetivo diario y que, al ser el ingrediente más caro, los restaurantes suelen ir bastante cortos con ella. Busca el plato que te gusta, sin duda, pero si eliges una pizza de queso (básicamente todo grasa y carbohidratos) tendrás que empezar a pensar en dónde está la proteína ahí y en el resto de tu día. Irás aprendiendo todo esto con las semanas, pero para empezar, piensa en buscar platos con una fuente de proteína como actor principal antes de graduarte a lasañas, pizzas y otras cosas más elaboradas.</span></div> </li>
  <li><b>Al restaurante le importa lo más mínimo tus “macros” y objetivos,</b><div><span style="font-weight: 400;"> lo que quiere es que vuelvas y hables de la experiencia con los tuyos. Asume que habrá más aceite de lo que estimas en todo y sé consciente de que el tamaño de las porciones puede que no sea “lo que necesitas”. En otras palabras, si estás intentando perder grasa, sobreestima lo que calculas de tu comida y no tengas miedo a dejar comida en el plato; no eres una basura de residuos orgánicos.</span></div> </li>
  <li style="list-style-type: none;"></li>
  <li><b>Si algo viene con salsas, pídelas por separado y así podrás ajustar lo que te pones.</b></li>
  <li>Simplifica tus elecciones antes de meterte en “lo complejo”. <div><span style="font-weight: 400;">Intentar adivinar lo que lleva la tortilla de patata de tu sitio favorito durante la primera semana de tu plan puede no ser la mejor de las ideas para tu bienestar mental. Ve poco a poco, tienes que cocinar en casa para aprender cómo se preparan las cosas y así luego saber más o menos lo que tiene la comida que vas eligiendo cuando comes fuera. Es mucho más sencillo estimar un “Salmón con verduras al horno” que un “Ratatouille con gambas y gratín de patata acompañado de salsa de setas”. Paso a paso.</span></div> </li>
  <li>Puedes modificar tu entorno más inmediato para ponértelo más fácil. <div><span style="font-weight: 400;">Si vas sólo a comer fuera puedes decirle a tu camarero que no te traiga la cesta del pan si no estaba en tus planes comerlo, por ejemplo. Puedes pedir medio menú del día en lugar del menú completo del que no tienes pensado comerte el entrante, el postre ni beberte el vino. ¿Vas acompañado? Genial, regala a tus compañeros de mesa tus delicias que no tenías pensado comer, o comparte y ahorra calorías casi sin darte cuenta y sin renunciar a lo que quieres.</span></div> </li>
</ul></div>
<br/><br/>
<br/><br/>

<div><span style="font-weight: 400;">En la comunidad DOCE en Facebook tendrás cientos de aliados con experiencia en todo esto para darte apoyo. Recuerda que la idea no es dejar de comer fuera, estar “a dieta” o no beber nada de alcohol ni comer tiramisú el resto de tu vida… ¡La idea es hacer todas esas cosas mientras consigues tus objetivos a medio/largo plazo! </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Paciencia, aprendizaje y experimentación. Todos hemos pasado por ello, te lo aseguro.</span></div> 
<br/><br/>
<br/><br/>
<div><h2><strong><span style="color: #98070a; font-size: 18pt;">6.Guía básica de suplementación, y por qué los suplementos son el pico de la pirámide no su base.</span></h2></div>
<div><span style="font-weight: 400;">Una cosa que llama mucho  la atención es la importancia que la gente le suele dar a la suplementación, a veces incluso priorizando a la propia nutrición. Esto es un sinsentido.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Veamos un ejemplo gráfico:</span></div> 
<br/><br/>
<div><img class="alignnone size-full wp-image-22597" src="https://www.programadoce.com/wp-content/uploads/2020/08/Suplementos.jpg" alt="" width="1302" height="1066" /></div>
<br/><br/>
<div><span style="font-weight: 400;">La suplementación tiene su sitio en un plan nutricional, por supuesto, pero está diseñada para complementarnos en aquellas cosas en las que somos deficientes, o bien para incrementar nuestro rendimiento en el gimnasio o recuperación post-entrenamiento, una vez hayamos cubierto todos los pilares básicos de nutrición, entrenamiento, gestión del estrés, descanso, etc…</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Hasta aquí estamos todos de acuerdo.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Ahora bien, si tu alimentación no es la adecuada, si tu sueño no es de calidad ni lo suficientemente duradero y si no entrenas duro y con intención, no hay ningún suplemento (legal) en el mercado que te vaya a proporcionar los resultados que buscas.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Con esto en mente vamos a hablar de una serie de suplementos que sí pueden ser útiles para nuestro camino y tus objetivos, siempre que los básicos cubiertos en tu plan nutricional y de entrenamiento estén bien atados.</span></div> 
<br/><br/>
<br/><br/>

<div><b>Creatina</b></div>
<br/><br/>
<div><span style="font-weight: 400;">La creatina es uno de los compuestos más investigados en los últimos años. Echando un vistazo a toda esa evidencia, podemos ver que tomar 3-5 g diarios puede tener sentido para la mayoría.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Juega un papel importante en la producción de energía – especialmente en condiciones que requieran un esfuerzo mayor a ‘lo normal’, como cuando entrenamos fuerza, por ejemplo – y la encontramos en pescado y carne principalmente.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Suplementar con creatina puede ayudarte a rendir un poco mejor en los entrenamientos, haciendo que sea más sencillo de tolerar un mayor volumen de entrenamiento y/o recuperarte del mismo, lo que puede resultar en un mantenimiento/aumento de tu masa muscular.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">También vemos evidencia bastante prometedora en el campo de fatiga mental y protección contra el daño cerebral. No existen suficientes datos como para tomarla simplemente por esto, pero yo mantendría el ojo puesto en nueva evidencia en torno a la memoria, el cerebro y la creatina.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">En resumen: Toma 3-5g de creatina al día en forma de polvo mezclado en suficiente agua.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">A tener en cuenta: Monohidrato de creatina es todo lo que necesitas. Si ves creatina de unicornio del norte, creatina pura 2000 plus o cualquier otra cosa, ten por seguro que no es más que marketing. Ojo, se han visto casos de diarrea y otras pequeñas molestias gástricas por una dosis demasiado elevada o muy poco líquido al tomarla.</span></div> 
<br/><br/>
<br/><br/>

<div><b>Vitamina D + Vitamina K</b></div>
<br/><br/>
<div><span style="font-weight: 400;">Suficiente exposición al sol y abundantes verduras/frutas en tu dieta deberían resolver cualquier deficiencia de estas vitaminas, aún así, es interesante considerar el combo de D + K por los potenciales beneficios que vemos si miramos a la evidencia disponible.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Tras examinar lo que el peso de la evidencia dice actualmente, miro con especial interés a la Vitamina K2 y su sinergia con la D y los huesos.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Como siempre, dependerá de tu contexto, pero por regla general, si vives en una zona de poco sol, sales poco al exterior y/o comes pocas verduras/fruta, o simplemente te dan curiosidad esos potenciales beneficios de la sinergia entre estas vitaminas puede ser interesante.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">En resumen: Toma entre 1000-5000 iu de Vitamina D y unos 1500 mcg de Vitamina K2 al día para unos huesos fuertes. Al ser vitaminas que se absorben junto a la grasa, asegúrate de tomarlas con comidas o con algo que contenga un poco de grasa.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">A tener en cuenta: Como siempre, esto no sustituye a pasar tiempo al aire libre y exponernos al sol, al igual que tampoco te da una carta de ‘pasar de las verduras y la fruta’, es un complemento a todo eso. Si no sabes qué dosis tomar, consulta con tu médico y saca un panel de sangre antes de suplementar con nada, por mucho que ‘sepamos’ lo que dice la evidencia, tu caso es tu caso, no lo olvides.</span></div> 
<br/><br/>
<br/><br/>
<div><b>Omega 3 </b></div>
<br/><br/>
<div><span style="font-weight: 400;">Algo parecido a la exposición solar y la vitamina D nos pasa con el omega 3 y el consumo de pescado azul, no solemos hacerlo a menudo.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Si no sueles comer pescado con frecuencia y quieres asegurarte la dosis, empezar a mirar recetas con más ideas puede ser un paso. Otro puede ser el suplementar con algo de omega 3 (DHA) y (EPA).</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">¿Por qué? Tenemos suficiente evidencia para decir que el omega 3 afecta directamente al nivel de triglicéridos en sangre, vemos un efecto moderado de mejora en personas con hipertensión y hay datos para pensar que tiene efectos a nivel emocional.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">A pesar de toda esta información, todavía no vemos una relación directa en el consumo de omega 3, la reducción de triglicéridos y que esta sinergia evite casos de enfermedades cardiovasculares, por ejemplo.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">En resumen: Tomar entre 1 – 3 de omega 3 al día puede ser interesante, sobre todo si tu consumo de pescado es bajo por lo general.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">A tener en cuenta: Intenta que la combinación por cada gramo de aceite de pescado – como comúnmente lo encontrarás – sea de al menos 250 mg de EPA y DHA. Echa un ojo a la etiqueta. ¿Un gramo de aceite por cápsula? Suma a ver si el EPA y DHA dan 250mg o más.</span></div> 
<br/><br/>
<br/><br/>

<b>BCAAs en polvo</b>
<br/><br/>
<div><span style="font-weight: 400;">Los aminoácidos ramificados o BCAA son una forma deliciosa (si eliges el sabor correcto) de tener siempre ladrillos de construcción en tu sangre y no hacer que el cuerpo se los pida a tu cuerpo mientras entrenas.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">No son “necesarios” ni tienen unos beneficios bestiales. Si comes suficiente proteína en el día a día (que lo harás al seguir tu plan nutricional) los BCAAs no aportan beneficios adicionales.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Son interesantes a la hora de tomarlos mientras entrenas para poner más cartas a tu favor y menos a la mano de perder masa muscular. También vemos algunas personas que reportan algo de mejora en las agujetas, sobre todo inicialmente, aunque la evidencia no consigue encontrar esto con certeza y puede ser un poco de efecto placebo.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">En resumen: Si tienes aminoácidos a mano y/o disfrutas bebiéndolos por su sabor mientras entrenas toma unos 3-5 g mezclados con agua durante tu sesión.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">A tener en cuenta: El sabor es muy subjetivo, pero intenta elegir alguno con sabor a fruta o similar para que sea un disfrute el beber más líquido mientras entrenas.</span></div> 

<br/><br/>
<br/><br/>

<div><b>Proteína de suero en polvo</b></div>
<br/><br/>
<div><span style="font-weight: 400;">Técnicamente una comida más que un suplemento (es literalmente producto de la creación del queso) pero lo ponemos aquí para comentar un poco más el porqué puede ser una interesante y práctica forma de llegar a tu proteína diaria.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Se digiere más rápido que ningún otro tipo de proteína y nos ayuda a empezar rápidamente el proceso de síntesis proteica. De esta forma nuestros músculos empiezan su proceso de reparación y regeneración lo antes posible al acabar nuestro entrenamiento.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">De nuevo, no tienes que correr tras entrenar para tomártelo, tu cuerpo podrá usar la proteína durante las horas posteriores al entrenamiento sin problema, simplemente ponte la vida mucho más fácil tomando entre 30-50 gramos después de entrenar.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">En resumen: Toma 30 gramos de proteína en polvo tras tu entrenamiento si eres mujer  y 40-50 gramos si eres hombre. No es un “suplemento” ni es “malo”, es literalmente comida que ha sido preparada para hacernos la vida más fácil a la hora de consumirla.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">A tener en cuenta: Verás cientos de tipos de proteína de suero. Por lo general intenta ceñirte a marcas que tengan cierta reputación y que no sean excesivamente baratas, te garantizarás que recibes lo que compras. Olvídate de las diferencias entre aislado, concentrado y todo eso, compra un concentrado (el normal) de toda la vida, que tenga un sabor bueno para ti y a volar.</span></div> 
<br/><br/>
<br/><br/>

<div><b>Glutamina</b></div>
<br/><br/>
<div><span style="font-weight: 400;">La glutamina es un aminoácido interesante. Durante años se le atribuyó un poder de ganancia muscular especial que luego se ha visto solo reflejado en personas con complicaciones médicas específicas y no en gente sana, como tú.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">¿Por qué lo ponemos aquí entonces? Porque lo que sí que vemos en la evidencia son unas curiosas propiedades digestivas. En ciertas personas parece tener un efecto positivo en digestiones.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Suele estar presente en cantidades suficientes en la proteína de suero, caseína y similares, pero si ves que tus digestiones podrían ser mejor, consultar con tu médico y/o especialista y darle una oportunidad a la glutamina no es mala idea. Seguimos investigando sobre su potencial ayuda al intestino permeable y otros problemas intestinales, como siempre, consulta con tu médico.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Si crees que te ayudará, 10 g mezclados con agua en ayunas al despertar suele ser la dosis adecuada.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">En resumen: Interesante aminoácido que consigue un puesto en la lista por el potencial uso gastrointestinal. Consulta con tu especialista para descartar otros problemas que creas que se pueden resolver con la glutamina antes de nada.</span></div> 
<br/><br/>
<br/><br/>
<div><h2><strong><span style="color: #98070a; font-size: 18pt;">7. Cómo leer las tablas de entrenamiento </span></h2></div>
<div><span style="font-weight: 400;">Vamos con el último de estos 7 emails para ponerte en la casilla de salida de tu aventura en DOCE. En este veremos todo sobre cómo leer tus tablas de entrenamiento para que puedas ejecutar y llevar un seguimiento de tus ejercicios como un auténtico </span></div> <i><div><span style="font-weight: 400;">pro. </span></div> </i>
<br/><br/>
<br/><br/>

<div><span style="font-weight: 400;">El orden de ejecución de los ejercicios lo determina su letra. </span></div> 
<br/><br/>
<div><ul>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">Primero hacemos todos los ejercicios “A” con todas sus series y repeticiones. </span></div> </li>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">Después todos los “B” con todas sus series y repeticiones. </span></div> </li>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">Después los “C”...</span></div> </li>
</ul></div>
<br/><br/>
<div><span style="font-weight: 400;">Fácil, ¿verdad?</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Vamos con algunas variaciones algo más complejas, nada serio, lo prometemos.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Si un ejercicio “A” tiene 3 series, harás las tres series del “A” antes de pasar al “B”.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Cuando veas que en una letra hay varios ejercicios quiere decir que o bien es una super-serie, o bien una tri-serie o un circuito.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Por ejemplo:</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">A1 y A2 es una superserie, alterno entre A1 y A2 con los descansos estipulados antes de pasar a B1.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">A1, A2 y A3 es una tri-serie. Hago A1, luego A2, luego A3, y vuelvo a A1 hasta terminar todas las series marcadas con los descansos estipulados antes de pasar al bloque “B”.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Lo mismo ocurriría cuando son más ejercicios o con cualquier otra letra que tenga varios números.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">En resumen: Cuando veas una letra, completa sus ejercicios, series y repeticiones antes de pasar a la siguiente.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">¿Fácil? Seguimos con otra cosa que verás en tu plan de entrenamiento, el </span></div> <i><div><span style="font-weight: 400;">tempo</span></div> </i><div><span style="font-weight: 400;">.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">El </span></div> <i><div><span style="font-weight: 400;">tempo</span></div> </i><div><span style="font-weight: 400;"> es la velocidad de ejecución de cada repetición medida en segundos en 4 fases. </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">La fase negativa es el primer número, la elongación muscular el segundo, la fase positiva el tercer número y la contracción el cuarto.</span></div> 
<br/><br/>
<br/><br/>

<div><span style="font-weight: 400;">Vamos con un ejemplo práctico:</span></div> 
<br/><br/>
<div><ul>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">Press de banca en 4010 es: bajamos en 4 segundos, no paramos abajo (0), subimos en 1 segundo, no paramos arriba (0).</span></div> </li>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">Press de banca en 3110 es: bajamos en 3 segundos, paramos un segundo abajo, subimos en un segundo, no paramos arriba.</span></div> </li>
</ul></div>
<br/><br/>
<div><span style="font-weight: 400;">Cuando el tercer número es una “X” quiere decir que subas el peso de forma explosiva (¡rápido!). </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Por ejemplo: Press de banca en 31X0 es: bajamos el peso en 3 segundos, paramos 1 segundo abajo, subimos el peso explosivamente, no paramos arriba.</span></div> 
<br/><br/>
<br/><br/>

<div><span style="font-weight: 400;">¿Quieres un ejemplo con otro ejercicio para comparar? </span></div> <div><span style="font-weight: 400;">
</span></div> <div><span style="font-weight: 400;">
</span></div> <div><span style="font-weight: 400;">Vamos a ello:</span></div> 
<br/><br/>
<div><ul>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">Jalón al pecho agarre prono en 40X0 es: subimos los brazos en 4 segundos, no paramos arriba, bajamos la barra al pecho de forma explosiva, no paramos abajo.</span></div> </li>
</ul></div>
<br/><br/>
<div><span style="font-weight: 400;">Como puedes ver, dividimos la ejecución del ejercicio en cuatro fases, así podemos determinar de forma sencilla dónde queremos hacer énfasis dependiendo del estímulo que estemos buscando. Tranquilo, todo esto te quedará claro en cuanto empieces a entrenar y le pilles ritmo, es exactamente igual que el tema de la nutrición; un aprendizaje inicial.</span></div> 
<br/><br/>
<div><div><span style="font-weight: 400;">En el apartado de peso sugerido verás la meta de la primera semana. Es importantísimo que te ajustes lo máximo posible a los pesos, series, repeticiones y descansos que verás en tu plan de entrenamiento. </span></div> 
<br/><br/>
<div><span style="font-weight: 400;">En esa meta de la primera semana verás que te pongo un número y detrás “RM”, por ejemplo: “10RM”, en ese caso quiero que uses el máximo peso con el que podrías hacer correctamente 10 repeticiones (siguiendo el tempo y con la ejecución adecuada).</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">¿Sigues aquí? ¡Genial! Vamos con la última parte de esta guía para interpretar tus tablas de entrenamiento.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">Cuando veas en la tabla una cosa llamada “RPE”, estamos hablando del nivel de dificultad del 1 al 10 en base a la siguiente métrica:</span></div> 
<br/><br/>
<div><ul>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">10 = fallo muscular</span></div> </li>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">9 = 1 rep más hubiese sido el fallo muscular</span></div> </li>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">8 = 2-3 reps más hubiese sido el fallo muscular</span></div> </li>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">7= 4-5 reps más hubiese sido el fallo muscular</span></div> </li>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">6 = podrías haber hecho más de 5 reps más</span></div> </li>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">5 o menos = es un calentamiento</span></div> </li>
</ul></div>
<br/><br/>
<div><span style="font-weight: 400;">Habrá veces en las progresiones semanales en las que con un peso determinado puedas hacer más (o menos) repeticiones que las que te marcamos en el programa, en ese caso:</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">&gt; Si te resulta muy fácil sube ligeramente el peso, pero recuerda que son varias series, así que a veces es mejor ser conservador, no subir el peso y ajustar en consecuencia la semana siguiente.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">&gt; Si no eres capaz de hacer las repeticiones marcadas:</span></div> 
<br/><br/>
<div><ul>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">Si es por 1-2 repeticiones en rangos de 1 a 10 repeticiones no pasa nada. Si son 3 o más, reduce ligeramente el peso.</span></div> </li>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">Si es por 2-3 repeticiones en rangos de 12 a 15 repeticiones no pasa nada. Si son más de 3, reduce ligeramente el peso.</span></div> </li>
  <li style="font-weight: 400;"><div><span style="font-weight: 400;">Si es por 3-4 repeticiones en rangos de 15 o más repeticiones no pasa nada. Si son 5 o más, reduce el peso.</span></div> </li>
</ul></div>
<br/><br/>
<div><span style="font-weight: 400;">Si en algún caso hubiera un ejercicio que no pudieses hacer porque el gimnasio carece del material necesario, coméntalo en el grupo privado de Facebook y mis entrenadores y yo buscaremos una alternativa para ti.</span></div> 
<br/><br/>
<div><span style="font-weight: 400;">¡A POR ELLO!</span></div> 
<br/><br/>
<br/><br/>
`
