import React from 'react'
import { KeyboardArrowDownRounded } from '@material-ui/icons'
import spain from '../../../Images/InitialForm/countries/spain.svg'
import { useState } from 'react'
import arrowBack from '../../../Images/InitialForm/countries/arrowBack.svg'
import NextButton from '../NextButton'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { updateClient, setClientSelected } from '../../../redux/actions/clientsActions'
import { getPathUpdate, getPathClientUpdate, blockForm } from '../../../utils/functions'
const PhoneNumber = ({ field, initialValue, setAllowScrolling, moveSectionDown, actions, clientSelected }) => {

  const [showCountries, setShowCountries] = useState(false)
  const [value, setValue] = useState(initialValue)


  const handleChangeValue = (value) => {
    setValue(value)
    setAllowScrolling(true)
  }

  const handleClickNext = () => {
    if(!field?.ref) return false
    if(value && initialValue === value){
      return moveSectionDown()
    }
    const payload = {
      id: clientSelected.idClient,
      data: { 
        body: { 
          [field.ref === "initialForm.questions" ? `${field.ref}.${field.id}` : field.ref]: +value,
        } 
      }
    }
    blockForm()
    actions.updateClient(payload)
    .then( client => {
      const path = getPathUpdate(field.id, field.ref)
      const value = getPathClientUpdate(client, field.id, field.ref)
      actions.setClientSelected({path , value})
      moveSectionDown()
    })
    .catch(error => {
      console.log(error);
    })
  }

  return(
    <>
      <div className="component-tel">
        <div className="container-tel-initial-form" onClick={()=>setShowCountries(true)}>
          <div>
            <img src={spain} className="country-img" alt="spain"/>
          </div>
          <div className="arrow-container">
            <KeyboardArrowDownRounded/>
          </div>
        </div>
        <input type="tel" autocomplete="tel-national" color="#F1ECE2" placeholder="612 34 56 78" value={value} onChange={ e => handleChangeValue(e.target.value)}/>
      </div>

      <NextButton onClick={handleClickNext} visibility={Boolean(value.trim())} buttonText={field?.properties?.button_text} hideMarks={field?.properties?.hide_marks}/>

      {showCountries && 
        <div className="pop-up-list-countries">
          <div className="header-list">
            <div className="arrow-back" onClick={()=>setShowCountries(false)}>
              <img src={arrowBack}  alt="arrow back"/>
            </div>
            <div className="search-container-country">
              <input className="input-search-tel" defaultValue="" placeholder={"Busca países"} />
            </div>
          </div>
        </div>
      }
      
    </>
  )
}

const mapStateToProps = ({clients}) => ({
  clientSelected: clients.clientSelected
});
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      updateClient,
      setClientSelected
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(PhoneNumber);