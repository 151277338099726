import React, { useState } from 'react'
import NextButton from '../NextButton'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { updateClient, setClientSelected } from '../../../redux/actions/clientsActions'
import { updatePhase } from '../../../redux/actions/phasesActions' 
import { getPathUpdate, getPathClientUpdate, blockForm } from '../../../utils/functions'
const TextArea = ({ field, type, initialValue, setAllowScrolling, moveSectionDown, actions, clientSelected}) => {

  const [value, setValue] = useState(initialValue)

  const handleClickNext = () => {
    if(field.update === "phase") return handlePhase()
    if(!field?.ref) return false
    if(type === "input" && value.trim() && initialValue === value){
      return moveSectionDown()
    }
    const payload = {
      id: clientSelected.idClient,
      data: { 
        body: { 
          [field.ref === "initialForm.questions" ? `${field.ref}.${field.id}` : field.ref]: value,
        } 
      }
    }
    blockForm()
    actions.updateClient(payload)
    .then( client => {
      const path = getPathUpdate(field.id, field.ref)
      const value = getPathClientUpdate(client, field.id, field.ref)
      actions.setClientSelected({path , value})
      moveSectionDown()
    })
    .catch(error => {
      console.log(error);
    })
  }

  const handlePhase = () => {
    let multiPath = []
    multiPath[`${clientSelected.currentPhase.step}.${field.ref}`] = value
    const payload = {
      id: clientSelected.currentPhase.idPhase,
      data: { 
        body: { 
          ...multiPath
        } 
      }
    }
    blockForm()
    actions.updatePhase(payload)
    .then( client => {
      const path = getPathUpdate(field.id, field.ref)
      const value = getPathClientUpdate(client, field.id, field.ref)
      actions.setClientSelected({path , value})
      moveSectionDown()
    })
    .catch(error => {
      console.log(error);
    })
  }

  const handleChangeValue = (value) => {
    setValue(value)
    setAllowScrolling(true)
  }


  return(
    <>
      <TextareaAutosize   
        className="textarea-initial-form" 
        value={value} 
        rowsMin={2} 
        placeholder="Escribe aqui tu respuesta"  
        maxLength={field.max_characters}
        onChange={ e => handleChangeValue(e.target.value)}

        />
        <br/>

      { field.type === "long_text" && <div align="right" >{`( ${value.length} / ${field.max_characters} )`}</div>}
      {/* <textarea className="textarea-initial-form" type={type} value={value} placeholder="Escribe aqui tu respuesta" onChange={ e => handleChangeValue(e.target.value)} /> */}
      <NextButton onClick={handleClickNext} visibility={Boolean(value.trim())} buttonText={field?.properties?.button_text} hideMarks={field?.properties?.hide_marks}/>
    </>
  )
}

const mapStateToProps = ({clients}) => ({
  clientSelected: clients.clientSelected
});
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      updateClient,
      setClientSelected,
      updatePhase
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(TextArea);