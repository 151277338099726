import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { updateClient, setClientSelected } from '../../redux/actions/clientsActions'
import { createAnalytics } from '../../redux/actions/analyticsActions'
import NextButton from '../InitialForm/NextButton'
import { ClearRounded,/*  KeyboardArrowRightRounded, ChevronLeftRounded  */} from '@material-ui/icons'

import moment from 'moment'
const TodayWeightPanel = ({ onClose, active = false, clientSelected, actions}) => {

  const [showOptions, setShowOptions] = useState(false)
  const [value, setValue] = useState("")

  useEffect(() => {
    if(active){
      document.getElementById("root").style.overflow = "hidden";
      setTimeout(()=>setShowOptions(true), 200)
    }else{
      document.getElementById("root").style.overflow = "auto";
    }
  }, [active]);

  const onCloseOptions = () => {
    setShowOptions(false)
    setTimeout(()=>onClose(), 200)

  }

  const handleChangeValue = (value) => {
    setValue(value)
  }

  const saveData = () => {
    //if(!isNaN(value)) return true
    
    const todayWeight = {
      formatedDate : moment(new Date()).format('DD/MM/YYYY'),
      date: new Date(),
      weight: +value
    }

    let multiPath = []
    multiPath["extraData.todayWeight.formatedDate"] = todayWeight.formatedDate
    multiPath["extraData.todayWeight.date"] = "date:now"
    multiPath["extraData.todayWeight.weight"] = todayWeight.weight

    const payloadClient = {
      id: clientSelected.idClient,
      data: { 
        body: { 
          ...multiPath
        } 
      }
    }

    let payloadAnalytics = {
      data: {
        body:{
          idClient: clientSelected.idClient,
          type: 'weight',
          data: todayWeight.weight
        }
      }
    }

    actions.createAnalytics(payloadAnalytics)
    .then( analytic => {
      console.log('Se ha registrado el peso en analytics')
      actions.updateClient(payloadClient)
      .then( client => {
        actions.setClientSelected({path: "extraData.todayWeight" , value: todayWeight})
        onCloseOptions()
      })
      .catch( error => console.log(error))
    })
    .catch(error => {
      console.log(error);
    })



  }

  return( active && 
    <div className="block-panel today-weight-panel " onClick={onCloseOptions}>
      
      <div className={`content-chat btns-download-pdf ${showOptions?'active-view':''}`} onClick={e=>e.stopPropagation()}>

        <div className="close-chat" onClick={onCloseOptions}><ClearRounded /></div>


        <div className={`page-form`} >

            <div className="content-section">
              <div>
                {/* Pregunta */}
                <div className="question-container">
                  <div className="question-text">¿Cuál es tu peso en kg?</div>
                </div>


                {/* Tipo de respuesta */}
                <div className="answer-container">
                  <input type="number" pattern={"[0-9],*"} inputMode="decimal" value={value} placeholder="Escribe aqui tu respuesta" onChange={ e => handleChangeValue(e.target.value)} />
                  <NextButton onClick={saveData} visibility={Boolean(value)} buttonText={"Aceptar"} hideMarks={false}/>
                </div>

              </div>
            </div>

        </div>




      </div>
  
    </div>
  )
}

const mapStateToProps = ({clients}) => ({
  clientSelected: clients.clientSelected
});
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      updateClient,
      createAnalytics,
      setClientSelected
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(TodayWeightPanel);