import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { useState } from "react";
import HeaderEntremamiento from "./HeaderEntremamiento";
import FooterEntrenamiento from "./FooterEntrenamiento";
/* import VideoEntrenamiento from "./VideoEntrenamiento"; */
import {
/*   ClearRounded,
  KeyboardArrowRightRounded, */
  ChevronLeftRounded,
} from "@material-ui/icons";
const { trainings } = require("doce-core").constants;

const Training = ({ clientSelected }) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const training =
    clientSelected?.phases?.[clientSelected?.currentPhase?.idPhase]?.training;

  return !clientSelected?.phases?.[clientSelected?.currentPhase?.idPhase] ? (
    <Redirect to="/" />
  ) : redirectTo ? (
    <Redirect to={redirectTo} />
  ) : (
    <div className="page-html-content">
      <div className="back-button" onClick={() => setRedirectTo("/")}>
        <ChevronLeftRounded />
      </div>

      <HeaderEntremamiento title={""} />
      {trainings?.[training?.trainingType]?.[
        training?.trainingSubType
      ].trainings.map((training, index) => {
        return (
          <div className="entrenamiento-page" key={index}>
            <div>
              <h2>{training.title}</h2>
              <div>
                {training.videos.map((video, index) => {
                  return (
                    <div key={index}>
                      <h4>{video.title}</h4>
                      <div>
                        <iframe
                          title="video"
                          className="iframe-video"
                          controls
                          src={video.url}
                        />
                      </div>
                      <br />
                      <br />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
      <FooterEntrenamiento />
    </div>
  );
};

const mapStateToProps = ({ clients }) => ({
  clientSelectedId: clients.clientSelectedId,
  clientSelected: clients.clientSelected,
});
export default connect(mapStateToProps)(Training);
