import React, { useState } from 'react'
import styled from 'styled-components'
import { useLocation } from "react-router";
import Iframe from 'react-iframe'
import { Redirect } from 'react-router-dom'
import {/*  ClearRounded, KeyboardArrowRightRounded,  */ChevronLeftRounded } from '@material-ui/icons'

const ViewPdf = () => {
  const [redirectTo, setRedirectTo] = useState(null)
  const { search } = useLocation();
  const url = new URLSearchParams(search).get('url')
  return(
    redirectTo ? <Redirect to={redirectTo} /> : 
    <Container>
      <ButtonBack onClick={() => setRedirectTo('/')}><ChevronLeftRounded /></ButtonBack>
      <Iframe 
        width="100%"
        height="100%"
        url={`https://docs.google.com/viewerng/viewer?url=${url}&embedded=true`}
      />
    </Container>

  )
}

export default ViewPdf

const Container = styled.div`
  width: 100%;
  height: 100vh;
`

const ButtonBack = styled.div`
  display: flex;
  background: #d9d9d9;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 15px;
  left: 15px; 
  border-radius: 50px;
  width: 30px;
  height: 30px;
  z-index: 100;
  svg{
    font-size: 24px;
    color: #262626;
  }
`