const ethosClient = require("doce-client").createClient();

export const updatePhase = ({ id, data }) => async (dispatch) => {
  try {
    const { body } = await ethosClient.updatePhase({ id, data });
    return Promise.resolve(body);
  } catch (error) {
    console.log(error);
  }
};
