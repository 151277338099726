import { historyTypes } from '../actions/historyActions'
var initialState = {
	forceGetHistories: Date.now(),
	histories: [],
	historySelected: null,
}

export default function (state = initialState, action) {
	switch (action.type) {
		case historyTypes.FORCE_GET_HISTORIES: {
			return { ...state, forceGetHistories: Date.now() }
		}
		case historyTypes.SET_HISTORIES: {
			return { ...state, histories: action.payload }
		}
		case historyTypes.SET_HISTORY_SELECTED: {
			return { ...state, historySelected: action.payload }
		}
		default: {
			return state
		}
	}
}
