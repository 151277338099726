import React from 'react'
import doneBtnAccept from '../../Images/InitialForm/doneBtnAccept.svg'
import Button from '@material-ui/core/Button';
const NextButton = ({ visibility = false, buttonText = "Aceptar", hideMarks = false, onClick }) => {
  return (
    <div className={`btn-container-accept ${visibility ? 'visible-button' : ''}`}>
      <Button className="btn-material-form" variant="contained">
        <div className="btn-accept" onClick={onClick}>
          <div>{buttonText}</div>
          {!hideMarks && <div className="container-done-accept"><img src={doneBtnAccept} alt="accept button"/></div>}
        </div>
      </Button>
    </div>

  )
}

export default NextButton