let identidicator = 0
const getIdentidicator = () => identidicator += 1
module.exports = {
  "fields": [
    { "id": "1",
      "ref": `questions.averageCurrentWeight`,
      "title": "Tu peso actual",
      "validations": {"required": true},
      "type": "number",
      "identidicator": getIdentidicator(),
      "update": "phase"
    },
    {
      "id": "2",
      "ref": `questions.averageWaistCurrent`,
      "title": "Tu cintura actual",
      "validations": {"required": true},
      "type": "number",
      "identidicator": getIdentidicator(),
      "update": "phase"
    },
    {
      "id": "3",
      "ref": `questions.neat`,
      "title": "NEAT / Pasos diarios: ¿Cuál ha sido tu media?",
      "validations": {"required": true},
      "type": "number",
      "identidicator": getIdentidicator(),
      "update": "phase"
    },
    {
      "id": "4",
      "ref": `questions.protein`,
      "title": "Proteinas: ¿Cuál ha sido tu media diaria en gramos?",
      "validations": {"required": true},
      "type": "number",
      "identidicator": 'b.',
      "update": "phase"
    },
    {
      "id": "5",
      "ref": `questions.carbohydrates`,
      "title": "Carbohidratos: ¿Cuál ha sido tu media diaria en gramos?",
      "validations": {"required": true},
      "type": "number",
      "identidicator": 'c.',
      "update": "phase"
    },
    {
      "id": "6",
      "ref": 'questions.fats',
      "title": "Grasas: ¿Cuál ha sido tu media diaria en gramos?",
      "validations": {"required": true},
      "type": "number",
      "identidicator": 'd.',
      "update": "phase"
    },
    {
      "id": "1",
      "ref": 'questions.macrosQuality',
      "title": "¿Cómo de fiables son los macros que has introducido?",
      "properties": {
        "description": "Elige tan sólo una opción:",
        "randomize": false,
        "allow_multiple_selection": false,
        "allow_other_choice": false,
        "vertical_alignment": true,
        "choices": [
          {
            "id": "vb8HlUkfJFRZ",
            "label": "Todo registrado y pesado"
          },
          {
            "id": "pmkqNaH8HKnr",
            "label": "Los fines de semana no registro"
          },
          {
            "id": "wXzfdZfr43cr",
            "label": "Registro todo pero peso el 80% de mis comidas"
          },
          {
            "id": "LFNZY7xszrlD",
            "label": "Registro todo pero peso el 60% de mis comidas o menos"
          },
          {
            "id": "LFNZY7xszrlz",
            "label": "No registro todo"
          }
        ]
      },
      "validations": {
        "required": true
      },
      "type": "multiple_choice",
      "identidicator": 'f.',
      "update": "phase"
    },
    {
      "id": "6",
      "ref": `questions.exerciseWaistPainful`,
      "title": "¿Ha habido algún ejercicio que te haya dado dolor articular?",
      "validations": {"required": true},
      "type": "long_text",
      "max_characters": 300,
      "identidicator": getIdentidicator(),
      "update": "phase"
    },
    {
      "id": "7",
      "ref": `questions.personalBest`,
      "title": "¿Has conseguido algún PB (Personal Best) este mes?",
      "validations": {"required": true},
      "properties": {
        "description": "Un PB es un récord de repeticiones para un peso determinado, o de peso para un rango de repeticiones determinado."
      },
      "type": "input",
      "identidicator": getIdentidicator(),
      "update": "phase"
    },
    {
      "id": "8",
      "ref": `questions.levelAdherence`,
      "title": "¿Cuál ha sido tu nivel de adherencia al plan nutricional?",
      "validations": {"required": true},
      "properties": {
        "steps": 5,
        "start_at_one": true,
        "labels": {
          "left": "Poca",
          "center": "Media",
          "right": "Mucha"
        }
      },
      "type": "opinion_scale",
      "identidicator": getIdentidicator(),
      "update": "phase"
    },
    {
      "id": "1",
      "ref": `questions.whyThatNote`,
      "title": "¿Por qué te pondrías esa nota?",
      "validations": {"required": true},
      "type": "long_text",
      "max_characters": 300,
      "identidicator": getIdentidicator(),
      "update": "phase"
    },
    {
      "id": "9",
      "ref": `questions.improvements`,
      "title": "¿Qué crees que podrías hacer para mejorar tu adherencia?",
      "validations": {"required": true},
      "type": "long_text",
      "max_characters": 300,
      "identidicator": getIdentidicator(),
      "update": "phase"
    },
    {
      "id": "10",
      "ref": `questions.tweet`,
      "title": "Si tuvieras que resumir esta fase en un ‘tweet’, ¿cómo sería?",
      "validations": {"required": true},
      "type": "long_text",
      "max_characters": 280,
      "identidicator": getIdentidicator(),
      "update": "phase"
    },
    {
      "id": "11",
      "ref": `questions.miniVictory`,
      "title": "¿Cuál crees que es tu minivictoria de estas semanas?",
      "validations": {"required": true},
      "properties": {
        "description": "Usa este momento y espacio para reflexionar, una mini victoria puede ser desde haber conseguido hacer X movimiento, hasta estar comiendo más verduras de forma frecuente, bebiendo menos alcohol, etc ¡El éxito lo defines tú!"
      },
      "type": "long_text",
      "max_characters": 300,
      "identidicator": getIdentidicator(),
      "update": "phase"
    },
    {
      "id": "12",
      "ref": `questions.notes`,
      "title": "Añade en esta sección cualquier información relevante o que quieras aportar que no estuviese en las preguntas anteriores.",
      "validations": {"required": true, "phases": [3,6,9]},
      "type": "long_text",
      "max_characters": 300,
      "identidicator": getIdentidicator(),
      "update": "phase"
    },
    {
      "id": "A0r8W8s2ZHpd",
      "ref": `images`,
      "title": "¡Gracias por todas las medidas!<br/><br/>Vamos con las fotos. <br/><br/>Por favor, <b>adjunta aquí tu foto de frente.</b>",
      "validations": { "required": true },
      "type": "file_upload",
      "identidicator": getIdentidicator(),
      "update": "phase"
    },
    {
      "id": "fQJBGRlsZnvl",
      "title": "Ahora <b>adjunta tu foto de espaldas.</b>",
      "ref": `images`,
      "validations": { "required": true },
      "type": "file_upload",
      "identidicator": getIdentidicator(),
      "update": "phase"
    },
    {
      "id": "DClriwj3KyQt",
      "title": "¡También necesitamos tu perfil! <b>Sube aquí tu perfil izquierdo.</b>",
      "ref": `images`,
      "validations": { "required": true },
      "type": "file_upload",
      "identidicator": getIdentidicator(),
      "update": "phase"
    },
    {
      "id": "j38ijKnLsNSx",
      "title": "Y por último, <b>sube aquí tu perfil derecho.</b>",
      "ref": `images`,
      "validations": { "required": true },
      "type": "file_upload",
      "identidicator": getIdentidicator(),
      "update": "phase"
    },
  ],
  "send_info": {
    //"ref": "default_tys",
    "title": "Ya casi está, solo tienes que guardar los datos",
    "properties": {
      "hide_marks": true,
      "button_text": "Guardar",
    },
    "type": "statement",
    "update": "phase",
    "action": 'status',


  },
  "confirm": {
    "title": "Hemos recibido correctamente tu información. ¡Gracias!",
    "properties": {
      "hide_marks": true,
      "button_text": "Aceptar",
      "redirect": '/',
    },
    "type": "statement"
  }
}