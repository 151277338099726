// import { applyMiddleware, createStore } from 'redux';
// import promise from 'redux-promise-middleware';
// import thunk from 'redux-thunk';
// import rootReducer from './rootReducer';

// let middleware = applyMiddleware(promise, thunk);

// const store = createStore(rootReducer, middleware);

// export default store

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
 
const initialState = {};
 
const middleware = [thunk];
 
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
 
export default store;