import React from 'react'
import queryString from 'query-string'

import { useState, useLayoutEffect } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { resetNewPassword } from '../../redux/actions/clientsActions'


import { setLoading, setNotification } from '../../redux/actions/globalActions'
import { Link, Redirect } from 'react-router-dom'

import logo from '../../Images/logo.png'
import backgroundSignIn from '../../Images/backgroundSignIn.png'
import AlertDialog from '../AlertDialog'
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core'

import {
  VisibilityRounded,
  VisibilityOffRounded,
  LockRounded,
} from "@material-ui/icons"

const ResetPasswordSecond = ({ actions, location, history }) => {
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [showPasswordConfirm, setShowPasswordConfirm] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [isValidLength, setIsValidLength] = useState(true)
  const [successUpdate, setSuccessUpdate] = useState(false)

  const VALID_LENGTH = 8
  
  const [showAlertDialog, setShowAlertDialog] = useState(false)
  useLayoutEffect(() => { actions.setLoading(false) }, [actions])

  const handleMouseDownPassword = event => event.preventDefault()
  const handleMouseDownPasswordConfirm = event => event.preventDefault()

  const { token, userId } = queryString.parse(location.search)

  const handleCloseDialog = () => setShowAlertDialog(false)

  const handleClickSuccess = () => {
    history.push('/path')
  }  
  
  const handleResetPassword = () => {
    
    if (password && passwordConfirm) {
      if (password.length < VALID_LENGTH) {
        setIsValidLength(false)
        return
      }
      if (password !== passwordConfirm) {
        setIsValid(false)
        return
      }
      setIsValidLength(true)
      setIsValid(true)


      actions.setLoading(true)

      const payload = {
        data: { 
          body: { 
            password,
            token,
            idClient: userId,
          } 
        }
      }
      
      actions.resetNewPassword(payload)
        .then(() => {
          setSuccessUpdate(true)
        })
        .finally(() => {
          actions.setLoading(false)
      })
    }
  }

  return (
    (!token || !userId) ? <Redirect to="/signin" /> :
    <>
      <div className="panel-register">
        <img className="imageBackground" src={backgroundSignIn} alt="background" />

        <div className="container-logo-inputs pr">
          <div className="container-logo">
            <div className="top-logo-info-signin">
              <div className="container-logo">
                <img src={logo} alt="logo" />
              </div>
            </div>
          </div>
        
          <div className="container-inputs">
            <h1>Reset Password</h1>
          
            {!successUpdate ?
              <div style={{width: "100%"}}>
                <div className="link-register">
                  <span>Por favor, introduce tu nueva contraseña:</span>
                </div>
                <TextField
                  name="password"
                  className="input-style bg-dark"
                  label="Contraseña" InputLabelProps={{ shrink: true}}
                  value={password}
                  onChange={e=>setPassword(e.target.value)}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{ 
                    startAdornment:
                      <InputAdornment position="start">
                        <LockRounded />
                      </InputAdornment>,
                    endAdornment: 
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        {
                          showPassword ? <VisibilityRounded /> : <VisibilityOffRounded />
                        }
                      </IconButton>
                    </InputAdornment>,
                  }}
                  variant="outlined"
                />
                <div>&nbsp;</div>
                <TextField
                  name="passwordConfirm"
                  className="input-style bg-dark"
                  label="Repetir Contraseña" InputLabelProps={{ shrink: true}}
                  value={passwordConfirm}
                  onChange={e=>setPasswordConfirm(e.target.value)}
                  type={showPasswordConfirm ? 'text' : 'password'}
                  InputProps={{ 
                    startAdornment:
                      <InputAdornment position="start">
                        <LockRounded />
                      </InputAdornment>,
                    endAdornment: 
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                        onMouseDown={handleMouseDownPasswordConfirm}
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        {
                          showPasswordConfirm ? <VisibilityRounded /> : <VisibilityOffRounded />
                        }
                      </IconButton>
                    </InputAdornment>,
                  }}
                  variant="outlined"
                />
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              {!isValidLength ?
                <div className="link-register">
                  <p>La contraseña introducida no es suficiente segura.</p>
                </div> : ''
              }
              {!isValid ?
                <div className="link-register">
                  <p>Parece que no coinciden.</p>
                </div> : ''
              }
                <div>&nbsp;</div>
                <div>&nbsp;</div>

              <div className="btns-signin">
                <Button
                  className="btn-material-form"
                  variant="contained"
                  onClick={handleResetPassword}
                >
                  <div>Reset Password</div>
                </Button>
              </div>

              <div className="link-register">
                <span>¿Has llegado aquí por error?</span>
                <Link to="/signin">
                  <div>Login aquí</div>
                </Link>
              </div>
          
              </div> : ''
            }
              {successUpdate ?
                <div style={{ width: "100%" }}>
                  <div className="link-register">
                    <p>Hemo actualizado tu contraseña y ya puedes volver a acceder.</p>
                  </div>
                  <div>&nbsp;</div>
                  <div>&nbsp;</div>
                  <div className="btns-signin">
                    <Button
                      className="btn-material-form"
                      variant="contained"
                      onClick={handleClickSuccess}
                    >
                      <div>Acceder</div>
                    </Button>
                  </div>
                  <div>&nbsp;</div>
                <div>&nbsp;</div>
                </div>
            : ''
            }
          </div>
          <AlertDialog open={showAlertDialog} close={handleCloseDialog} texto={'Antes de registrarte, debes solicitar acceso.'}/>
        </div>
      </div>
    </>
  )
}


const mapStateToProps = ({clients}) => ({
  clientSelected: clients.clientSelected,
  clientSelectedId: clients.clientSelectedId
});

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      resetNewPassword,
      setLoading, 
      setNotification,
    }, dispatch)	
	}
}
export default connect(mapStateToProps, matchDispatchToProps)(ResetPasswordSecond)
