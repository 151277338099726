import { templatesTypes } from '../actions/templatesActions'
let initialState = {
  templates: {}
}

if (typeof (Storage) !== 'undefined') {
  initialState = { 
    ...initialState, 
    templates: localStorage.getItem('templates') ??  initialState.templates
  }
}

const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case templatesTypes.SET_TEMPLATES: {
      //setItemLocalStorage('panelSelectedCrm', action.payload)
      return { ...state, templates: action.payload }
    }

    case templatesTypes.MODIFY_TEMPLATE: {
      return { 
        ...state, 
        templates: { 
          ...state.templates,
          [action.payload.idTemplate]: action.payload
        }
      }
    }
    
    default:
      return state
  }
}

export default reducer
