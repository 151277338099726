import React from 'react'

const Contact = () => {
  return(
    <div className='container-page-html'>
      <div>Actualmente ofrecemos tres formas de ponerte en contacto con el equipo de DOCE. ¡Vamos con ellas!</div>
      <div style={{color: "#98070a"}}><b>GRUPO DE FACEBOOK</b></div>
      <div>
        En los grupos de Facebook se resuelven las dudas que te puedan surgir en el día a día. Hay dos vías para hacerlo:
      </div>
      <ol>
        <li>Posteando en el muro la duda concreta que tengas. Recomendamos que hagas una recopilación de dudas durante varios días, y las publiques a la vez.</li>
        <li>Puedes usar el buscador del grupo (columna de la izquierda) y buscar palabras clave: piensa que en este grupo han estado miles de personas haciendo DOCE antes que tú, lo más normal es que tu duda ya haya sido resuelta.</li>
      </ol>
      <div>
        Recuerda que el grupo es de uso exclusivo para clientes, es 100% privado y que actualmente tenemos dos grupos: uno para chicas y otro para chicas.
      </div>

      <a href="https://www.facebook.com/groups/DOCE.EB.ESPARTANOS/">
        <div className="btn-facebook">Grupo de chicos</div>
      </a>
      <a href="https://www.facebook.com/groups/DOCE.EB.GUERRERAS/">
        <div className="btn-facebook">Grupo de chicas</div>
      </a>


      <div>
        <span style={{color: "#98070a"}}><b>EVALUACIONES CADA DOS SEMANAS</b></span>
      </div>
      <div>
        En las evaluaciones podrás resolver cualquier tipo de duda que te haya surgido durante las dos semanas anteriores. Recuerda ponernos en contexto sobre tus dudas y darnos toda la información posible.
        <span style={{color: "#98070a"}}><b>WHATSAPP</b></span>
      </div>
      <div>
        El servicio de Whatsapp de DOCE se debe utilizar en momentos muy concretos:
      </div>
      <ol>
        <li>He tenido algún dolor o molestia en un ejercicio, y creo que he podido lesionarme.</li>
        <li>Tengo dificultades técnicas con la app.</li>
        <li>No consigo encontrar datos como la fase de entrenamiento o los ajustes que te dimos hace dos semanas.</li>
      </ol>
      <a href="https://wa.me/message/WAT7HV5ERFTRD1">
        <div className="btn-whatsapp">Escríbenos por Whatsapp</div>
      </a>
      
    </div>
  )
}

export default Contact