import React, { useState } from 'react'
import styled from 'styled-components'
import { connect } from "react-redux";
import { Redirect, Link } from 'react-router-dom'
const FilterClient = ({ clientSelected }) => {
  const [loading, setLoading] = useState(false)
  const handleClick = (e) => {
    setLoading(true)
  }

  return (
    !clientSelected ? <Redirect to="/" /> : 
    <>
       {/* <img className="imageBackground" src={backgroundSignIn} /> */}
       <StyledContainerButtons>
        <Link to="/initial-form" onClick={handleClick}>
          <StyledButton>Soy cliente nuevo</StyledButton>
        </Link>
        <Link to="/old-clients-form" onClick={handleClick}>
          <StyledButton>Ya soy cliente</StyledButton>
        </Link>
       </StyledContainerButtons>
      {loading && <div className="block-form"></div>}

    </>
  )
}


const mapStateToProps = ({clients}) => ({
  clientSelectedId: clients.clientSelectedId,
  clientSelected: clients.clientSelected
});
export default connect(mapStateToProps)(FilterClient);

const StyledContainerButtons = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  & > a {
    width: 100%;
  }
  & > a:last-child > div{
    margin-top: 30px;
  }
`

const StyledButton = styled.div`
  position: relative;
  background: #c72020;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: normal;
  width: 100%;
  padding: 20px 10px;
`