import React from 'react'
import NextButton from '../NextButton'
import renderHTML from 'react-render-html';
import { replaceFields, blockForm } from '../../../utils/functions'
/* import { notificationEmailEmployee, emailsIds } from '../../../utils/constans'
import History from "../../../models/History"
import { Redirect, Link } from 'react-router-dom' */
import { connect } from "react-redux";
import { updateClient } from '../../../redux/actions/clientsActions'
import { updateHistory } from '../../../redux/actions/historyActions'
import { bindActionCreators } from 'redux';

const Statement = ({ field, /* initialValue, setAllowScrolling, */ moveSectionDown, clientSelected, actions, templates, historySelected}) => {

/*   const [setValue] = useState(initialValue) */

  const handleClickNext = () => {

    if(field.update === "phase") return handlePhase()
    moveSectionDown()
  }

  const handlePhase = () => {
    let /* multiPathPhase = {}, */ multiPathClient = {} /* ,multiPathHistory = [] */
    /*multiPathHistory.push(new History({
      idClient: clientSelected.idClient,
      category: `PHASE_${clientSelected.currentPhase.phaseNumber}`,
      from: "EMPLOYEE",
      to: "CLIENT",
      createdBy: "SYSTEM",
      metadata: {
        //idTemplate: "d4860963-0e18-41b7-be93-f83dc0783c12",
        subject: `Se ha enviado correctamente tu ${clientSelected.currentPhase.step === "check" ? "check de 2 semanas" : "fin de fase"}`,
        content: templates?.[clientSelected.currentPhase.step === "check" ? emailsIds.checkSent : emailsIds.endPhaseSent]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>'),
        notification: true,
        sent:{
          email: clientSelected.personalData.email,
          msg: templates?.[emailsIds.nofiticationEmail]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>'),
        }
      }
    }))*/
    // multiPathHistory.push(new History({
    //   idClient: clientSelected.idClient,
    //   category: `PHASE_${clientSelected.currentPhase.phaseNumber}`,
    //   from: "CLIENT",
    //   to: "EMPLOYEE",
    //   createdBy: "CLIENT",
    //   metadata: {
    //     //idTemplate: "d4860963-0e18-41b7-be93-f83dc0783c12",
    //     subject: `${clientSelected.currentPhase.step === "check" ? "Check de 2 semanas" : "Fin de fase"} (${clientSelected.personalData.nombre} ${clientSelected.personalData.apellidos})`,
    //     content: templates?.[clientSelected.currentPhase.step === "check" ? emailsIds.checkSent : emailsIds.endPhaseSent]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>'),
    //     notification: true,
    //     sent:{
    //       email: notificationEmailEmployee,
    //       msg: `${clientSelected.currentPhase.step === "check" ? "Check de 2 semanas" : "Fin de fase"} (${clientSelected.personalData.nombre} ${clientSelected.personalData.apellidos})`,
    //     }
    //   }
    // }))
    
    if(field.action==="status"){
      const { step } = historySelected.metadata
      multiPathClient["status"] = `WATING_FEEDBACK_THE_${step === "check" ? "CHECK" : "END_PHASE"}`

      const payloadHistory = {
        id: historySelected.idHistory,
        data: { body: { seenHistory:true } }
      }
      blockForm()
      actions.updateHistory(payloadHistory)
      .then(({ history }) => {

        const payloadClient = {
          id: clientSelected.idClient,
          data: { 
            body: {
              ...multiPathClient,
              //phase: Object.keys(multiPathPhase).length>0 ? multiPathPhase : null,
              //history: Object.keys(multiPathHistory).length>0 ? multiPathHistory : null,
            } 
          }
        }
        actions.updateClient(payloadClient)
        .then( ({ client, phase }) => {
          console.log('====================================');
          console.log(client, phase);
          console.log('====================================');
          moveSectionDown()
        })
        .catch( error => {
          console.log(error);
        })

      })
      .catch( error => {
        console.log(error);
      })
    }else{
      moveSectionDown()
    }
  }

/*   const handleChangeValue = (value) => {
    setValue(value)
    setAllowScrolling(true)
  } */

  return(
    <div className={`statement-page ${field?.layout?.pageContent?.className ?? ''}`}>

      { field?.attachment?.type === "video" &&
        <div className={field?.layout?.attachment?.properties?.className || ''}>
          <video playsinline autoPlay="autoplay" muted loop={true}>
            <source src={field.attachment.src} type="video/mp4"/>
          </video>
        </div>
      }

      {/* TOP IMAGE */}
      {field?.layout?.topContainer && 
        <div className={field?.layout?.topContainer?.className ?? ''}>
          {field?.layout?.topContainer?.attachment.type==="image" && 
            <img src={field?.layout?.topContainer?.attachment.src} alt="niidea"/>
          }
        </div>
      }
      
      <div className="content-statement">{renderHTML(replaceFields(clientSelected, field.title ?? ''))}</div>
      {field?.properties?.description && <div className="description-question">{renderHTML(replaceFields(clientSelected, field.properties.description))}</div>}
      <br/>
      <NextButton onClick={handleClickNext} visibility={true} buttonText={field?.properties?.button_text} hideMarks={field?.properties?.hide_marks}/>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="box-height"></div>
    </div>
  )
}

const mapStateToProps = ({clients, templates, history}) => ({
  clientSelected: clients.clientSelected,
  templates: templates.templates,
  historySelected: history.historySelected
});
const matchDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    updateClient,
    updateHistory,
  }, dispatch)	
})
export default connect(mapStateToProps, matchDispatchToProps)(Statement);