import { globalTypes } from '../actions/globalActions';
var initialState = {
  loading: false,
  notification: { visibility: false, text: '', status: false, moment: Date.now() },
}

export default function (state = initialState, action) {
  switch (action.type) {

    case globalTypes.SET_NOTIFICATION:{
      return { ...state, 
        notification: {
          ...state.notification,
          ...action.payload 
        }
      }
    }
    case globalTypes.SET_LOADING:{
      return { ...state, loading: action.payload }
    }
    
    default:{
      return state;
    }
  }
}