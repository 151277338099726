import React, { useEffect, useState, useLayoutEffect } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { signIn, setClient, createClient, getClient, setClientSelected, setClientSelectedId } from '../../redux/actions/clientsActions'
import { setLoading, setNotification } from '../../redux/actions/globalActions'
import { Redirect, Link } from 'react-router-dom'

import logo from '../../Images/logo.png'
import backgroundSignIn from '../../Images/backgroundSignIn.png'
import AlertDialog from '../AlertDialog'
import { TextField, InputAdornment, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import { VisibilityRounded, PersonRounded, VisibilityOffRounded, LockRounded} from "@material-ui/icons";

const SignIn = ({clientSelected, actions, clientSelectedId}) => {
  useLayoutEffect(() => { actions.setLoading(false) }, [actions])
  const [email, setEmail] = useState('')
  const [emailAccess, setEmailAccess] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [showAlertDialog, setShowAlertDialog] = useState(false)

  useEffect(() => {
    if(clientSelectedId && clientSelectedId !== clientSelected?.idClient){
      actions.getClient({ idClient: clientSelectedId })
      .then( client => {
        actions.setClientSelected({value: client})
      })
    }
  }, [actions, clientSelected, clientSelectedId])


  const handleMouseDownPassword = event => event.preventDefault();
  const handleLogin = () => {
    actions.setLoading(true)
    const payload = {
      data: { 
        body: {
          email, 
          password
        } 
      }
    }
    actions.signIn(payload)
    .then(({ idClient })=>{
      if(!idClient) throw new Error("Invalid email or password")
      actions.setClientSelectedId(idClient)
      actions.setLoading(false)
    })
    .catch(error => {
      console.log(error);
      actions.setNotification({ visibility: true, text: error, status: "close", moment: Date.now() })
      actions.setLoading(false)
    })
  }

  const handleCloseDialog = () => setOpenDialog(false);
  const handleCloseAlertDialog = () => setShowAlertDialog(false);

  const handleRequestAccess= () => {
    handleCloseDialog();
    actions.getClient({email: emailAccess})
    .then((client) => {
      if(client && client.status === "NEW"){
        setShowAlertDialog(true)
      } else {
  /*       const data = {
          body: {
            personalData: {
              email: emailAccess
            }
          }
        } */
        actions.createClient(emailAccess)
        .then(() => {
          setShowAlertDialog(true)
        })
        .catch((error) => console.log(error)) 
      }
    })
    .catch((error) => console.log(error))
  }

  return(
    
     clientSelectedId && clientSelected?.idClient ? <Redirect to="/" /> :

      <div className="panel-signin">

        <img className="imageBackground" src={backgroundSignIn} alt="background"/>

        <div className="container-logo-inputs pr">
          <div className="container-logo">
            <div className="top-logo-info-signin">
              <div className="container-logo">
                <img src={logo} alt="logo"/>
              </div>
            </div>
          </div>

          <div className="container-inputs">
            <h1>Sign in</h1>
            <TextField
              value={email}
              onChange={e=>setEmail(e.target.value)}
              className="input-style bg-dark mg-bottom-30"
              label="Email"
              InputLabelProps={{ shrink: true }}
              InputProps={{startAdornment:<InputAdornment position="start"><PersonRounded /></InputAdornment>}}
              variant="outlined"
            />

            <TextField
              className="input-style bg-dark"
              label="Password" InputLabelProps={{ shrink: true}}
              value={password}
              onChange={e=>setPassword(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              InputProps={{ 
                startAdornment:<InputAdornment position="start"><LockRounded /></InputAdornment>,
                endAdornment: 
                <InputAdornment position="end">
                  <IconButton onClick={()=>setShowPassword(!showPassword)} onMouseDown={handleMouseDownPassword} aria-label="toggle password visibility" edge="end">
                    {showPassword ? <VisibilityRounded /> : <VisibilityOffRounded />}
                  </IconButton>
                </InputAdornment>,
              }}
              variant="outlined"
            />
            <div>&nbsp;</div>
            <div className="btns-signin">
              {/* <div onClick={() => setOpenDialog(!openDialog)}>REQUEST ACCESS</div> */}
              <Button className="btn-material-form" variant="contained" onClick={handleLogin}>
                <div>LOG IN</div>
              </Button>

            </div>

            <Dialog open={openDialog}
              onClose={handleCloseDialog} 
              aria-labelledby="form-dialog-title" 
              fullWidth >
              <DialogTitle id="form-dialog-title">Request Access</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Introduzca su email
                </DialogContentText>
                <TextField
                  value={emailAccess}
                  color="secondary"
                  onChange={e=>setEmailAccess(e.target.value)}
                  label="Email"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              </DialogContent>
              <DialogActions>
                <Button className="btn-solicitar" onClick={handleRequestAccess}>
                  REQUEST
                </Button>
              </DialogActions>
            </Dialog>

            <div className="link-register">
              <span>¿Eres nuevo? Crea tu contraseña</span>
              <Link to="/register">aquí</Link>
            </div>

            <Link className="btn-fotgot-pass" to="/reset-password">
              ¿Has olvidado tu contraseña?
            </Link>
            
            {/*<div className="terms-container">
              <span>Privacy</span>
              <span className="dot-separation"></span>
              <span>Terms of use</span>
              <span className="dot-separation"></span>
              <span>Cookies</span>
            </div>*/}
          </div>
          
          <AlertDialog open={showAlertDialog} close={handleCloseAlertDialog} texto={'¡Enhorabuena! Ya tienes acceso, puedes registrarte.'} />
        </div>

        {/* <Input title='Email' placeholder="example@email.com" value={email} onChange={value=>setEmail(value)}/>
        <Input title='Password' placeholder="••••••••••••••" value={password} onChange={value=>setPassword(value)} type="password"/>
        <Input title='Token' placeholder="aFZgv78bKhkHHuIU8iBH"  value={token} onChange={value=>setToken(value)}/> */}


        {/* <div className="pr btn-app primary" onClick={changePanel}>Next</div> */}


      </div>
    

  )
}


const mapStateToProps = ({clients}) => ({
  clientSelected: clients.clientSelected,
  clientSelectedId: clients.clientSelectedId
});

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      signIn,
      setClient,
      getClient,
      createClient,
      setClientSelected,
      setClientSelectedId,
      setLoading,
      setNotification
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(SignIn);
