import React, { useState, useLayoutEffect } from "react"
import { Form } from "doce-core"
import { connect } from "react-redux";
import {
  updateClient,
  setClientSelected,
} from "../../redux/actions/clientsActions"
import { bindActionCreators } from "redux"
import { Redirect } from "react-router-dom"
import { getPathUpdate, getPathClientUpdate } from "../../utils/functions"
import { updatePhase } from "../../redux/actions/phasesActions"
import { setNotification } from "../../redux/actions/globalActions"

const { oldClientForm } = require("doce-core").constants;

const OldClientsForm = ({ clientSelected, actions }) => {
  const [initialValues, setInitialValues] = useState({})
  const [redirectTo, setRedirectTo] = useState(null)
  
  useLayoutEffect(() => {
    const getInitialValues = () => {
      let initialValues = {};
      const initialFormFields = oldClientForm.fields.find(
        (field) => field.id === "g83Ty6o1KHjj432d"
      ).properties.fields;
      initialFormFields.forEach(({ id, ref, prefix }) => {
        if (id === 'yY7utz84toGe' || id === 'q2wVZ4lDYK7F') return 
        const key = prefix ? `${prefix}-${id}` : id;
        initialValues[key] = getPathClientUpdate(clientSelected, id, ref)
      })
  
      initialFormFields.forEach((item, index, object) => {
        if (item.id === 'yY7utz84toGe' || item.id === 'q2wVZ4lDYK7F') {
          object.splice(index, 1);
        }
      })
  
      //setInitialValues(initialValues)
      //let formLs = { ...JSON.parse(localStorage.getItem('form')), ...initialValues}
      //localStorage.setItem("form", JSON.stringify(formLs))
      setInitialValues(initialValues)
    };
    if (clientSelected) {
      getInitialValues()
    }
  }, [clientSelected])



  const onChange = ({ key, field, oldValue, newValue, values }) =>
    new Promise(async (resolve, reject) => {
      //localStorage.setItem("form", JSON.stringify(values))

      if (oldValue !== newValue) {
        if (field.action === "client") {
          saveClient({ field, newValue })
            .then(() => {
              resolve()
            })
            .catch((error) => {
              actions.setNotification({
                visibility: true,
                text: error,
                status: "close",
                moment: Date.now(),
              })
              reject()
            })
        } else {
          resolve()
        }
      } else if (field.ref === "saveInfo") {
        await createPhases({ values })
          .then(() => {
            resolve()
          })
          .catch((error) => {
            actions.setNotification({
              visibility: true,
              text: error,
              status: "close",
              moment: Date.now(),
            })
            reject()
          })
      } else if (field.id === "confirm") {
        setRedirectTo("/")
      } else {
        resolve()
      }
    })

  const createPhases = ({ values }) =>
    new Promise(async (resolve, reject) => {
      const step = values?.["oSH1M0l00ZyW"][0] ?? null;
      let multipathClient = {
        "status": `WATING_FEEDBACK_THE_${
          step === "check" ? "CHECK" : "END_PHASE"
        }`,
        "initialForm.createdDate": "date:now",
      };
      const lastStep = step === "check" ? "endPhase" : "check";
      let lastStepValues = {
        [`${lastStep}.questions.averageCurrentWeight`]: values[
          "PC-averageCurrentWeight"
        ],
        [`${lastStep}.questions.averageWaistCurrent`]: values[
          "PC-averageWaistCurrent"
        ],
        [`${lastStep}.questions.neat`]: values["PC-neat"],
        [`${lastStep}.images.A0r8W8s2ZHpd`]: values["PC-A0r8W8s2ZHpd"],
        [`${lastStep}.images.fQJBGRlsZnvl`]: values["PC-fQJBGRlsZnvl"],
        [`${lastStep}.images.DClriwj3KyQt`]: values["PC-DClriwj3KyQt"],
        [`${lastStep}.images.j38ijKnLsNSx`]: values["PC-j38ijKnLsNSx"],
        [`${lastStep}.startDate`]: "date:now",
        [`${lastStep}.notificated`]: "date:now",
      };
      if (values["UC-mfpCheck"][0] === "yes") {
        lastStepValues[`${lastStep}.questions.kcal`] = values["PC-kcal"];
        lastStepValues[`${lastStep}.questions.protein`] = values["PC-protein"];
        lastStepValues[`${lastStep}.questions.carbohydrates`] =
          values["PC-carbohydrates"];
        lastStepValues[`${lastStep}.questions.fats`] = values["PC-fats"];
      }
      let currentStepValues = {
        [`${step}.questions.averageCurrentWeight`]: values[
          "UC-averageCurrentWeight"
        ],
        [`${step}.questions.averageWaistCurrent`]: values[
          "UC-averageWaistCurrent"
        ],
        [`${step}.questions.neat`]: values["UC-neat"],
        [`${step}.images.A0r8W8s2ZHpd`]: values["UC-A0r8W8s2ZHpd"],
        [`${step}.images.fQJBGRlsZnvl`]: values["UC-fQJBGRlsZnvl"],
        [`${step}.images.DClriwj3KyQt`]: values["UC-DClriwj3KyQt"],
        [`${step}.images.j38ijKnLsNSx`]: values["UC-j38ijKnLsNSx"],
        [`${step}.questions.exerciseWaistPainful`]: values[
          "UC-exerciseWaistPainful"
        ],
        [`${step}.questions.personalBest`]: values["UC-personalBest"],
        [`${step}.questions.levelAdherence`]: values["UC-levelAdherence"],
        [`${step}.questions.whyThatNote`]: values["UC-whyThatNote"],
        [`${step}.questions.whatDidWell`]: values["UC-whatDidWell"],
        [`${step}.questions.improvements`]: values["UC-improvements"],
        [`${step}.questions.tweet`]: values["UC-tweet"],
        [`${step}.questions.miniVictory`]: values["UC-miniVictory"],
        [`${step}.questions.notes`]: values["UC-notes"],
        [`${step}.startDate`]: "date:now",
        [`${step}.notificated`]: "date:now",
      };
      if (values["UC-mfpCheck"][0] === "yes") {
        currentStepValues[`${step}.questions.kcal`] = values["UC-kcal"];
        currentStepValues[`${step}.questions.protein`] = values["UC-protein"];
        currentStepValues[`${step}.questions.carbohydrates`] =
          values["UC-carbohydrates"];
        currentStepValues[`${step}.questions.fats`] = values["UC-fats"];
        multipathClient["isMfp"] = "Si";
      }

      let multipathCurrentPhase = {},
        existsLastPhase = false,
        payloadLastPhase = {};
      if (step === "endPhase") {
        multipathCurrentPhase = { ...lastStepValues, ...currentStepValues };
      } else {
        //si entra por aqui, significa  que hay que crear dos fases. Se creará una anterior que va a contener los datos de lastStepValues como fin de fase
        //pero hay que crear un check de 2 para esta fase, que contenga la fecha de startDate y notificated para no enviar notificaciones de checks para esta fase
        lastStepValues[`${step}.startDate`] = "date:now";
        lastStepValues[`${step}.notificated`] = "date:now";

        payloadLastPhase = {
          id: "newPhase",
          data: {
            body: {
              idClient: clientSelected.idClient,
              nextPhaseNumber: values["h0sAA2lzU7X4"] - 1,
              nextPhase: { ...lastStepValues },
            },
          },
        };
        multipathCurrentPhase = currentStepValues;
        existsLastPhase = true;
      }
      const payloadCurrentPhase = {
        id: "newPhase",
        data: {
          body: {
            idClient: clientSelected.idClient,
            nextPhaseNumber: values["h0sAA2lzU7X4"],
            nextPhase: multipathCurrentPhase,
          },
        },
      };

      //guardamos primero lafase en curso
      return await actions
        .updatePhase(payloadCurrentPhase)
        .then(async (response) => {
          if (!response?.nextPhase)
            return reject("No se ha podido guardar la fase actual")
          const payload = {
            id: clientSelected.idClient,
            data: {
              body: {
                ...multipathClient,
                "currentPhase.idPhase": response.nextPhase.idPhase,
                "currentPhase.phaseNumber": response.nextPhase.phaseNumber,
                "currentPhase.step": step,
              },
            },
          };
          //si existe penultima fase guardamos esa fase
          if (existsLastPhase) {
            return await actions
              .updatePhase(payloadLastPhase)
              .then(async ({ nextPhase }) => {
                if (!nextPhase)
                  return reject("No se ha podido guardar la última fase")
                return await actions
                  .updateClient(payload)
                  .then(({ client }) => {
                    actions.setClientSelected({
                      path: "initialForm.createdDate",
                      value: client.initialForm.createdDate,
                    })
                    return resolve()
                  })
                  .catch((error) => {
                    return reject(error)
                  })
              })
              .catch((error) => {
                return reject(error)
              })
          } else {
            return await actions
              .updateClient(payload)
              .then(async ({ client }) => {
                actions.setClientSelected({
                  path: "initialForm.createdDate",
                  value: client.initialForm.createdDate,
                })
                return resolve()
              })
              .catch((error) => {
                return reject(error)
              })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

  const saveClient = ({ field, newValue }) =>
    new Promise(async (resolve, reject) => {
      const payload = {
        id: clientSelected.idClient,
        data: {
          body: {
            [field.ref === "initialForm.questions"
              ? `${field.ref}.${field.id}`
              : field.ref]: newValue,
          },
        },
      };
      actions
        .updateClient(payload)
        .then((client) => {
          const path = getPathUpdate(field.id, field.ref)
          const value = getPathClientUpdate(client, field.id, field.ref)
          return resolve(actions.setClientSelected({ path, value }))
        })
        .catch((error) => reject(error))
    })

  return !clientSelected ? (
    <Redirect to="/" />
  ) : redirectTo ? (
    <Redirect to={redirectTo} />
  ) : (
    <Form
      form={oldClientForm}
      onChange={onChange}
      initialActive="startInfo"
      initialValues={initialValues}
    />
  )
};

const mapStateToProps = ({ clients }) => ({
  clientSelectedId: clients.clientSelectedId,
  clientSelected: clients.clientSelected,
})
const matchDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateClient,
      setClientSelected,
      updatePhase,
      setNotification,
    },
    dispatch
  ),
})
export default connect(mapStateToProps, matchDispatchToProps)(OldClientsForm)
