import React from 'react'
import { connect } from 'react-redux';
import { Backdrop, CircularProgress, LinearProgress } from '@material-ui/core';

const Loading = ({ loading }) => {
  return(
    <Backdrop className={`loading-panel ${loading ? '' : 'display-none'}`} open={true} >
      <LinearProgress className="progress-loading" />
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

const mapStateToProps = ({ global }) => ({
  loading: global.loading
});

export default connect(mapStateToProps)(Loading);