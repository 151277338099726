import React from 'react'
import { useState } from 'react'
import arrowInitialForm from '../../Images/arrowInitialForm.svg'
import renderHTML from 'react-render-html';
import Input from './Types/Input'
import TextArea from './Types/TextArea'
import Date from './Types/Date'
import Statement from './Types/Statement'
import PhoneNumber from './Types/PhoneNumber'
import NextButton from './NextButton'
import MultipleOptions from './Types/MultipleOptions'
import OptionScale from './Types/OptionScale';
import FileUpload from './Types/FileUpload';

import { getPathClientUpdate, replaceFields } from '../../utils/functions'
//import { replaceFields } from '../../redux/actions/clientsActions'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom'


const sectionTypes = ["input", "email", "date", "phoneNumber", "number", "multiple_choice", "long_text", "opinion_scale", "group"]

const PageForm = ({ id, field, menu = false, moveSectionDown, setAllowScrolling, clientSelected, /* actions, */ historySelected }) => {
  const getReference = () => {
    let reference = field.ref
    if(field.update === "phase" && historySelected?.metadata?.idPhase && historySelected?.metadata?.step){
      reference = `["${historySelected.metadata.idPhase}"].${historySelected.metadata.step}.${reference}`
    }
    return reference
  }
  const [type] = useState(field?.type ?? null)
  const [reference] = useState(getReference()) 
  const [redirectTo, setRedirect] = useState(null)

  const moveSectionDownStatement = (field) => {
    if(field?.properties?.redirect){
      setRedirect(field?.properties?.redirect)
    }else{
      moveSectionDown()
    }
  }



  return(
    redirectTo ? <Redirect to={redirectTo} /> : 

    <div id={id} className={`page-form ${clientSelected?.initialForm?.questions?.[field?.dependencies?.id] && !clientSelected.initialForm.questions[field.dependencies.id].includes(field?.dependencies?.answer) ? 'hidde-question' : '' }`} >

      {/* Menu */}
      {menu &&
        <div className="menu-section">
          <div>
            <div className={'container-indentification-question'}>
              <div className="number-text">{menu.identidicator}</div>
              <div className="container-arrow"><img src={arrowInitialForm} alt="arrow"/ ></div>
            </div>
          </div>
          <div className="question-text">{renderHTML(menu?.title ?? '')}</div>
        </div>
      }
      
      {/* SECCION */}
      { sectionTypes.includes(type) && 
        <div className={`section ${field?.layout?.sectionClassName ?? ''}`}>
          
          {/* TOP IMAGE */}
          {field?.layout?.topContainer && 
            <div className={field?.layout?.topContainer?.className ?? ''}>
              {field?.layout?.topContainer?.attachment.type==="image" && 
                <img src={field?.layout?.topContainer?.attachment.src} alt="niidea" />
              }
            </div>
          }
          
          {/* PREGUNTA */}
          <div className="content-section" >
            
            {/* Identificador de pregunta */}
            {field?.identidicator &&
              <div>
                <div className={'container-indentification-question'}>
                  <div className="number-text">{field.identidicator}</div>
                  {!field.hideArrowIndentificator && <div className="container-arrow"><img src={arrowInitialForm} alt="arrow"/></div>}
                </div>
              </div>
            }

            {/* Contenedor de la pregunta */}
            <div>

              {/* Pregunta */}
              <div className="question-container">
                <div className="question-text">{field?.title ? renderHTML(replaceFields(clientSelected, field.title)) : ''}</div>
                {field?.attachment?.type === "image" && <div className="container-attachment-initial-form"><img src={field?.attachment?.src ?? ''} alt="niidea"/></div>}
                {field?.properties?.description && <div className="description-question">{renderHTML(field.properties.description)}</div>}
              </div>

              { type === 'group' &&  <NextButton onClick={moveSectionDown} visibility={true} buttonText={field?.properties?.button_text} hideMarks={field?.properties?.hide_marks}/> }

              {/* Tipo de respuesta */}
              <div className="answer-container">
                { ['input', 'email', 'number'].includes(type) && <Input type={type} hideInput={field.hideInput} initialValue={getPathClientUpdate(clientSelected, field.id, reference) ?? ""} setAllowScrolling={setAllowScrolling} moveSectionDown={moveSectionDown} field={field} />}
                { type === "long_text" && 
                  <TextArea type={type} initialValue={getPathClientUpdate(clientSelected, field.id, reference) ?? ""} setAllowScrolling={setAllowScrolling} moveSectionDown={moveSectionDown} field={field} />
                }
                { type === 'date' && <Date initialValue={getPathClientUpdate(clientSelected, field.id, reference) ?? ""} setAllowScrolling={setAllowScrolling} moveSectionDown={moveSectionDown} field={field}  /> }
                { type === 'phoneNumber' && <PhoneNumber initialValue={getPathClientUpdate(clientSelected, field.id, reference) ?? ""} setAllowScrolling={setAllowScrolling} moveSectionDown={moveSectionDown} field={field} />}
                { type === 'multiple_choice' && <MultipleOptions field={field} initialValues={getPathClientUpdate(clientSelected, field.id, reference) ?? []} multipleSelection={field.properties.allow_multiple_selection} choices={field.properties.choices} allowOtherChoice={field.properties.allow_other_choice} yesOrNo={field.yesOrNo} legal={field.properties.legal} moveSectionDown={moveSectionDown} />}
                { type === 'opinion_scale' && <OptionScale field={field} initialValue={getPathClientUpdate(clientSelected, field.id, reference) ?? ""}  steps={field.properties.steps} labels={field.properties.labels} moveSectionDown={moveSectionDown} setAllowScrolling={setAllowScrolling}/> }


              </div>

            </div>

          </div>

        </div>
      }

      {/* STATEMENT */}
      { type === "statement" && <Statement field={field} moveSectionDown={() => moveSectionDownStatement(field)}/> }

      {/* FILE UPLOAD */}
      { type === "file_upload" && <FileUpload field={field} initialFile={getPathClientUpdate(clientSelected, field.id, reference) ?? null} moveSectionDown={moveSectionDown}/> }
    </div>
  )
}

const mapStateToProps = ({clients, history}) => ({
  clientSelected: clients.clientSelected,
  historySelected: history.historySelected
})

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      replaceFields
    }, dispatch)	
	}
}

export default connect(mapStateToProps, matchDispatchToProps)(PageForm)