import React from 'react'
import PropTypes from 'prop-types';
import { addLocaleData, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import messages from './messages.json'

import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import pt from 'react-intl/locale-data/pt';
import Routes from '../../Routes';

addLocaleData([...en, ...es, ...pt]);

const IntlApp = ({language}) => {
  
  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Routes/>
    </IntlProvider>
  )
}

IntlApp.propTypes = {
  locale: PropTypes.string
};

IntlApp.defaultProps = {
  locale: 'en'
};

const mapStateToProps = state => ({
  language: state.intl.language
})
export default connect(mapStateToProps)(IntlApp) 
