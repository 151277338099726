import React from 'react'

const HeaderEntrenamiento = ({ title }) => {
  return(
    <div>
      <h2>{title}</h2>
    </div>
  )
}

export default HeaderEntrenamiento