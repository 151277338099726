import React from 'react'
//import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
import { initialForm } from '../../utils/initialForm'
import PageForm from './PageForm';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom'
import { scrollNextPage } from '../../utils/functions'

/* let anchors = [...Array(100).keys()].map((key) => `${key}`) */
const InitialForm = ({ clientSelected }) => {

  const moveSectionDown = () => {
    scrollNextPage()
  }

  return(
    !clientSelected ? <Redirect to="/" /> : 
    <div className="content-form-scroll" style={{overflow: "hidden"}}>
      <div id="fullpage">
        <>
          {initialForm.fields.map((field, key)=>{
            return(
              <>
                {/* <a id={key+1}> */}
                  <PageForm id={`id-${key+1}`} field={field} key={field.id} moveSectionDown={moveSectionDown} setAllowScrolling={ value => {}} /*setAllowScrolling={ value => setAllowScrolling(value)}*/ />
                {/* </a> */}
                {field?.properties?.fields &&
                  field.properties.fields.map((childField, key2) => {
                    return(
                      // <a id={`${key+1}-${key2+1}`}>
                        <PageForm id={`id-${key+1}-${key2+1}`} field={childField} menu={field?.menu ?? false} key={field.id} moveSectionDown={moveSectionDown} setAllowScrolling={ value => {}} /*setAllowScrolling={ value => setAllowScrolling(value)}*/ />
                      // </a>
                    )
                  })
                }
              </>
            )
          })}
          
          <PageForm id={"thankyou_screens"} field={initialForm['thankyou_screens'][0]} moveSectionDown={moveSectionDown} setAllowScrolling={ value => {}} /*setAllowScrolling={ value => setAllowScrolling(value)}*/ />

        </>
      </div>
      <div className="block-form" style={{display: "none"}}></div>
    </div>
  )
}

const mapStateToProps = ({clients}) => ({
  clientSelectedId: clients.clientSelectedId,
  clientSelected: clients.clientSelected
});
export default connect(mapStateToProps)(InitialForm);