import React from 'react'
import { Line } from 'react-chartjs-2'
import 'chartjs-plugin-lineheight-annotation'
import styled from 'styled-components';

const ChartData = ({ data }) => {

  // Chart.plugins.register({
  //   beforeRender: function(chart) {
  //     if (chart.config.options.showAllTooltips) {
  //       // create an array of tooltips, 
  //       // we can't use the chart tooltip because there is only one tooltip per chart
  //       chart.pluginTooltips = [];
  //       chart.config.data.datasets.forEach(function(dataset, i) {
  //         chart.getDatasetMeta(i).data.forEach(function(sector, j) {
  //           chart.pluginTooltips.push(new Chart.Tooltip({
  //             _chart: chart.chart,
  //             _chartInstance: chart,
  //             _data: chart.data,
  //             _options: chart.options.tooltips,
  //             _active: [sector]
  //           }, chart));
  //         });
  //       });      
  //       chart.options.tooltips.enabled = false; // turn off normal tooltips
  //     }
  //   },
  //   afterDraw: function(chart, easing) {
  //     if (chart.config.options.showAllTooltips) {
  //       if (!chart.allTooltipsOnce) {
  //         if (easing !== 1) {
  //           return;
  //         }
  //         chart.allTooltipsOnce = true;
  //       }
  //       chart.options.tooltips.enabled = true;
  //       Chart.helpers.each(chart.pluginTooltips, function(tooltip) {
  //         tooltip.initialize();
  //         tooltip.update();
  //         tooltip.pivot();
  //         tooltip.transition(easing).draw();
  //       });
  //       chart.options.tooltips.enabled = false;
  //     }
  //   }  
  // })

  return (
    <ContainerChartData>
      <Line options={{
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 40,
            bottom: 0
          }
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 6,
            fontSize: 16,
            fontColor: "white",
            fontFamily: 'sans-serif',
            usePointStyle: true
          }

        },
        maintainAspectRatio: false,
        responsive: true,
        lineHeightAnnotation: {
          shadow: true,
          color: "rgb(255,255,255,0.5)"
        },
        scales: {
          yAxes: [{
            gridLines: {
              display: false,
              color: "rgb(255,255,255,0.5)"
            },
            ticks: {
              display: true,
              beginAtZero: true,
              min: 40,
              maxTicksLimit: 10
            }
          }],
          xAxes: [{
            gridLines: {
              display: false,
            }
          }]
        },
        showAllTooltips: true,
      }} data={data}
      />
    </ContainerChartData>
  )
}

export default (ChartData);

const ContainerChartData = styled.div`
  position: relative;
  height: 250px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
`