import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  createFile,
  downloadFile,
 /*  generateFile, */
  openPdfApp,
} from "../../utils/functions";
import { SERVER_URL } from "../../utils/constans";

import { Redirect } from "react-router-dom";
const { trainings } = require("doce-core").constants;
const OptionsPanel = ({ data, onClose, active = false, clientSelected }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (active) {
      document.getElementById("root").style.overflow = "hidden";
      setTimeout(() => setShowOptions(true), 200);
    } else {
      document.getElementById("root").style.overflow = "auto";
    }
  }, [active]);

  const onCloseOptions = () => {
    setShowOptions(false);
    setTimeout(() => onClose(), 200);
  };

  const handleClick = async (btn, key) => {
    const phase =
      clientSelected?.phases?.[clientSelected.currentPhase.idPhase] ?? null;

    if (
      btn.text === "Para días de entrenamiento" ||
      btn.text === "Descarga tu plan"
    ) {
      if (phase?.training?.nutritionalPlans?.nutritionalPlanTraining?.data) {
        openPdfApp(
          `${SERVER_URL}${phase.training.nutritionalPlans.nutritionalPlanTraining.data}`
        );
      }
      //const file = await generateFile(phase?.training?.nutritionalPlans?.nutritionalPlanTraining)
      //downloadFile({file, filename: "Plan nutricional"})
    } else if (btn.text === "Para días de descanso") {
      const file = createFile(
        phase?.training?.nutritionalPlans?.nutritionalPlanRest
      );
      downloadFile({ file, filename: "Para días de descanso" });
    } else if (btn.text === "Para cuatro comidas al día") {
      openPdfApp(`https://app.programadoce.com/assets/pdfs/MealPlanner4$2.pdf`);
      /*
      axios(`/assets/pdfs/MealPlanner4$2.pdf`, { method: 'GET', responseType: 'blob'})
      .then(({data})=>{
        downloadFile({file: data, filename: "Planificador de comidas (4 meals + 2 snacks)"})
      })*/
    } else if (btn.text === "Para tres comidas al día") {
      openPdfApp(`https://app.programadoce.com/assets/pdfs/MealPlanner3$2.pdf`);
      /*
      axios(`/assets/pdfs/MealPlanner3$2.pdf`, { method: 'GET', responseType: 'blob'})
      .then(({data})=>{
        downloadFile({file: data, filename: "Planificador de comidas (3 meals + 2 snacks)"})
      })
      */
    } else if (btn.text === "Vídeos de entrenamiento") {
      setRedirectTo("/training-videos");
    } else if (btn.text === "Entrenamiento en PDF") {
      const training =
        clientSelected?.phases?.[clientSelected?.currentPhase?.idPhase]
          ?.training;
      const pdfPath =
        trainings?.[training?.trainingType]?.[training?.trainingSubType]
          ?.pdfPath;
      if (pdfPath) {
        openPdfApp(`https://app.programadoce.com${pdfPath}`);
        /*axios(pdfPath, { method: 'GET', responseType: 'blob'})
        .then(({data})=>{
          downloadFile({file: data, filename: "Entrenamiento"})
        })*/
      }
    }
  };

  return redirectTo ? (
    <Redirect to={redirectTo} />
  ) : (
    active && data && (
      <div className="block-panel" onClick={() => onCloseOptions()}>
        <div
          className={`btns-download-pdf ${showOptions ? "active-view" : ""}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="title-popup">{data.title}</div>
          {data.buttons.map((btn, key) => {
            return (
              <div
                key={key}
                className="btn-popup"
                onClick={() => handleClick(btn, key)}
              >
                {btn.text}
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

const mapStateToProps = ({ clients }) => ({
  clientSelected: clients.clientSelected,
});

export default connect(mapStateToProps)(OptionsPanel);
