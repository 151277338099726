import React from 'react'
import { useState, useLayoutEffect } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { resetPassword } from '../../redux/actions/clientsActions'

import { setLoading, setNotification } from '../../redux/actions/globalActions'
import { Link } from 'react-router-dom'

import logo from '../../Images/logo.png'
import backgroundSignIn from '../../Images/backgroundSignIn.png'
import AlertDialog from '../AlertDialog'
import { TextField, InputAdornment, Button } from '@material-ui/core';
import { PersonRounded } from "@material-ui/icons";



const ResetPassword = ({ actions}) => {
  const [email, setEmail] = useState()
  const [isValid, setIsValid] = useState(true)
  const [success, setsuccess] = useState(false)
  
  const [showAlertDialog, setShowAlertDialog] = useState(false)

  useLayoutEffect(() => { actions.setLoading(false) }, [actions])

  const handleCloseDialog = () => setShowAlertDialog(false);

  const handleResetPassword = () => {
    
    if (email) {
      const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
      
      if (!pattern.test(email.trim())) {
        setIsValid(false)
        return
      }
      setIsValid(true)


      actions.setLoading(true)

      const payload = {
        data: { 
          body: { 
            email: email.trim(),
          } 
        }
      }
      
      actions.resetPassword(payload)
        .finally(() => {
          setsuccess(true)
          actions.setLoading(false)
        })
    }
  }

  return(

      <div className="panel-register"> 

        <img className="imageBackground" src={backgroundSignIn} alt="background"/>

        <div className="container-logo-inputs pr">
          <div className="container-logo">
            <div className="top-logo-info-signin">
              <div className="container-logo">
                <img src={logo} alt="logo"/>
              </div>
            </div>
          </div>

          <div className="container-inputs">
            <h1>Reset Password</h1>
            {!success ?
              <div className="link-register">
                <span>Por favor, introduce el email de tu cuenta de usuario</span>
              </div> : ''
            }
            {!success ?
              <TextField
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="input-style bg-dark mg-bottom-30"
                label="Email"
                InputLabelProps={{ shrink: true }}
                InputProps={{ startAdornment: <InputAdornment position="start"><PersonRounded /></InputAdornment> }}
                variant="outlined"
              /> : ''
            }
            {success ?
              <div className="link-register">
                <p>Te hemos enviado un correo con más instrucciones.</p>
              </div> : ''
            }
            {!isValid ?
              <div className="link-register">
                <p>Parece que el email introducido no es correcto.</p>
              </div> : ''
            }

            <div className="link-register">
              <span>¿Ya estás registrado?</span>
              <Link to="/signin">
                <div>Login aquí</div>
              </Link>
            </div>

            <div className="btns-signin">
              <Button
                className="btn-material-form"
                variant="contained"
                onClick={handleResetPassword}
              >
                <div>Reset Password</div>
              </Button>
            </div>
          </div>
          <AlertDialog open={showAlertDialog} close={handleCloseDialog} texto={'Antes de registrarte, debes solicitar acceso.'}/>
        </div>
      </div>
  )
}


const mapStateToProps = ({clients}) => ({
  clientSelected: clients.clientSelected,
  clientSelectedId: clients.clientSelectedId
});

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      resetPassword,
      setLoading, 
      setNotification,
    }, dispatch)	
	}
}
export default connect(mapStateToProps, matchDispatchToProps)(ResetPassword)
