import React from 'react';
import ReactFullpage from "@fullpage/react-fullpage";

const Test = () => {

  return(
    <ReactFullpage
      anchors={anchors}
      navigationTooltips={anchors}
      sectionsColor={["#282c34", "#ff5f45", "#0798ec"]}
      normalScrollElements=".test-section"
      render={({ fullpageApi }) => {
        /* const moveSectionDown  = fullpageApi?.moveSectionDown  */
        const setAllowScrolling  = fullpageApi?.setAllowScrolling 
        if(setAllowScrolling) setAllowScrolling(false)
        return (
          <div>
            <MySection content={"Slide down!"} />
            <MySection content={"Keep going!"} />
            <MySection content={"Slide up!"} />
          </div>
        );
      }}
    />
  )
}

export default Test

class MySection extends React.Component {
  render() {
    return (
      <div className="section">
        <div className="test-section">
          <h3>{this.props.content}</h3>
        </div>
      </div>
    );
  }
}

const anchors = ["firstPage", "secondPage", "thirdPage"];