import { CHANGE_LANGUAGUE_ACTION } from '../actions/intlActions'

let initialState = {
  language: "es"
}

if (typeof (Storage) !== 'undefined') {
  const langStorage = localStorage.getItem('locale') || 'es'
  initialState = { ...initialState, language: langStorage }
} else {
  console.warn('Your browser doesn\'t support localstorage')
  initialState = { ...initialState, language: navigator.languages.indexOf('es') >= 0 ? 'es' : 'en' }
}

const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGUE_ACTION: {
      return { ...state, language: action.payload }
    }
    default:
      return state
  }
}

export default reducer
