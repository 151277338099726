import React, { useState } from 'react'
import NextButton from '../NextButton'
import renderHTML from 'react-render-html';
import cloud from '../../../Images/InitialForm/cloud.svg'
import arrowUpload from '../../../Images/InitialForm/arrowUpload.svg'
import crossUpload from '../../../Images/InitialForm/crossUpload.svg'
import docUpload from '../../../Images/InitialForm/docUpload.svg'
import { converFileToBase64, replaceFields, convertDataFile, getPathUpdate, getPathClientUpdate, blockForm, isFile } from '../../../utils/functions'
import { SERVER_URL } from '../../../utils/constans'
import { updatePhase } from '../../../redux/actions/phasesActions'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { updateClient, setClientSelected } from '../../../redux/actions/clientsActions'
import _ from 'underscore'
import imageCompression from 'browser-image-compression';

const FileUpload = ({ field, type, initialFile = null, setAllowScrolling, moveSectionDown, clientSelected, actions, historySelected}) => {


  const [file, setFile] = useState(initialFile)

  const handleFile = async file => {
    try {
      if(file.type === "image/png"){
        const options = {
          maxSizeMB: 5,
          useWebWorker: true
        }
        const compressedFile = await imageCompression(file, options);
        const fileData = await converFileToBase64(compressedFile)
        //const fileData = await convertFileToBuffer(compressedFile)
        setFile(fileData)
      }else{
        const fileData = await converFileToBase64(file)
        //const fileData = await convertFileToBuffer(file)
        setFile(fileData)
      }
      //await uploadToServer(compressedFile); // write your own logic
    } catch (error) {
      console.log(error);
    }
    
  }

  const handleClickNext = () => {
    if(!isFile(file)) return 
    if(field.update === "phase") return handlePhase()
    if(!field?.ref) return moveSectionDown()
    if(_.isEqual(initialFile, file)){
      return moveSectionDown()
    }
    const payload = {
      id: clientSelected.idClient,
      data: { 
        body: { 
          [field.ref === "initialForm.questions" ? `${field.ref}.${field.id}` : field.ref]: file,
        } 
      }
    }
    blockForm()
    actions.updateClient(payload)
    .then( client => {
      const path = getPathUpdate(field.id, field.ref)
      const value = getPathClientUpdate(client, field.id, field.ref)
      actions.setClientSelected({path , value})
      moveSectionDown()
    })
    .catch(error => {
      console.log(error);
    })
  }

  const handlePhase = () => {
    const { idPhase, step } = historySelected.metadata
    if(!idPhase || !step) return 
    let multiPath = {}
    multiPath[`${step}.${field.ref}.${field.id}`] = file
    const payload = {
      id: idPhase,
      data: { 
        body: { 
          ...multiPath,
          idClient: clientSelected.idClient,
          phaseNumber: clientSelected.phases[idPhase].phaseNumber
        } 
      }
    }
    blockForm()
    actions.updatePhase(payload)
    .then( client => {
      const path = getPathUpdate(field.id, field.ref)
      const value = getPathClientUpdate(client, field.id, field.ref)
      actions.setClientSelected({path , value})
      moveSectionDown()
    })
    .catch(error => {
      console.log(error);
    })
  }

  const deleteFile = () => {
    setFile(null)
  }

  return(
    <div className={`fileupload-page ${field?.layout?.pageContent?.className ?? ''}`}>

      { field?.attachment?.type === "video" &&
        <div className={field?.layout?.attachment?.properties?.className || ''}>
          <video playsinline autoPlay="autoplay" muted loop={true}>
            <source src={field.attachment.src} type="video/mp4"/>
          </video>
        </div>
      }

      <div className="content-fileupload">{renderHTML(replaceFields(clientSelected, field?.title ?? ''))}</div>

      {field?.properties?.description && <div className="description-question">{renderHTML(replaceFields(clientSelected, field.properties.description))}</div>}
      <div className={`container-file-upload ${file ? 'container-file-complete' : ''} ${!file?.type?.startsWith("image/") ? 'file-no-img' : ''}`}>
        {(!file || !isFile(file)) && 
          <div className="file-upload-app" htmlFor={`fileupload-${field.id}`}>
            <div className="distribute-fileupload">
                <div className="cloud-upload">
                  <img src={cloud} alt="cloud"/>
                  <div className="cloud-icon-arrow">
                    <div className="arrow-wrapper">
                      <img src={arrowUpload} alt="arrowUpload"/>
                      <img src={arrowUpload} alt="arrowUpload"/>
                    </div>
                  </div>
                </div>
                <div aria-hidden="true">
                  <div color="#F1ECE2" fontWeight="bold" className="name-file-form">
                    <strong>Elige el archivo</strong> 
                  </div>
                </div>
                <div color="#F1ECE2" aria-hidden="true" className="limit-file">Límite de tamaño: 10MB</div>
            </div>
            <input id={`fileupload-${field.id}`} type="file" onChange={e => handleFile(e.target.files?.[0] ?? null)}/>
          </div>
        }
        {file && isFile(file) && 
          <div className="file-upload-app" >
            <div className="container-doc-upload">
              {file.type.startsWith("image/") ?
                <img className="fit-image-form" alt="fit form" src={file.data.includes('/uploads/files') ? `${SERVER_URL}${file.data}` : convertDataFile(file)} />
                :
                <img src={docUpload} alt="upload document"/>
              }
            </div>
            <div className="title-file-upload">
              <div className="name-file">{file?.name ?? "Sin nombre"}</div>
              <div className="delete-file" onClick={() => deleteFile()}><img src={crossUpload}  alt="upload cross"/></div>
            </div>
          </div>
        }
      </div>
      <NextButton onClick={handleClickNext} visibility={true} buttonText={field?.properties?.button_text} hideMarks={field?.properties?.hide_marks}/>
    </div>
  )
}


const mapStateToProps = ({clients, history}) => ({
  clientSelected: clients.clientSelected,
  historySelected: history.historySelected
});
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      updateClient,
      setClientSelected,
      updatePhase
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(FileUpload);