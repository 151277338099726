import React, { useState, useEffect, useRef } from 'react'
import { connect } from "react-redux";
import { deleteTags } from '../../utils/functions'
import { ClearRounded, KeyboardArrowRightRounded, ChevronLeftRounded, DoneRounded, SendRounded } from '@material-ui/icons'
import { Button } from '@material-ui/core';

import renderHTML from 'react-render-html';

import moment from 'moment'
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { updateHistory } from '../../redux/actions/historyActions'
import { setClientSelected } from '../../redux/actions/clientsActions'
import { reloadHistories, setHistorySelected } from '../../redux/actions/historyActions'
import styled from 'styled-components'

const Chat = ({ onClose, active = false, actions, histories}) => {

  const [showOptions, setShowOptions] = useState(false)
  const [historySelected, setHistorySelected] = useState(false)
  const [redirectTo, setRedirectTo] = useState(false)
  const [visibleChatInput, setVisibleChatInput] = useState(false)
  const [textResponse, setTextResponse] = useState("")
  const inputRef = useRef()

  useEffect(() => {
    if(visibleChatInput){
      inputRef.current.focus()
    }
  }, [visibleChatInput])

  useEffect(() => {
    if(active){
      document.getElementById("root").style.overflow = "hidden";
      setTimeout(()=>setShowOptions(true), 200)
    }else{
      document.getElementById("root").style.overflow = "auto";
    }
  }, [active]);

  useEffect(() => {
    scrollToBottom()
  }, [historySelected])
  
  useEffect(() => {
    if(historySelected){
      handleHistory(histories.find(history => history.idHistory === historySelected.idHistory))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [histories])
  
  const scrollToBottom = () => {
    //const elem = $("#chat-container")?.[0]
    //if(elem){
      //$("#chat-container").scrollTop(elem.scrollHeight);
    //}
  }

  const onCloseOptions = () => {
    setShowOptions(false)
    setTimeout(()=>onClose(), 200)

  }

  const handleHistory = (history) => {
    //actualizar a leido los mensajes del employee
    readNotifications(history)
    setHistorySelected(history)
    actions.setHistorySelected(history)

  }
  const handleClickButton = (button, idHistory) => {
    if(button.action === "showInitialForm"){
      setRedirectTo(`/initial-form?wrongForm=true&idHistory=123456`)
    }else if(["showCheck", "showEndPhase"].includes(button.action)){
      setRedirectTo(`/check-form?idHistory=${historySelected.idHistory}`)
    }
  }

  const handleOnBlur = () => {
    if(!textResponse.trim()){
      setVisibleChatInput(false)
    }
  }

  const sendNotification = () => {
    if(!textResponse.trim()) return
    
    const payload = {
      id: historySelected.idHistory,
      data: { 
        body: { 
          message: textResponse,
          from: "CLIENT",
          to: "EMPLOYEE",
        } 
      }
    }
    actions.updateHistory(payload)
    .then(({ history }) => {
      setTextResponse("")
      actions.reloadHistories()
    })
    .catch( error => {
    })
  }

  const readNotifications = (history) => {
    const payload = {
      id: history.idHistory,
      data: { 
        body: {
          readNotifications:"EMPLOYEE"
        } 
      }
    }
    actions.updateHistory(payload)
    .then(({ history }) => {
      //actions.reloadHistories()
    })
    .catch( error => {
    })
  }

  const handleBackNotifications = () => {
    setHistorySelected(null)
    actions.reloadHistories()
  }
  const handleSeenNotification = () => {
    const payload = {
      id: historySelected.idHistory,
      data: { 
        body: {
          seenHistory:true
        } 
      }
    }
    actions.updateHistory(payload)
    .then(({ history }) => {
      handleBackNotifications()
    })
    .catch( error => {
    })
  }

  const handleFaq = () => {
    setRedirectTo('/doce-faq')
  }

  return( 
    
    redirectTo ? <Redirect to={redirectTo} /> :
    active && 

    <div className="block-panel chat-panel" onClick={onCloseOptions}>
      
      
      <div  className={`content-chat btns-download-pdf ${showOptions?'active-view':''}`} onClick={e=>e.stopPropagation()}>
        {!historySelected &&
          <>
            <div className="close-chat" onClick={onCloseOptions}><ClearRounded /></div>
            <div className="title-popup">Notificaciones</div>
            <div className="notification-list">
              {histories.map((history, index) => {
                const content = deleteTags(history?.metadata?.content)
                let mensajes = history?.messages?.filter(message => !message.seenEmployee && message.from === "EMPLOYEE")?.length
                return(
                  <div className="item-chat" key={index} onClick={() => handleHistory(history)}>
                    <div className="container-read-it">
                    { mensajes > 0 && <div className="dot-read">{mensajes}</div>}
                    </div>
                    <div className="text-item-chat">
                      <div>{history.metadata.subject}<span className="date-item-chat">{moment(history.createdAt).format('DD/MM')}</span></div>
                      <div>{content.length>100 ? content.substring(0, 100) + "..." : content }</div>
                      {/* <div>{content}</div> */}
                    </div>
                    <div className="arrow-item-chat">
                      <div><KeyboardArrowRightRounded/></div>
                    </div>
                  </div>
                )
              })

              }
            </div>
          </>
        }
        {historySelected && 
          <ContainerNotification>

            <div className="close-chat history-back" onClick={()=>handleBackNotifications()}><ChevronLeftRounded /></div>
            <div className="container-history" id="chat-container">
              
              <div className="title-history">{historySelected.metadata.subject}</div>
              <div className="content-history">{renderHTML(historySelected.metadata.content)}</div>

              {historySelected?.metadata?.button && 
                  <div className="btn-bottom-panel square-btn" onClick={()=>handleClickButton(historySelected.metadata.button)}>
                    {historySelected.metadata.button.text}
                  </div>
              }

              {historySelected?.messages && 
                <div>
                  {historySelected.messages.map((message) => {
                    return(
                      <div className={`item-container-message-${message.from}`}>
                        
                        <div className="message-chat">
                          <div>{message.message}</div>
                          {/* <div className="info-date-chat">
                            <span className="date-chat">13:54</span>
                            <span className={`${message.seen ? 'message-seen' : 'message-not-seen'}`}><DoneAllRounded /></span>
                          </div> */}
                        </div>
                      </div>
                    )
                  })}
                </div>
              }              
            </div>
            <div className="container-btn-bottom-panel" >
              {!visibleChatInput && 
                <>
                  { historySelected?.metadata?.visibleFaq &&
                    <Button onClick={handleFaq}>
                      <div className="btn-bottom-panel">FAQ</div>
                    </Button>
                  }
                  { !historySelected?.metadata?.hiddenResponse &&
                    <Button>
                      <div
                        className="btn-bottom-panel chat-btn"
                        onClick={() => setVisibleChatInput(true)}
                      >
                        Responder <SendRounded />
                      </div>
                    </Button>
                  }
                  { historySelected?.metadata?.hiddenOkButton &&
                    <Button onClick={handleSeenNotification}>
                      <div className="btn-bottom-panel">OK, leida <DoneRounded /></div>
                    </Button>
                  }
                </>
              }
              {visibleChatInput && 
                <div className="container-input-chat">
                  <input ref={inputRef} onBlur={() => handleOnBlur() } value={textResponse} onChange={e => setTextResponse(e.target.value)}/>
                  <Button onClick={() => sendNotification()}><SendRounded /></Button>
                </div>
              }
            </div>
            
          </ContainerNotification>
        }
      
      </div>
  
    
    
    
    </div>
  )
}

const mapStateToProps = ({clients, history}) => ({
  clientSelected: clients.clientSelected,
  histories: history.histories
});

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      updateHistory,
      setClientSelected,
      reloadHistories,
      setHistorySelected
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(Chat);

const ContainerNotification = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 25px) !important;
`