import React from 'react'

const FooterEntrenamiento = () => {
  return(
    <div>

    </div>
  )
}

export default FooterEntrenamiento