import React from 'react'

const GuiaDoce = () => {
  return(
    < div className="container-page-html">
      <h2>
        <strong style={{color: "#98070a", fontSize: "18pt"}}>1. Introducción al sistema DOCE y nuestros protocolos.</strong> 
      </h2>
      <div>
        ¡Ya eres parte de la familia DOCE!
      </div>
      <div>
        Gracias por estar aquí, por tu confiandiva y enhorabuena por tomar las riendas; esto en sí mismo ya es una mini victoria que deberíamos celebrar.
      </div>
      <div>
        <b>En esta pagina encontrarás</b> información relevante sobr nuestro camino juntos, en ellos verás desde protocolos a seguir para asegurar tu éxito hasta pequeñas “píldoras de información” con trucos y otras cosas que te ayudarán en tus hábitos, nutrición, entrenamiento y estilo de vida en general.
      </div>
      <div>
        Además de la Guía DOCE, donde estás ahora mismo, en la app también tendrás acceso a:
      </div>
      <div>
          <li>Tu plan de entrenamiento.</li>
          <li>Tu plan nutricional.</li>
          <li>Tu <i>Meal Planner</i> para este primer mes.</li>
      </div>
      <div>
        En el plan de entrenamiento verás que tienes las tablas de las próximas cuatro semanas al principio del PDF y esas mismas tablas “sin rellenar” al final. Esto es así para que puedas imprimir tus entrenamientos y rellenarlos semanalmente de forma que puedas llevar un seguimiento de tu progreso e ir ajustando semanalmente en función del mismo.
      </div>
      <div>
        En el plan nutricional recibirás distintas indicaciones para el día a día de estas primeras semanas y más adelante, en esta Guía DOCE,  verás la importancia de preparar tus comidas, trucos para hacerlo fácil, cómo comer fuera… 
      </div>
      <div>
        Con el <i>Meal Planner</i> te invitamos a hacer algo distinto. Queremos que, por un tiempo limitado, veas el poder que puede tener el planificar tu nutrición con intención. Vas a practicar el apuntar lo que planeas comer esa semana por adelantado para que puedas ver cómo se compara eso con tu plan y para que puedas hacer pequeños ajustes que te acerquen a tu objetivos.
      </div>
      <div>
        ¿Tienes preguntas?
      </div>
      <div>
        A veces tenemos respuestas. :)
      </div>
      <div>
        Con tu plan DOCE también tienes acceso, a través de esta app, a nuestras páginas privadas con vídeos de entrenamiento, nutrición, FAQ, motivación etc que hemos creado con la experiencia de más de 1000 clientes en los últimos años y que actualizamos de forma periódica.
      </div>
      <div>
        Por último, sabemos que esto puede ser un poco abrumador al principio. Por eso hemos creado una comunidad DOCE, nuestro grupo privado en Facebook, al que has recibido una invitación reciente.
      </div>
      <div>
        En el grupo podrás conectar con otros muchos miembros del programa que tienen tus mismas dudas, inquietudes y objetivos. Y que junto a nuestros entrenadores te apoyarán, te resolverán tus dudas y te ayudarán a mantener la motivación alta y a ser constante en los momentos duros.
      </div>
      <div>
        Es importante que cualquier duda que te surja del programa la plasmes en el muro del grupo privado, de forma que otros miembros puedan ver también la respuesta que te demos a dicha duda, o dártela ellos mismos si ya se han visto en esa situación.
      </div>
      <div>
        <b>¿Cómo funcionaremos para comunicarnos, medir el progreso y hacer ajustes cuando sea necesario?</b>
      </div>
      <div>
        Cada 2 semanas a través de la App DOCE nos enviarás tus fotos de progreso, medidas y peso (recibirás una notificación por correo y otra en la app) sólo tendrás que rellenarlo y enviárnoslo, para que uno de nuestros entrenadores pueda revisar contigo tu progreso y que ajustes debemos hacer a tu plan para que sigas avanzando camino a tus objetivos.
      </div>
      <div>
        Este proceso llevará 3 días laborables en los cuales planificaremos tu plan nutricional para las siguientes semanas. Además cada cuatro semanas te asignaremos el plan de entrenamiento nuevo que mejor encaje contigo.
      </div>
      <div>
        Si en algún momento necesitas asistencia técnica por favor no dudes en contactar con nuestro equipo a través del grupo privado de Facebook. Insistimos en que este sea el medio de comunicación para que así todas las dudas y problemas que puedan surgir queden resueltos para todos los miembros de la comunidad DOCE (¡el buscador del grupo es una mina de oro!) No obstante, si la duda no es relativa al programa y es más bien un problema técnico con la web o similar, puedes contactar con nosotros mandando un e-mail a <a href="equipo@programadoce.com">equipo@programadoce.com</a>
      </div>
      <div>
        <h2>
          <strong style={{color: "#98070a", fontSize: "18pt"}}>2. Principios nutricionales DOCE</strong> 
        </h2>
    </div>
      <div>
        En DOCE no usamos la palabra «dieta».
      </div>
      <div>
        ¿No te esperabas algo tan drástico?
      </div>
      <div>
        Lo que muchas veces no se comenta es que «dieta», del latín <i>diaeta,</i> no es más que una palabra usada para definir lo que comemos a diario o, si usamos la definición del latín; nuestra manera de vivir.
      </div>
      <div>
        Si eres un competidor de culturismo, o un actor preparándote para una película que requiere que consigas una transformación física espectacular en un corto periodo de tiempo, tendrás que hacer grandes sacrificios en los meses previos a la competición o la película y eso es más posible que requiera de grandes restricciones alimenticias.
      </div>
      <div>
        Pero si lo que buscas es cambiar tu estilo de vida y hábitos nutricionales a mejor porque quieres mejorar tu aspecto físico, tu salud, tu rendimiento deportivo (o en el gimnasio) y tu relación con la comida… Tu plan nutricional no debe estar basado en grandes restricciones que lo hagan aburrido e insoportable.
      </div>
      <div>
        Vamos a repetir esto último.
      </div>
      <div>
        <b>
        Si quieres cambiar tu estilo de vida y mejorar tus hábitos nutricionales, tu plan nutricional no debe estar basado en grandes restricciones que lo hagan aburrido e insoportable.
        </b>
      </div>
      <div>
        A pesar de lo que muchos se empeñen en decir, la comida no es sólo “combustible”. La comida es parte fundamental de nuestras relaciones sociales y laborales, obviar este hecho sería un gran error por nuestra parte.
      </div>
      <div>
        Entonces, ¿por qué no usamos la palabra dieta?
      </div>
      <div>
        Como hemos visto una dieta es, en teoría, el conjunto de alimentos que ingerimos en un periodo de 24 horas. El motivo de que no nos guste usar esa palabra es que hoy en día asociamos la palabra dieta a planes alimenticios orientados a la pérdida de peso, que en muchos casos son excesivamente bajos en calorías.
      </div>    
      <div>
        Planes que por lo general son insostenibles. Planes que nos llevan de cabeza y que nos hacen sentir en una cárcel. Planes que ni podemos, ni debemos mantener en el tiempo, ya que nos llevarán a desarrollar una relación negativa con la comida, ¿por qué no tenemos en cuenta nuestra preferencia personal a la hora de comer?
      </div>
      <div>
        Lo que te gusta comer importa, lo que la evidencia dice sobre lo que debemos y cuánto debemos comer para mejorar nuestro físico y salud también. Encontremos ese equilibrio en el que ambos se crucen, será mucho más sencillo que puedas mantenerlo para el resto de tu vida, ¿no crees?
      </div>
      <div>
        Como diría nuestro compañero Alberto: «La gente piensa que debe vivir a base de ensaladas, cuando realmente con añadir una ensalada a su día a día ya harían mucho bien que podría expandirse al resto de sus hábitos diarios.»
      </div>
      <div>
        <img className ="alignnone size-full wp-image-22592" src="https://www.programadoce.com/wp-content/uploads/2020/08/Básicos-DOCE.png" alt="" width="1080" height="820">
        </img>
      </div> 
      <div>
        Con la información que nos proporcionaste en los cuestionarios iniciales, hemos diseñado tu plan nutricional teniendo en cuenta lo que consideramos que serán tus requisitos calóricos y de macronutrientes diarios para conseguir tus objetivos en las próximas cuatro semanas.
      </div>
      <div>
        Cada dos semanas te pediremos que hagas un "check-in" con nuestro equipo para revisar tu progreso y, cuando proceda, hacer ajustes sobre tu plan nutricional y proporcionarte nuevas herramientas que te permitan llevar un mejor control de lo que comes.
      </div>
      <div>
        También irás progresando en flexibilidad para que puedas integrar tu plan cada vez mejor en un día a día que pueda incluir eventos sociales, comidas de negocios y tomarte un capricho con amigos y/o familiares… Lo dicho, aunque en principio pueda parecer algo más restrictivo, no es más que un bonito período de aprendizaje que pagará dividendos para el resto de tu vida.
      </div>
      <div>
        No es una dieta, es una educación nutricional que irás pincelando tú con tus gustos, preferencias y objetivos poco a poco.
      </div>
      <div>
       DOCE no es un plan de pérdida de grasa o ganancia muscular a corto plazo. DOCE es un plan diseñado para cambiar tu estilo de vida para mejor, mejorar tus hábitos nutricionales y mejorar tu composición corporal.
      </div>
      <div>
       Ya sé lo que estás pensando… 
      </div>
      <div>
        ¿Quiere esto decir que no voy a perder grasa y/o ganar músculo en las siguientes 12 semanas? No, no quiere decir eso, de hecho esperamos que consigas grandes resultados en las siguientes 12 semanas.
      </div>
      <div>
       En estas 12 semanas esperamos que consigas las metas que te has planteado, pero de poco serviría si luego no eres capaz de mantener los resultados conseguidos (algo que suele pasar con muchas “dietas y programas”). 
      </div>
      <div>
      Por eso queremos proporcionarte las herramientas necesarias para que puedas tener hábitos nutricionales saludables y sostenibles a largo plazo que te permitan mantener tus resultados y seguir progresando, sin que por ello dejes de “tener vida”. Creemos –y la evidencia también parece indicar– que es importante que a medio/largo plazo exista un equilibrio que te permita disfrutar tanto del gimnasio como de la vida fuera de él.
      </div>
      <div>
       No queremos que te encierres en una cueva durante los siguientes meses. Podrás ir a cenar con amigos y familiares, al cine con tus hijos, de barbacoa e incluso salir a tomar algo. 
      </div>
      <div>
        ¿Quiere esto decir que no voy a perder grasa y/o ganar músculo en las siguientes 12 semanas? No, no quiere decir eso, de hecho esperamos que consigas grandes resultados en las siguientes 12 semanas.
      </div>
      <div>
        En estas 12 semanas esperamos que consigas las metas que te has planteado, pero de poco serviría si luego no eres capaz de mantener los resultados conseguidos (algo que suele pasar con muchas “dietas y programas”).  
      </div>
      <div>
        Por eso queremos proporcionarte las herramientas necesarias para que puedas tener hábitos nutricionales saludables y sostenibles a largo plazo que te permitan mantener tus resultados y seguir progresando, sin que por ello dejes de “tener vida”. Creemos –y la evidencia también parece indicar– que es importante que a medio/largo plazo exista un equilibrio que te permita disfrutar tanto del gimnasio como de la vida fuera de él.
      </div>
      <div>
        No queremos que te encierres en una cueva durante los siguientes meses. Podrás ir a cenar con amigos y familiares, al cine con tus hijos, de barbacoa e incluso salir a tomar algo. 
      </div>
      <div>
        Si no pudieses nunca hacer ninguna de esas cosas ¿Merecería la pena tener abdominales? Nosotros creemos que no.
      </div>
      <div>
        Pero esto no quiere decir que tengas “barra libre” para comer o beber cualquier cosa. Y te estaríamos mintiendo si te dijéramos que va a ser fácil. Al principio tendrás que cambiar tus hábitos nutricionales, y es importante que sepas cómo hacerlo, sobre todo en las ocasiones que te acabamos de mencionar, ya que suele ser cuando tiramos por tierra todos nuestros esfuerzos.
      </div>
      <div>
        Para eso tendrás el apoyo de todo nuestro equipo y de los demás miembros de DOCE, que en nuestro grupo privado de Facebook te ofrecerán ayuda, consejos, mecanismos y estrategias para sobrellevar los momentos más difíciles, y por supuesto ánimo y motivación. DOCE no es sólo un programa de entrenamiento y nutrición, DOCE es una comunidad.
      </div>
      <div>
        Gracias por estar aquí y formar parte de ella. 
      </div>
      <div>
         <h2>
            <strong style={{color: "#98070a", fontSize: "18pt"}}>3. Tu día a día en DOCE</strong> 
          </h2>
      </div>
      <div>
        Tras leer en el punto anterior lo que queremos conseguir con nuestros hábitos a medio/largo plazo es normal que tengas la típica duda que todos hemos tenido en algún momento... 
      </div>
      <div>
        «¿Y cómo leches se hace eso?»
      </div>
      <div>
        Tranquilo, vamos a ver un par de puntos comunes para que te hagas una idea de cómo será el día a día de estas primeras cuatro semanas. Recuerda, empezamos con un período de aprendizaje que te dará habilidades para toda la vida, aunque puedas tener algo de experiencia y sea tentador, no te saltes esta parte. 
      </div>
      <div>
          <b>
            FRECUENCIA DE COMIDAS
          </b>
      </div>
      <div>
        Diseñaremos tu plan nutricional de forma que tengas entre tres y cuatro comidas principales con la posibilidad de añadir uno o dos snacks. De esta forma no dejaremos pasar nunca demasiado tiempo entre comidas, para intentar evitar que pases hambre y los antojos. De nuevo, esto es el principio, tenemos casos de personas que viven mejor con solo dos comidas y algún snack diario y otras con seis. No te preocupes, es parte de la experimentación y el aprendizaje, encontraremos tu frecuencia de comidas favorita a lo largo de nuestra aventura para que luego tú puedas seguir practicando. Quédate con ésto: <b>la frecuencia de comidas “perfecta” es aquella que va con tu vida y te permite conseguir tus objetivos. </b> Nada más y nada menos.
      </div>
      <div>
          <b> 
            COMIDAS
          </b>
      </div>
      <div>
       Como verás en el <i>Meal Planner</i>, donde podrás establecer un plan semanal de comidas, hemos decidido no ponerle “nombre” a las comidas (desayuno, comida, merienda o cena) sino simplemente les hemos puesto números. Hacemos esto para erradicar el concepto de que para desayunar hay que tomar cereales, tostadas, croissants o cosas similares. Las opciones disponibles para tus comidas serán las mismas, ya sea desayuno, comida o cena. De nuevo, <b>tú decides lo que te gusta comer,</b> nosotros simplemente compartimos la educación necesaria de lo que nuestro cuerpo necesita para rendir y lograr los objetivos que tienes en mente. 
      </div>
      <div>
       Verás que esto se irá haciendo más y más flexible conforme progreses en el programa y vayas cumpliendo etapas. También dependerá de tu objetivo, no necesitaremos el mismo nivel de compromiso y precisión para “simplemente estar bien” que para “competir en la próxima competición para portada de revista”. 
      </div>
      <div>
          <b> 
            CANTIDADES
          </b>
      </div>
      <div>
        A no ser que especifiquemos lo contrario, queremos que peses tus comidas siempre en seco / crudo / antes de ser cocinadas. Para la verdura lo ideal es que tomes verduras frescas, pero sabemos que a veces no es tan cómodo o económico como las verduras congeladas, si usas estas últimas la cantidad será la misma que te indiquemos en el plan.
      </div>
      <div>
        No, no tendrás que estar “pesando comida” para siempre, es simplemente una parte breve del aprendizaje.
      </div>
      <div>
           <b> 
           SUPLEMENTACIÓN INTRA Y POST-ENTRENAMIENTO
           </b>
      </div>
      <div>
        Tema que se menciona mucho por todas partes pero que puede tener menos relevancia de la que crees. Recibirás un email con todo sobre suplementos durante tu aventura en DOCE, pero por ahora quédate con que tomar algo para que haga X cosa sin estar dándole al cuerpo esa cosa a través de tu comida, movimiento y/o estilo de vida, es igual que fregar el suelo teniendo goteras.
      </div>
      <div>
        Si tienes acceso a BCAAs sería bueno que los bebieras durante el entrenamiento, no por nada “mágico”, simplemente porque están ricos y te obligarán a beber algo más de agua y porque así tenemos aminoácidos en sangre constantemente, dándole una razón menos al cuerpo para que use los que conforman nuestros músculos y otros tejidos. (¡Esto es bueno!)
      </div>
      <div>
        También tomarás proteína en polvo después de entrenar, no necesitar ir corriendo a tomarlo pero lo hemos incorporado en el plan para que lo tomes en algún momento después de tu entreno por una razón sencilla: es una forma práctica de hacer que llegues a tu requerimiento diario de proteína. Mucha gente no come suficiente proteína para los objetivos que tienen y es uno de los principales obstáculos que nos encontramos inicialmente. 
      </div>
      <div>
        Este pequeño hábito resuelve ese obstáculo de un plumazo.
      </div>
      <div>
          <b> 
          BEBIDAS
          </b>
      </div>
      <div>
        «Beberte la cena» suena a algo improbable o incluso nada apetecible, ¿verdad?
      </div>
      <div>
        Echa un vistazo a la siguiente imagen para darte una idea de la importancia de ser conscientes de lo que bebemos a diario... 
      </div>
      <div>
        <img className="alignnone size-full wp-image-22593" src="https://www.programadoce.com/wp-content/uploads/2020/08/Café-Burger.jpg" alt="" width="1032" height="894">
        </img>
      </div>
      <div>
        Ese inocente “latte” de Starbucks o tu cadena de cafés favorita puede estar dándole a tu cuerpo la misma energía que tu cena sin saciarte lo más mínimo.
      </div>
      <div>
        Impresiona, ¿verdad?
      </div>
      <div>
        Ahora piensa la cantidad de bebidas que consumes día a día por “rutina” y cuánta energía están aportando a tu nutrición.
      </div>
      <div>
        Sé crítico, ¿te están ayudando en tus objetivos?
      </div>
      <div>
        La respuesta, a no ser que seas un atleta de élite con requerimientos calóricos parecidos a Michael Phelps, es un rotundo “no”.
      </div>
      <div>
        Entonces, ¿qué puedes hacer para garantizar que bebes suficiente y que disfrutas de lo que bebes sin ponerte obstáculos en el camino?
      </div>
      <div>
        Vamos con algunos puntos esenciales para tener todo esto cubierto durante tu aventura en DOCE:
      </div>
      <div>
        <ul>
           <li>
            No existe un número mágico de litros de líquido que debas consumir, depende mucho del contexto de cada uno, donde vivas, el tiempo, tu actividad… Podríamos volvernos locos pero vamos a simplificarlo con dos cosas: bebe cuando tengas sed y sigue esta guía visual de tu orina para saber cuándo estás bien hidratado y cuando deberías beber un poco más.
          </li>
         </ul>
      </div>
      <div>
          <img className="alignnone size-full wp-image-22594" src="https://www.programadoce.com/wp-content/uploads/2020/08/Hidratación.png" alt="" width="1342" height="328" /> 
      </div>
      <div>
       Si tu orina se mantiene en colores del 1 al 4 es probable que estés bien hidratado, si la ves del 4 al 6 puede que sea buena idea que bebas un poco más que lo que estás haciendo hasta ahora.
      </div>
      <div>
        ¿Entre el 7 y 8? Puede que algún suplemento –como un multivitamínico– o ciertos alimentos tengan que ver en el color, pero es también posible que necesites beber más y/o pedir opinión médica si esos colores son habituales en tu orina.
      </div>
      <div>
         <ul>
            <li>
              Intenta que la mayoría de tu ingesta de líquidos sea agua. ¿Aburrido de su sabor? Prueba con agua con gas y bebidas sin calorías, por ejemplo. Aún así, intenta siempre tener un ratio de 2:1 de agua; bebe dos vasos de agua por cada vaso de bebida zero, sin calorías o similar. Es una forma segura de asegurar suficiente hidratación sin abusar de edulcorantes, gases, etc.
             </li>
            <li>
              ¿Te gusta el café? A nosotros en DOCE también, pero intentamos limitar el consumo a máximo 2 tazas diarias por la mañana. Si tomas un buen café es probable que no necesites endulzarlo, pero si eres de dulce, usa un edulcorante para ello; recuerda lo que vimos de las calorías en nuestras bebidas al principio de este email.
             </li>
            <li>
              Olvida los zumos, come fruta de verdad. Pedir ese “café y zumito” es un ritual muy típico en algunos países como España, Italia y otras zonas del mediterráneo pero no tienes porqué hacerlo ni aporta beneficios de salud adicionales que una naranja entera no pueda darte, por ejemplo. Vuelve a ese gráfico inicial y piensa, ¿cómo crees que estarás más saciado, con un zumo de tres naranjas o con una naranja entera disfrutada bocado a bocado?
             </li>
            <li>
              ¿Fan del alcohol? Quizás deberías empezar a pensarlo dos veces. El alcohol no solo es un compuesto que nos puede “nublar el pensamiento” y hacer que tomemos decisiones un poco “estúpidas” –¡esos kebabs a las 5 de la mañana!–, también aporta 7 calorías por cada gramo y puede hacer que con dos copas se borre nuestro progreso diario de pérdida de grasa. Veremos formas de socializar con y sin alcohol en futuros emails, pero por ahora nos gustaría que pararas y pienses cuánto alcohol bebes, por qué lo haces y si sería posible cambiar ese “sistema automático”.
             </li>
          </ul>
      </div>
      <div>
        <b>
          Este punto en pocas palabras: 
        </b>
        <p>
          tus primeras cuatro semanas serán un aprendizaje para el resto de tu vida, tus preferencias son importantes y verás cómo ir metiéndolas en tu plan poco a poco, pesa tu comida en crudo/sin cocinar, tómate algo de proteína después de entrenar pero no te vuelvas loco por ello y manténte hidratado; es esencial y la forma en que lo hagamos puede hacernos la vida más fácil con nuestros objetivos o convertirlo en un infierno del que no sepamos cómo salir.
        </p>
    </div>
      <div>
       Elige la vía fácil, párate un momento y crea una estrategia para disfrutar de tus bebidas siendo consciente de lo que aportan, deja que la mayoría de las calorías vengan de la comida, ¡están mucho más ricas!
      </div>
      <div>
        <h2>
            <strong style={{color: "#98070a", fontSize: "18pt"}}>4.Preparación de comidas</strong> 
          </h2>
      </div>
      <div>
        ¿Una sección entera dedicada a cómo preparar tus comidas? Por supuesto
      </div>
      <div>
        No prepararse adecuadamente es el equivalente a prepararse para fallar.
      </div>
      <div>
        La experiencia nos dice que el error más común en aquellos que no consiguen sus objetivos es que no se preparan y planifican de antemano, tanto los entrenamientos como la nutrición.  
      </div>
      <div>
        Sí, la nutrición también. Para el gimnasio tienes un plan de entrenamiento marcado de antemano, del cual debes llevar un seguimiento detallado de tus sesiones para progresar semana a semana.
      </div>
      <div>
        Para progresar siguiendo un plan nutricional debes planificar cada día (o incluso cada semana) con antelación y prepararte bien. Si no te prepararas, improvisarás. Y si improvisas, durante esta fase inicial de aprendizaje, acabarás fallando.
      </div>
      <div>
        Lo hemos visto cientos de veces. 
      </div>
      <div>
        Hemos creado esta mini guía para que esto no te ocurra a ti y en unas semanas seas un pro de la planificación de comidas y así luego puedas improvisar y “vivir” de forma más flexible sin problema.
      </div>
      <div>
        <b>
          PLANIFICA TODA LA SEMANA DE ANTEMANO
          </b>
      </div>
      <div>
        La mejor forma de hacer esto es escribir en un papel todas tus comidas de la semana, ¿piensas comer algo el martes por la tarde? Escríbelo. ¿Es eso lo mismo que el miércoles por la tarde? Genial, escríbelo también; estamos en fase de aprendizaje, ¿recuerdas?
      </div>
      <div>
        Para esto, hemos creado un <i>Meal Planner</i> semanal que consiste en un calendario con toda la semana en el que podrás apuntar lo que vas a comer cada día. Imprime el <i>Meal Planner</i>  semanal, escribe ahí todas tus comidas de la semana, prepáralas de antemano y una vez preparadas mételas en la nevera o el congelador.
      </div>
      <div>
        Nuestro compañero <a href="https://themacrowizard.com/soyalberto/">Alberto</a> perdió 65 kilos hace unos años y tras aprender sobre nutrición, entrenamiento y otros pilares importantes en todo esto de cambiar nuestro cuerpo, decidió escribir un libro dedicado íntegramente a cocinar.
      </div>
      <div>
        ¡Le dió tanta importancia que dedicó un par de años de su vida a sacarlo y lo tiene como herramienta principal para todo aquel que empieza en este camino!
      </div>
      <div>
        Al formar parte de la familia DOCE puedes acceder al libro aquí por una fracción de su precio, te aseguramos que te ayudará inmensamente en el camino de comprar, preparar y almacenar tus comidas… así como en la aventura de comer fuera después de hacer todo esto.
      </div>
      <div>
        Tienes más información sobre el libro <a href="https://themacrowizard.com/cocina/">aquí</a>, usa el código DOCE para desbloquear tu precio especial.
      </div>
      <div>
        Escribe tu plan semanal de comidas en tu <i>Meal Planner</i> antes de hacer la compra de esa semana, de forma que sepas exactamente qué comidas e ingredientes vas a necesitar en su preparación y así hacer la compra de eso y nada más.
      </div>
      <div>
        Lo que nos lleva al siguiente punto.
      </div>
      <div>
        <b>
          HAZ UNA LISTA DE LA COMPRA… ¡Y SÍGUELA SIN DESVIARTE!
        </b>
      </div>
      <div>
        Escribe en tu lista de la compra todos los ingredientes y comidas que necesitas para esa semana y en qué cantidades. 
      </div>
      <div>
        Ve a un supermercado con el que ya estés familiarizado de forma que no tardes mucho en hacer la compra, no te entretengas en zonas del supermercado en la que no debas estar y sobre todo nunca vayas a hacer la compra con hambre, ¡intenta ir siempre con el estómago lleno!
      </div>
      <div>
        Hacer la compra con el estómago vacío es una receta para el fracaso, si tienes hambre comerás y comprarás con los ojos y el estómago en lugar de con la cabeza (y con tu lista). 
      </div>
      <div>
        Si vas a hacer la compra con hambre te aseguro que comprarás cosas que no debas comprar y si en casa tienes tentaciones sucumbirás a ellas cuando tengas hambre o por puro aburrimiento. Nuestro entorno es muy poderoso.
      </div>
      <div>
        También tienes ejemplos de lista de la compra, nevera y despensa en el libro que comentamos de Alberto, <a href="https://themacrowizard.com/cocina/">“Un Mago en la Cocina”.</a>
      </div>
      <div>
        Si decides hacerte con él, no olvides usar el código DOCE y escribirle con lo que te gusta y lo que no del libro, dile que te mandamos nosotros. ;) 
      </div>
      <div>
        <b>
         PREPARA TODAS TUS COMIDAS DE LA SEMANA EN UNA SÓLA VEZ  (O DOS)
          </b>
      </div>
      <div>
        Elige un día (o dos) a la semana en los que cocinarás todas tus comidas para los siguientes días.
      </div>
      <div>
        De esta forma te llevará varias horas en una o dos tandas y no tendrás que preocuparte más el resto de la semana. Esto no sólo te ahorrará tiempo a lo largo de la semana, sino que además evitará que surjan tentaciones a lo largo de la semana por pereza de cocinar.
      </div>
      <div>
        No me digas que no te suenan esas llamadas a la pizzería del barrio al llegar a casa después de trabajar reventado… ¡Todos hemos pasado por ello!
      </div>
      <div>
        Te recomendamos que compres tupperwares y bolsas de plástico en las que meter tus comidas en el congelador. Todas las noches saca del congelador las comidas del día siguiente y déjalas en la nevera para que se descongelen de forma que al día siguiente sólo tardes 5 minutos en preparar tu comida.
      </div>
      <div>
        Recuerda, hacerlo fácil es clave para que lo hagas de forma semi automática.
      </div>
      <div>
        No dejes ninguna comida en la nevera más de 2-3 días ni vuelvas a congelar comida que ya hayas descongelado.
      </div>
      <div>
        <b>
          COCINA EN GRANDES CANTIDADES
          </b>
      </div>
      <div>
        Esto resulta bastante obvio si vas a cocinar sólo 1-2 veces por semana ya que te ahorrará muchísimo tiempo. Por ejemplo, puedes cocinar todo el pollo y brócoli de la semana al mismo tiempo y luego dividirlo en porciones en función de lo que hayas escrito tu <i>Meal Planner.</i>
      </div>
      <div>
        <b>
          MIDE BIEN TUS PORCIONES
          </b>
      </div>
      <div>
        Algunas comidas son muy altas en calorías y pueden crear con facilidad un superávit calórico si no medimos bien nuestras porciones. 
      </div>
      <div>
        ¿Alguna vez alguien te ha dicho eso de «la comida sana no engorda»? Piénsalo.
      </div>
      <div>
        Si ingieres más calorías de las que tu cuerpo consume en sus quehaceres diarios ganarás peso, por muy sano que comas, es así de simple.
      </div>
      <div>
        Echa un ojo al siguiente gráfico para ver un ejemplo de lo poco que se necesita para añadir cientos de calorías sin darnos cuenta.
      </div>
      <div>
          <img className="alignnone size-full wp-image-22595" src="https://www.programadoce.com/wp-content/uploads/2020/08/Porciones-Tostada.jpg" alt="" width="1358" height="970" />
      </div>
      <div>
        Los frutos secos (y sus cremas) son muy fáciles de comer en exceso, sobre todo si medimos “a ojo” en lugar de pesarlos. Y aunque son muy nutritivos, también son muy altos en calorías y debemos tenerlo en cuenta. Volvemos al aprendizaje que tanto nos gusta mencionar, intenta aprender cómo se ve una ración que mencionamos en tu plan nutricional para que luego tengas esa habilidad en tu mente.
      </div>
      <div>
        Te servirá tanto a la hora de cocinar como al salir fuera a comer.
      </div>
      <div>
        Asegúrate de medir bien las porciones antes de meter la comida en los tupperwares de forma que luego no tengas que preocuparte a lo largo de la semana. En el caso de los frutos secos usa pequeños recipientes de plástico o pequeños tarritos de cristal en los que metas las porciones del día, si comes directamente de la bolsa lo más probable es que te excedas.
      </div>
      <div>
          <i>
            Pro tip:
          </i>
          <p>
            cuando sigues un plan nutricional de pérdida de grasa es conveniente que elijas tupperwares y platos pequeños para comer ya que de esa forma potencialmente engañaras a tu cerebro y creerá que está comiendo más al ver el plato “rebosante”. 
          </p>
      </div>
      <div>
        Planear y preparar tus comidas de antemano es la clave del éxito, así que deja de lado de las excusas, póntelo lo más fácil posible y ¡empieza hoy!
      </div>
      <div>
        <h2>
            <strong style={{color: "#98070a", fontSize: "18pt"}}>5. Cómo la preparación de comidas te convierte en un “pro” comiendo fuera (“breve”, el artículo tocho sobre esto está en la semana 10 de las DOCE semanas, DOCE emails: “¿Cómo puedo flexibilizar mi dieta? Estrategias para comer fuera como un “pro”.”)</strong> 
          </h2>
      </div>
      <div>
        ¿Recuerdas cómo te hablamos de cómo cocinar y comer en casa te iba a convertir en un <i>pro</i> a la hora de salir a comer fuera? 
      </div>
      <div>
        Este punto trata todo sobre ello, vamos al lío.
      </div>
      <div>
        Comer fuera siempre puede ser un reto cuando estás empezando en esto de “intentar comer algo mejor” y perseguir tus objetivos. En casa simplemente tienes que seguir tu plan nutricional e ir paso a paso:
      </div>
      <div>
        “Peso 150 gramos de pechuga de pollo, caliento la sartén, añado la pechuga, le pongo un poco de especias, añado las verduras y el resto de cosas para el plato… Boom, listo.”
      </div>
      <div>
        Lo bueno que tiene el hacer esto durante 4-6 semanas inicialmente es que podrás “memorizar” lo que son 150 gramos de pollo, sabrás que “un poquito de aceite” es realmente unos 30-40 gramos si no lo mides y verás que los postres, dulces y otras cosas densas en calorías pueden aportarte energía suficiente para toda una semana si no eres consciente de las porciones.
      </div>
      <div>
        Aplicando este aprendizaje, cuando salgamos fuera podemos usar una combinación de estrategias para “hackear el sistema” y seguir encarrilados en el objetivo.
      </div>
      <div>
        ¿Recuerdas eso de “las porciones”? Echa un ojo a este gráfico.
      </div>
      <div>
          <img className="alignnone size-full wp-image-22596" src="https://www.programadoce.com/wp-content/uploads/2020/08/Porciones.jpg" alt="" width="1452" height="1322" />
      </div>
      <div>
        Con esta guía visual puedes hacerte una idea de cómo puedes usar tus manos para estimar de forma aproximada cuánto vas a comer con lo que tienes delante en el plato.
      </div>
      <div>
        De nuevo, esto es un arte que debe ser perfeccionado poco a poco. Mientras lo haces, tus señales internas (saciedad, hambre, etc) también irán perfeccionándose. La idea es que llegue el día en el que puedas “comer de forma intuitiva” lo que necesitas y quieres, sabiendo escuchar a ese “tengo hambre” o “ya estoy saciado” que tan poco escuchamos en esta era moderna debido a una gran cantidad de factores.
      </div>
      <div>
        ¿Algo más a tener en cuenta? Por supuesto, y va en línea con lo que decimos siempre de ponértelo fácil.
      </div>
      <div>
        <ul>
          <li>
            <b> Tu prioridad debe ser la proteína.</b> Recuerda que tienes un objetivo diario y que, al ser el ingrediente más caro, los restaurantes suelen ir bastante cortos con ella. Busca el plato que te gusta, sin duda, pero si eliges una pizza de queso (básicamente todo grasa y carbohidratos) tendrás que empezar a pensar en dónde está la proteína ahí y en el resto de tu día. Irás aprendiendo todo esto con las semanas, pero para empezar, piensa en buscar platos con una fuente de proteína como actor principal antes de graduarte a lasañas, pizzas y otras cosas más elaboradas.
            </li>
          <li>
            <b> Al restaurante le importa lo más mínimo tus “macros” y objetivos, </b> lo que quiere es que vuelvas y hables de la experiencia con los tuyos. Asume que habrá más aceite de lo que estimas en todo y sé consciente de que el tamaño de las porciones puede que no sea “lo que necesitas”. En otras palabras, si estás intentando perder grasa, sobreestima lo que calculas de tu comida y no tengas miedo a dejar comida en el plato; no eres una basura de residuos orgánicos.
            </li>
          <li>
            <b>
              Si algo viene con salsas, pídelas por separado y así podrás ajustar lo que te pones.
              </b>
            </li>
          <li>
              <b>
                Simplifica tus elecciones antes de meterte en “lo complejo”.
               </b>
              <p>
                Intentar adivinar lo que lleva la tortilla de patata de tu sitio favorito durante la primera semana de tu plan puede no ser la mejor de las ideas para tu bienestar mental. Ve poco a poco, tienes que cocinar en casa para aprender cómo se preparan las cosas y así luego saber más o menos lo que tiene la comida que vas eligiendo cuando comes fuera. Es mucho más sencillo estimar un “Salmón con verduras al horno” que un “Ratatouille con gambas y gratín de patata acompañado de salsa de setas”. Paso a paso.
               </p>
            </li> 
          <li>
            <b>
              Puedes modificar tu entorno más inmediato para ponértelo más fácil.     
             </b>
            <p>
              Si vas sólo a comer fuera puedes decirle a tu camarero que no te traiga la cesta del pan si no estaba en tus planes comerlo, por ejemplo. Puedes pedir medio menú del día en lugar del menú completo del que no tienes pensado comerte el entrante, el postre ni beberte el vino. ¿Vas acompañado? Genial, regala a tus compañeros de mesa tus delicias que no tenías pensado comer, o comparte y ahorra calorías casi sin darte cuenta y sin renunciar a lo que quieres.            
              </p>
           </li>
          </ul>
      </div>
      <div>
        En la comunidad DOCE en Facebook tendrás cientos de aliados con experiencia en todo esto para darte apoyo. Recuerda que la idea no es dejar de comer fuera, estar “a dieta” o no beber nada de alcohol ni comer tiramisú el resto de tu vida… ¡La idea es hacer todas esas cosas mientras consigues tus objetivos a medio/largo plazo! 
      </div>
      <div>
        Paciencia, aprendizaje y experimentación. Todos hemos pasado por ello, te lo aseguro.
      </div>
      <div>
        <h2>
            <strong style={{color: "#98070a", fontSize: "18pt"}}>6.Guía básica de suplementación, y por qué los suplementos son el pico de la pirámide no su base.</strong> 
          </h2>
      </div>
      <div>
        Una cosa que llama mucho  la atención es la importancia que la gente le suele dar a la suplementación, a veces incluso priorizando a la propia nutrición. Esto es un sinsentido.
      </div>
      <div>
        Veamos un ejemplo gráfico:
      </div>
      <div>
          <img className="alignnone size-full wp-image-22597" src="https://www.programadoce.com/wp-content/uploads/2020/08/Suplementos.jpg" alt="" width="1302" height="1066" />
      </div>
      <div>
        La suplementación tiene su sitio en un plan nutricional, por supuesto, pero está diseñada para complementarnos en aquellas cosas en las que somos deficientes, o bien para incrementar nuestro rendimiento en el gimnasio o recuperación post-entrenamiento, una vez hayamos cubierto todos los pilares básicos de nutrición, entrenamiento, gestión del estrés, descanso, etc…
      </div>
      <div>
        Hasta aquí estamos todos de acuerdo.
      </div>
      <div>
        Ahora bien, si tu alimentación no es la adecuada, si tu sueño no es de calidad ni lo suficientemente duradero y si no entrenas duro y con intención, no hay ningún suplemento (legal) en el mercado que te vaya a proporcionar los resultados que buscas.
      </div>
      <div>
        Con esto en mente vamos a hablar de una serie de suplementos que sí pueden ser útiles para nuestro camino y tus objetivos, siempre que los básicos cubiertos en tu plan nutricional y de entrenamiento estén bien atados.
      </div>
      <div>
         <b>
         Creatina
         </b>
      </div>
      <div>
        La creatina es uno de los compuestos más investigados en los últimos años. Echando un vistazo a toda esa evidencia, podemos ver que tomar 3-5 g diarios puede tener sentido para la mayoría.
      </div>
      <div>
        Juega un papel importante en la producción de energía – especialmente en condiciones que requieran un esfuerzo mayor a ‘lo normal’, como cuando entrenamos fuerza, por ejemplo – y la encontramos en pescado y carne principalmente.
      </div>
      <div>
        Suplementar con creatina puede ayudarte a rendir un poco mejor en los entrenamientos, haciendo que sea más sencillo de tolerar un mayor volumen de entrenamiento y/o recuperarte del mismo, lo que puede resultar en un mantenimiento/aumento de tu masa muscular.
      </div>
      <div>
        También vemos evidencia bastante prometedora en el campo de fatiga mental y protección contra el daño cerebral. No existen suficientes datos como para tomarla simplemente por esto, pero yo mantendría el ojo puesto en nueva evidencia en torno a la memoria, el cerebro y la creatina.
      </div>
      <div>
        En resumen: Toma 3-5g de creatina al día en forma de polvo mezclado en suficiente agua.
      </div>
      <div>
        A tener en cuenta: Monohidrato de creatina es todo lo que necesitas. Si ves creatina de unicornio del norte, creatina pura 2000 plus o cualquier otra cosa, ten por seguro que no es más que marketing. Ojo, se han visto casos de diarrea y otras pequeñas molestias gástricas por una dosis demasiado elevada o muy poco líquido al tomarla.
      </div>
      <div>
           <b>
             Vitamina D + Vitamina K
           </b>
      </div>
      <div>
        Suficiente exposición al sol y abundantes verduras/frutas en tu dieta deberían resolver cualquier deficiencia de estas vitaminas, aún así, es interesante considerar el combo de D + K por los potenciales beneficios que vemos si miramos a la evidencia disponible.
      </div>
      <div>
        Tras examinar lo que el peso de la evidencia dice actualmente, miro con especial interés a la Vitamina K2 y su sinergia con la D y los huesos.
      </div>
      <div>
        Como siempre, dependerá de tu contexto, pero por regla general, si vives en una zona de poco sol, sales poco al exterior y/o comes pocas verduras/fruta, o simplemente te dan curiosidad esos potenciales beneficios de la sinergia entre estas vitaminas puede ser interesante.
      </div>
      <div>
        En resumen: Toma entre 1000-5000 iu de Vitamina D y unos 1500 mcg de Vitamina K2 al día para unos huesos fuertes. Al ser vitaminas que se absorben junto a la grasa, asegúrate de tomarlas con comidas o con algo que contenga un poco de grasa.
      </div>
      <div>
        A tener en cuenta: Como siempre, esto no sustituye a pasar tiempo al aire libre y exponernos al sol, al igual que tampoco te da una carta de ‘pasar de las verduras y la fruta’, es un complemento a todo eso. Si no sabes qué dosis tomar, consulta con tu médico y saca un panel de sangre antes de suplementar con nada, por mucho que ‘sepamos’ lo que dice la evidencia, tu caso es tu caso, no lo olvides.
      </div>
      <div>
          <b>
            Omega 3 
          </b>
      </div>
      <div>
        Algo parecido a la exposición solar y la vitamina D nos pasa con el omega 3 y el consumo de pescado azul, no solemos hacerlo a menudo.
      </div>
      <div>
        Si no sueles comer pescado con frecuencia y quieres asegurarte la dosis, empezar a mirar recetas con más ideas puede ser un paso. Otro puede ser el suplementar con algo de omega 3 (DHA) y (EPA).
      </div>
      <div>
        ¿Por qué? Tenemos suficiente evidencia para decir que el omega 3 afecta directamente al nivel de triglicéridos en sangre, vemos un efecto moderado de mejora en personas con hipertensión y hay datos para pensar que tiene efectos a nivel emocional.
      </div>
      <div>
        A pesar de toda esta información, todavía no vemos una relación directa en el consumo de omega 3, la reducción de triglicéridos y que esta sinergia evite casos de enfermedades cardiovasculares, por ejemplo.
      </div>
      <div>
        En resumen: Tomar entre 1 – 3 de omega 3 al día puede ser interesante, sobre todo si tu consumo de pescado es bajo por lo general.
      </div>
      <div>
        A tener en cuenta: Intenta que la combinación por cada gramo de aceite de pescado – como comúnmente lo encontrarás – sea de al menos 250 mg de EPA y DHA. Echa un ojo a la etiqueta. ¿Un gramo de aceite por cápsula? Suma a ver si el EPA y DHA dan 250mg o más.
      </div>
      <div>
          <b>
             BCAAs en polvo
          </b>
      </div>
      <div>
        Los aminoácidos ramificados o BCAA son una forma deliciosa (si eliges el sabor correcto) de tener siempre ladrillos de construcción en tu sangre y no hacer que el cuerpo se los pida a tu cuerpo mientras entrenas.
      </div>
      <div>
       No son “necesarios” ni tienen unos beneficios bestiales. Si comes suficiente proteína en el día a día (que lo harás al seguir tu plan nutricional) los BCAAs no aportan beneficios adicionales.
      </div>
      <div>
        Son interesantes a la hora de tomarlos mientras entrenas para poner más cartas a tu favor y menos a la mano de perder masa muscular. También vemos algunas personas que reportan algo de mejora en las agujetas, sobre todo inicialmente, aunque la evidencia no consigue encontrar esto con certeza y puede ser un poco de efecto placebo.
      </div>
      <div>
        En resumen: Si tienes aminoácidos a mano y/o disfrutas bebiéndolos por su sabor mientras entrenas toma unos 3-5 g mezclados con agua durante tu sesión.
      </div>
      <div>
        A tener en cuenta: El sabor es muy subjetivo, pero intenta elegir alguno con sabor a fruta o similar para que sea un disfrute el beber más líquido mientras entrenas.
      </div>
      <div>
          <b>
           Proteína de suero en polvo
          </b>
      </div>
      <div>
        Técnicamente una comida más que un suplemento (es literalmente producto de la creación del queso) pero lo ponemos aquí para comentar un poco más el porqué puede ser una interesante y práctica forma de llegar a tu proteína diaria.
      </div>
      <div>
        Se digiere más rápido que ningún otro tipo de proteína y nos ayuda a empezar rápidamente el proceso de síntesis proteica. De esta forma nuestros músculos empiezan su proceso de reparación y regeneración lo antes posible al acabar nuestro entrenamiento.
      </div>
      <div>
        De nuevo, no tienes que correr tras entrenar para tomártelo, tu cuerpo podrá usar la proteína durante las horas posteriores al entrenamiento sin problema, simplemente ponte la vida mucho más fácil tomando entre 30-50 gramos después de entrenar.
      </div>
      <div>
        En resumen: Toma 30 gramos de proteína en polvo tras tu entrenamiento si eres mujer  y 40-50 gramos si eres hombre. No es un “suplemento” ni es “malo”, es literalmente comida que ha sido preparada para hacernos la vida más fácil a la hora de consumirla.
      </div>
      <div>
        A tener en cuenta: Verás cientos de tipos de proteína de suero. Por lo general intenta ceñirte a marcas que tengan cierta reputación y que no sean excesivamente baratas, te garantizarás que recibes lo que compras. Olvídate de las diferencias entre aislado, concentrado y todo eso, compra un concentrado (el normal) de toda la vida, que tenga un sabor bueno para ti y a volar.
      </div>
      <div>
          <b>
          Glutamina
          </b>
      </div>
      <div>
        La glutamina es un aminoácido interesante. Durante años se le atribuyó un poder de ganancia muscular especial que luego se ha visto solo reflejado en personas con complicaciones médicas específicas y no en gente sana, como tú.
      </div>
      <div>
        ¿Por qué lo ponemos aquí entonces? Porque lo que sí que vemos en la evidencia son unas curiosas propiedades digestivas. En ciertas personas parece tener un efecto positivo en digestiones.
      </div>
      <div>
        Suele estar presente en cantidades suficientes en la proteína de suero, caseína y similares, pero si ves que tus digestiones podrían ser mejor, consultar con tu médico y/o especialista y darle una oportunidad a la glutamina no es mala idea. Seguimos investigando sobre su potencial ayuda al intestino permeable y otros problemas intestinales, como siempre, consulta con tu médico.
      </div>
      <div>
        Si crees que te ayudará, 10 g mezclados con agua en ayunas al despertar suele ser la dosis adecuada.
      </div>
      <div>
        En resumen: Interesante aminoácido que consigue un puesto en la lista por el potencial uso gastrointestinal. Consulta con tu especialista para descartar otros problemas que creas que se pueden resolver con la glutamina antes de nada.
      </div>
      <div>
        <h2>
            <strong style={{color: "#98070a", fontSize: "18pt"}}>7. Cómo leer las tablas de entrenamiento</strong> 
          </h2>
      </div>
      <div>
       Vamos con el último de estos 7 emails para ponerte en la casilla de salida de tu aventura en DOCE. En este veremos todo sobre cómo leer tus tablas de entrenamiento para que puedas ejecutar y llevar un seguimiento de tus ejercicios como un auténtico <i>pro</i>
      </div>
      <div>
        El orden de ejecución de los ejercicios lo determina su letra. 
      </div>
      <div>
        <ul>
          <li>
            Primero hacemos todos los ejercicios “A” con todas sus series y repeticiones. 
            </li>
          <li>
            Después todos los “B” con todas sus series y repeticiones. 
            </li>
          <li>
            Después los “C”...
            </li>
          </ul>
      </div>
      <div>
        Fácil, ¿verdad?
      </div>
      <div>
        Vamos con algunas variaciones algo más complejas, nada serio, lo prometemos.
      </div>
      <div>
        Si un ejercicio “A” tiene 3 series, harás las tres series del “A” antes de pasar al “B”.
      </div>
      <div>
        Cuando veas que en una letra hay varios ejercicios quiere decir que o bien es una super-serie, o bien una tri-serie o un circuito.
      </div>
      <div>
        Por ejemplo:
      </div>
      <div>
        A1 y A2 es una superserie, alterno entre A1 y A2 con los descansos estipulados antes de pasar a B1.
      </div>
      <div>
        A1, A2 y A3 es una tri-serie. Hago A1, luego A2, luego A3, y vuelvo a A1 hasta terminar todas las series marcadas con los descansos estipulados antes de pasar al bloque “B”.
      </div>
      <div>
        Lo mismo ocurriría cuando son más ejercicios o con cualquier otra letra que tenga varios números.
      </div>
      <div>
        En resumen: Cuando veas una letra, completa sus ejercicios, series y repeticiones antes de pasar a la siguiente.
      </div>
      <div>
        ¿Fácil? Seguimos con otra cosa que verás en tu plan de entrenamiento, el <i>tempo</i>
      </div>
      <div>
        El <i>tempo</i> es la velocidad de ejecución de cada repetición medida en segundos en 4 fases.
      </div>
      <div>
        La fase negativa es el primer número, la elongación muscular el segundo, la fase positiva el tercer número y la contracción el cuarto.
      </div>
      <div>
      Vamos con un ejemplo práctico:
      </div>
      <div>
          <ul>
            <li>
            Press de banca en 4010 es: bajamos en 4 segundos, no paramos abajo (0), subimos en 1 segundo, no paramos arriba (0).
            </li>
            <li>
            Press de banca en 3110 es: bajamos en 3 segundos, paramos un segundo abajo, subimos en un segundo, no paramos arriba.
            </li>
          </ul>
      </div>
      <div>
        Cuando el tercer número es una “X” quiere decir que subas el peso de forma explosiva (¡rápido!). 
      </div>
      <div>
        Por ejemplo: Press de banca en 31X0 es: bajamos el peso en 3 segundos, paramos 1 segundo abajo, subimos el peso explosivamente, no paramos arriba.
      </div>
      <div>
        ¿Quieres un ejemplo con otro ejercicio para comparar?
      </div>
      <div>
        Vamos a ello:
      </div>
      <div>
          <ul>
            <li>
            Jalón al pecho agarre prono en 40X0 es: subimos los brazos en 4 segundos, no paramos arriba, bajamos la barra al pecho de forma explosiva, no paramos abajo.
            </li>
          </ul>
      </div>
      <div>
        Como puedes ver, dividimos la ejecución del ejercicio en cuatro fases, así podemos determinar de forma sencilla dónde queremos hacer énfasis dependiendo del estímulo que estemos buscando. Tranquilo, todo esto te quedará claro en cuanto empieces a entrenar y le pilles ritmo, es exactamente igual que el tema de la nutrición; un aprendizaje inicial.
      </div>
      <div>
        En el apartado de peso sugerido verás la meta de la primera semana. Es importantísimo que te ajustes lo máximo posible a los pesos, series, repeticiones y descansos que verás en tu plan de entrenamiento. 
      </div>
      <div>
        En esa meta de la primera semana verás que te pongo un número y detrás “RM”, por ejemplo: “10RM”, en ese caso quiero que uses el máximo peso con el que podrías hacer correctamente 10 repeticiones (siguiendo el tempo y con la ejecución adecuada).
      </div>
      <div>
        ¿Sigues aquí? ¡Genial! Vamos con la última parte de esta guía para interpretar tus tablas de entrenamiento.
      </div>
      <div>
        Cuando veas en la tabla una cosa llamada “RPE”, estamos hablando del nivel de dificultad del 1 al 10 en base a la siguiente métrica:
      </div>
      <div>
        <ul>
          <li>
            10 = fallo muscular
          </li>
          <li>
            9 = 1 rep más hubiese sido el fallo muscular
          </li>
          <li>
            8 = 2-3 reps más hubiese sido el fallo muscular
          </li>
          <li>
            7= 4-5 reps más hubiese sido el fallo muscular
          </li>
          <li>
            6 = podrías haber hecho más de 5 reps más
          </li>
          <li>
            5 o menos = es un calentamiento
          </li>
        </ul>
      </div>
      <div>
        Habrá veces en las progresiones semanales en las que con un peso determinado puedas hacer más (o menos) repeticiones que las que te marcamos en el programa, en ese caso:
      </div>
      <div>
        Si te resulta muy fácil sube ligeramente el peso, pero recuerda que son varias series, así que a veces es mejor ser conservador, no subir el peso y ajustar en consecuencia la semana siguiente.
      </div>
      <div>
        Si no eres capaz de hacer las repeticiones marcadas:
      </div>
      <div>
          <ul>
            <li>
              Si es por 1-2 repeticiones en rangos de 1 a 10 repeticiones no pasa nada. Si son 3 o más, reduce ligeramente el peso.
            </li>
            <li>
              Si es por 2-3 repeticiones en rangos de 12 a 15 repeticiones no pasa nada. Si son más de 3, reduce ligeramente el peso.
            </li>
            <li>
            Si es por 3-4 repeticiones en rangos de 15 o más repeticiones no pasa nada. Si son 5 o más, reduce el peso.
            </li>
          </ul>
      </div>
      <div>
        Si en algún caso hubiera un ejercicio que no pudieses hacer porque el gimnasio carece del material necesario, coméntalo en el grupo privado de Facebook y mis entrenadores y yo buscaremos una alternativa para ti.
      </div>
      <div>
        ¡A POR ELLO!
      </div>
    </div>

  )
}

export default GuiaDoce