import { clientsTypes } from '../actions/clientsActions'
import { setItemLocalStorage } from '../../utils/functions'
import { panelsClients } from '../../utils/constans'
import Client from '../../models/Client'
import dotProp from 'dot-prop-immutable'
const breadcrumsInitial = { text: "Clientes" }

let initialState = {
  client: new Client(),//null,
  clientes: null,
  clientSelected: null,
  clientSelectedId: null,
  breadcrums: [breadcrumsInitial],
  panelSelected: panelsClients.LIST, //list | personalData 
}

if (typeof (Storage) !== 'undefined') {
  initialState = { 
    ...initialState, 
    //clientSelected: localStorage.getItem('clientSelected') ??  initialState.clientSelected,
    panelSelected: localStorage.getItem('panelSelectedClients') ??  initialState.panelSelected,
    clientSelectedId: localStorage.getItem('clientSelectedId') ? localStorage.getItem('clientSelectedId') :  initialState.clientSelectedId
  }
}

const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case clientsTypes.SET_PANEL_SELECTED: {
      //setItemLocalStorage('panelSelectedClients', action.payload)
      return { ...state, panelSelected: action.payload }
    }
    case clientsTypes.SET_CLIENT: {
      //setItemLocalStorage('panelSelectedClients', action.payload)
      //setItemLocalStorage('client', JSON.stringify(action.payload))
      return { ...state, client: new Client(action.payload) }
    }
    case clientsTypes.MODIFY_CLIENT: {
      const { path, value } = action.payload
      let newClient = dotProp.set(state.client, path, value) 
      return { ...state, client: new Client(newClient) }
    }
    case clientsTypes.SET_CLIENT_SELECTED_ID: {
      if(action.payload){
        setItemLocalStorage('clientSelectedId', action.payload)
      }else{
        localStorage.removeItem('clientSelectedId')
      }
      return { ...state, clientSelectedId: action.payload }
    }
    case clientsTypes.SET_CLIENT_SELECTED: {
      const { path , value } = action.payload
      const newClient = path ? dotProp.set(state.clientSelected, `${path}`, value) : value
      return { ...state, clientSelected: new Client(newClient)}
    }
    default:
      return state
  }
}

export default reducer
