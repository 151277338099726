import { combineReducers } from 'redux';

import intl from './reducers/intlReducer'
import user from './reducers/userReducer'
import crm from './reducers/crmReducers'
import dashboard from './reducers/dashboardReducers'
import clients from './reducers/clientsReducers'
import global from './reducers/globalReducer'
import templates from './reducers/templatesReducers'
import history from './reducers/historyReducer'
import feedback from './reducers/feedbackReducer'

const reducer = combineReducers({
  intl,
  user,
  crm,
  clients,
  feedback,
  global,
  dashboard,
  templates,
  history
});

export default reducer;