import React, { useState } from 'react'
import doneIcon from '../../../Images/InitialForm/doneIcon.svg'
import doneIconOther from '../../../Images/InitialForm/doneIconOther.svg'
import NextButton from '../NextButton'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { updateClient, setClientSelected } from '../../../redux/actions/clientsActions'
import { updateHistory } from '../../../redux/actions/historyActions'
import { updatePhase } from '../../../redux/actions/phasesActions'
import { getPathUpdate, getPathClientUpdate, blockForm } from '../../../utils/functions'
import { emailsIds, emailsSubjects } from '../../../utils/constans'
import History from "../../../models/History"
import { useEffect } from 'react';

const valuesYesOrNo = ["S", "N"]

const MultipleOptions = ({ initialValues = [], field, choices, yesOrNo = false, allowOtherChoice, otherChoiceInitial = "" ,multipleSelection = false, moveSectionDown, actions, clientSelected, legal = false, templates, historySelected }) => {

  const [values, setValues] = useState(initialValues)
  const [otherChoice, setOtherChoice] = useState(otherChoiceInitial)
  const [showInputOtherChoice, setShowInputOtherChoice] = useState(false)

  useEffect(() => {
    
    if (typeof values === 'string') {
      return
    }    
    if(values && values?.some(value => isNaN(value) && value.includes("otherChoice"))) {
      let othervalue = values.find(value => value.includes('otherChoice'))
      setOtherChoice(othervalue[1])
     // setShowInputOtherChoice(true)
    }
  }, [values])

  const handleChangeValues = (value) => {
    let newValues = [...values]
    if(multipleSelection){
      if(value === 'otherChoice') {
        if(!newValues.some(value => value.includes("otherChoice")) || otherChoice.trim()){
          newValues = [...newValues.filter( v => !v.includes(value))]
          newValues = [...newValues, ["otherChoice", otherChoice]]
          setValues(newValues)
        } else {
          newValues = [...newValues.filter( v => !v.includes(value))]
          setValues(newValues)
        }
      }else if(!newValues.includes(value)){
        newValues = [...newValues, value]
        setValues(newValues)
      }else{
        newValues = [...newValues.filter( v => v !== value)]
        setValues(newValues)
      }
    }else{
      if(value === 'otherChoice') {
        if(!newValues.some(value => value.includes("otherChoice")) || otherChoice.trim()){
          newValues = [["otherChoice", otherChoice]]
          setValues(newValues)
        } else {
          newValues = []
          setValues(newValues)
        }
      } else {
        newValues = [value]
        setValues(newValues)
      }
      //if(!legal) handleClickNext(newValues)
    }
    //setAllowScrolling(true)
  }
  const handleClickNext = async (values) => {
    if (field.update === "phase") return handlePhase()
    if(!field?.ref) return false
    if(values && initialValues === values && !legal){
      return moveSectionDown()
    }
    let multipathClient = {}, multiPathHistory = []
    multipathClient[field.ref === "initialForm.questions" ? `${field.ref}.${field.id}` : field.ref] = otherChoice ? values.map( value => value === "otherChoice" ? ["otherChoice", otherChoice] : value ) : values
    if(legal) {
      multipathClient['status'] = "WAITING_VALIDATION_INITIAL_FORM"
      multipathClient['initialForm.createdDate'] = "date:now";
      multiPathHistory.push(new History({
        idClient: clientSelected.idClient,
        category: `INITIAL_FORM`,
        from: "EMPLOYEE",
        to: "CLIENT",
        createdBy: "SYSTEM",
        metadata: {
          idTemplate: emailsIds.initialFormSent,
          subject: emailsSubjects.initialForm,
          content: templates?.[emailsIds.initialFormSent]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates,
          notification: true,
          hiddenResponse: true,
          sent:{
            email: clientSelected.personalData.email,
            msg: templates?.[emailsIds.nofiticationEmail]?.content.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>') ?? emailsIds.noTemplates,
          }
        }
      }))
    }
    const payload = {
      id: clientSelected.idClient,
      data: { 
        body: { 
          ...multipathClient,
          history: Object.keys(multiPathHistory).length>0 ? multiPathHistory : null,
        } 
      }
    }
    blockForm()
    actions.updateClient(payload)
    .then( async ({client}) => {
      const path = getPathUpdate(field.id, field.ref)
      const value = getPathClientUpdate(client, field.id, field.ref)
      actions.setClientSelected({path , value})
      

      if(legal && historySelected?.metadata?.deleteAfterForm){
        const payloadHistory = {
          id: historySelected.idHistory,
          data: { body: { seenHistory:true } }
        }
        await actions.updateHistory(payloadHistory)
        .then(({ history }) => {
          console.log("History", history);
        })
        .catch( error => {
          console.log(error);
          return false
        })
      }

      if(legal){
        actions.setClientSelected({path: "initialForm.createdDate" , value: client.initialForm.createdDate})
      }

      moveSectionDown()
    })
    .catch(error => {
      console.log(error);
    })
  }

  const handleChangeOtherOption = (e) => {
    e.stopPropagation()
    setShowInputOtherChoice(false)
    handleChangeValues("otherChoice")
  }

  const handlePhase = () => {
    let multiPath = []
    multiPath[`check.${field.ref}`] = values[0]
    
    const payload = {
      id: clientSelected.currentPhase.idPhase,
      data: { 
        body: { 
          ...multiPath
        } 
      }
    }
    blockForm()
    actions.updatePhase(payload)
    .then( client => {
      const path = getPathUpdate(field.id, field.ref)
      const value = getPathClientUpdate(client, field.id, field.ref)
      actions.setClientSelected({path , value})
      moveSectionDown()
    })
    .catch(error => {
      console.log(error);
    })
  }

  return(
    <>
      {choices.map((choice, key, index) => {
        return(
          <div key={index} className={`container-choice ${yesOrNo || legal ? 'yes-or-no' : ''} ${values.includes(choice.id) ? 'active-choice' : ''}`} onClick={() => handleChangeValues(choice.id)}>
            <div className="letter-choice">
              <div className="container-letter-choice">
              {/* <span>{String.fromCharCode(key+1 + 64)}</span> */}
              <span>{yesOrNo ? valuesYesOrNo[key] : String.fromCharCode(key + 1 + 64)}</span>
              </div>
            </div>
            <div className="label-choice">{choice.label}</div>
            <div className={`icon-choice ${values.includes(choice.id) ? 'show-check' : ''}`}>
              <img src={doneIcon} alt="icon done"/>
            </div>
          </div>
        )
      })}
      { allowOtherChoice && 
        <div className={`container-choice ${values.some(value => value.includes("otherChoice")) && !showInputOtherChoice ? 'active-choice' : ''}`} onClick={() => setShowInputOtherChoice(true)}>
          {!showInputOtherChoice && 
            <>
              <div className="letter-choice">
                <div className="container-letter-choice">
                <span>{  String.fromCharCode(choices.length + 1 + 64)}</span>
                </div>
              </div>
              <div className="label-choice">{otherChoice.trim() ? otherChoice : 'Otro'}</div>
              <div className={`icon-choice ${values.some(value => value.includes("otherChoice")) ? 'show-check' : ''}`}>
                <img src={doneIcon} alt="icon done"/>
              </div>
            </>
          }
          {showInputOtherChoice && 
            <div className="other-choice-container" >
              <div className="input-other-choice-container">
                <input placeholder='Escribe tu respuesta' value={otherChoice} onChange={e => setOtherChoice(e.target.value)} />
              </div>
              <div className="btn-done-other-choice" onClick={ e => handleChangeOtherOption(e)}>
                <img src={doneIconOther} alt="icon done"/>
              </div>
            </div>
          }
          
        </div>
      }
      {!legal && !showInputOtherChoice && <NextButton onClick={() => handleClickNext(values)} visibility={values.length>0} buttonText={field?.properties?.button_text} hideMarks={field?.properties?.hide_marks}/>}
      {legal && values.length>0 && <NextButton onClick={() => handleClickNext(values)} visibility={values.length>0} buttonText={field?.properties?.button_text} hideMarks={field?.properties?.hide_marks}/>}
    </>
  )
}

const mapStateToProps = ({clients, templates, history}) => ({
  clientSelected: clients.clientSelected,
  templates: templates.templates,
  historySelected: history.historySelected

});
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      updateClient,
      setClientSelected,
      updateHistory,
      updatePhase,
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(MultipleOptions);