const ethosClient = require('doce-client').createClient()
export const historyTypes = {
	FORCE_GET_HISTORIES: 'FORCE_GET_HISTORIES',
	SET_HISTORIES: 'SET_HISTORIES',
	SET_HISTORY_SELECTED: 'SET_HISTORY_SELECTED',
}

export const updateHistory = ({ id, data }) => async (dispatch) => {
	try {
		const { body } = await ethosClient.updateHistory({ id, data })
		return Promise.resolve(body)
	} catch (error) {
		console.log(error)
	}
}

export const getHistories = ({ id, data }) => async (dispatch) => {
	try {
		const { body } = await ethosClient.getHistories({ id, data })
		const { histories } = body
		dispatch(setHistories(histories))
		return body
	} catch (error) {}
}

export const setHistories = (payload) => ({
	type: historyTypes.SET_HISTORIES,
	payload,
})
export const reloadHistories = () => ({
	type: historyTypes.FORCE_GET_HISTORIES,
})

export const setHistorySelected = (payload) => ({
	type: historyTypes.SET_HISTORY_SELECTED,
	payload,
})
