import jp from 'jsonpath'
import { initialForm } from '../utils/initialForm'
import { SERVER_URL } from '../utils/constans'

import $ from "jquery"
import axios from 'axios'
export const setItemLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
}

export const converFileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve({ 
    name: file.name, 
    lastModified: file.lastModified, 
    size: file.size, 
    type: file.type, 
    data: reader.result.replace(`data:${file.type};base64,`, '')
  });
  reader.onerror = error => reject({ error });
})

export const convertFileToBuffer = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file)
  reader.onload = () => resolve({
    name: file.name, 
    lastModified: file.lastModified, 
    size: file.size, 
    type: file.type, 
    data: toBuffer(new Uint8Array(reader.result))
  })
}) 

export const toBuffer = arrayBuffer => {
  var buffer = Buffer.alloc(arrayBuffer.byteLength)
  var view = new Uint8Array(arrayBuffer)
  for (var i = 0; i < buffer.length; ++i) {
    buffer[i] = view[i]
  }
  return buffer
}

export const getPathUpdate   = (id, ref) => {
  return ref === "initialForm.questions" ? ref + "."+id : ref
}

export const getPathClientUpdate = (client, id, ref) => {
  return jp.query(client, `$..${getPathUpdate(id, ref)}`)?.[0] ?? null
}

export const replaceFields = (client, value) => {
  let result = value
  if(result.includes("{{personalData.nombre}}")){
    result = result.replace(/{{personalData.nombre}}/g, client?.personalData?.nombre ?? '________');
  }
  return result
}

export const getDataInitialForm = ({ id, value, client }) => {
  if(client){
    const ids = jp.query(client, `$..questions["${id}"]`)[0]
    if(Array.isArray(ids)){
      let answer = ""
      ids.forEach( id => {
        answer += jp.query(initialForm, `$..fields..[?(@.id=="${id}")]`)[0].label + ', '
      })
      answer += ";"
      answer = answer.replace(', ;', "")
      return answer
    }else{
      return ids
    }
  }
  return jp.query(initialForm, `$..fields[?(@.id=="${id}")]`)?.[0]?.[value]?.replace(/<\/?[^>]+(>|$)/g, "")
}
export const convertDataFile = file => file ? `data:${file.type};base64,${file.data}` : null

export const createFile = (attachment) => {
  if(!attachment?.data || !attachment?.type) return false
  const byteCharacters = atob(attachment.data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob( [byteArray], { type: attachment.type });
  return file
}

export const generateFile = async (attachment) => {
  const url = `${SERVER_URL}${attachment.data}`
  console.log(url);
  try {
    const { data } = await axios.get(url, {responseType: 'blob'})
    console.log(data);
    return data
  } catch (error) {
    console.log(error);
    return 
  }
  
}

export const deleteTags = (html = "") => html.replace(/<[^>]*>/g, ' ')

export const downloadFile = async ({file, filename}) => {
  const fileBase64 = await converFileToBase64(file)
  var elem = window.document.createElement('a');
  elem.href = `data:${fileBase64.type};base64,${fileBase64.data}`;
  elem.download = filename;        
  document.body.appendChild(elem);
  elem.click();        
  document.body.removeChild(elem);
}

export const blockForm = () => {
  $(".block-form").css('display', 'block');
}
export const unblockForm = () => {
  $(".block-form").css('display', 'none');
}

export const scrollNextPage = () => {
  blockForm()
  $(".content-form-scroll").css('overflow', 'auto');
  //$("#fullpage").find(".page-form:").hide()

  let currentPage = $("#fullpage > .page-form.current-page")[0]
  if(!currentPage) currentPage = $("#fullpage > .page-form:first-child").addClass("current-page")[0]
  let nextPage = $(currentPage).nextAll(".page-form").not(".hidde-question").first()
  $(nextPage).show()
  document.querySelector(`#fullpage > div#${$(nextPage).attr('id')}`).scrollIntoView({ behavior: 'smooth', block: 'start'})

  $(nextPage).addClass("current-page")
  $(currentPage).removeClass("current-page")
  $(".content-form-scroll").css('overflow', 'hidden');
  setTimeout(()=>{
    //$(nextPage).find("~ .page-form").hide()
    $("#fullpage > .page-form:not(.current-page)").hide()
  }, 1000)

  unblockForm()
}

export const isFile = (attachment) => {
  if(!attachment?.data || !attachment?.type) return false
  return true
}
export const topFunction = () => {
  //document.body.scrollTop = 0;
  //document.documentElement.scrollTop = 0;
  $("html, body").animate({ scrollTop: 0 }, 0)
}

export const openPdfApp = url => {
  var elem = window.document.createElement('a');
  elem.href = '/view-pdf?url='+url  
  document.body.appendChild(elem);
  elem.click();        
  document.body.removeChild(elem);
}