import React, { useState } from 'react'
//import { useLocation } from 'react-router-dom';
import NextButton from '../NextButton'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { updateClient, setClientSelected } from '../../../redux/actions/clientsActions'
import { updatePhase } from '../../../redux/actions/phasesActions' 
import { getPathUpdate, getPathClientUpdate, blockForm } from '../../../utils/functions'

const Input = ({ field, type, hideInput = false, initialValue, setAllowScrolling, moveSectionDown, actions, clientSelected, historySelected}) => {
  //let location = useLocation();
  const [value, setValue] = useState(initialValue)
  //const [blurInput, setBlurInput] = useState(null)

  const handleClickNext = () => {
    if(field.update === "phase") return handlePhase()
    if(!field?.ref) return moveSectionDown()
    if(type === "input" && value.trim() && initialValue === value){
      return moveSectionDown()
    }
    const payload = {
      id: clientSelected.idClient,
      data: { 
        body: { 
          [field.ref === "initialForm.questions" ? `${field.ref}.${field.id}` : field.ref]: type === "number" ? +value : value,
        } 
      }
    }
    blockForm()
    actions.updateClient(payload)
    .then( client => {
      const path = getPathUpdate(field.id, field.ref)
      const value = getPathClientUpdate(client, field.id, field.ref)
      actions.setClientSelected({path , value})
      moveSectionDown()
    })
    .catch(error => {
      console.log(error);
    })
  }
  const handlePhase = () => {

    const { idPhase, step } = historySelected.metadata
    if(!idPhase || !step) return 
    let multiPath = {}
    multiPath[`${step}.${field.ref}`] = type === "number" ? +value : value
    const payload = {
      id: idPhase,
      data: { 
        body: { 
          ...multiPath
        } 
      }
    }
    
    blockForm()

    actions.updatePhase(payload)
    .then( client => {
      const path = getPathUpdate(field.id, field.ref)
      const value = getPathClientUpdate(client, field.id, field.ref)
      actions.setClientSelected({path , value})
      moveSectionDown()
    })
    .catch(error => {
      console.log(error);
    })
  }

  const handleChangeValue = (value) => {
    setValue(value)
    setAllowScrolling(true)
  }

  // useEffect(() => {
  //   if(blurInput && blurInput?.id){
  //     console.log("blurInput", blurInput)

  //     document.querySelector(`#fullpage > div#${blurInput.id}`).scrollIntoView({ block: 'start'})
  //   }

  // }, [blurInput])

  // const handleOnBlurInput = (e) => {
  //   const pageForm = $(e.target).closest(".page-form")[0]
  //   setBlurInput({ id:$(pageForm).attr('id') , key: new Date()})
  // }



  return(
    <>
      {!hideInput && <input type={type} value={value} placeholder="Escribe aqui tu respuesta" onChange={ e => handleChangeValue(e.target.value)} />}
      <NextButton onClick={handleClickNext} visibility={Boolean(value) || hideInput} buttonText={field?.properties?.button_text} hideMarks={field?.properties?.hide_marks}/>
    </>
  )
}

const mapStateToProps = ({clients, history}) => ({
  clientSelected: clients.clientSelected,
  historySelected: history.historySelected
});
const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      updateClient,
      setClientSelected,
      updatePhase
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(Input);