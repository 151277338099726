import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from './components/Layout/Layout';

import Forbidden from './components/Forbidden/Forbidden'
import NotFound from './components/NotFound/NotFound'
import Oauth2 from './components/Shared/Oauth2'
import { ALL } from './utils/roleList'
import Home from './components/Home/Home';
import CheckForm from './components/Forms/CheckForm'
import FilterClient from './components/FilterClient/FilterClient'
import InitialForm from './components/InitialForm/InitialForm'
import OldClientsForm from './components/Forms/OldClientsForm'
import Test from './components/Shared/Test'

import SignIn from './components/SignIn/SignIn'
import Register from './components/Register/Register'
import ResetPassword from './components/ResetPassword/ResetPassword'
import ResetPasswordSecond from './components/ResetPasswordSecond/ResetPasswordSecond'
import PageHtml from './components/Shared/PageHtml'

import Training from './components/Traning/Training';
import GuiaDoce from './components/PagesHtml/GuiaDoce'
import ProgramaDoce from './components/PagesHtml/ProgramaDoce'
import FaqDoce from './components/PagesHtml/FaqDoce'
import Contact from './components/PagesHtml/Contact'
import ViewPdf from './components/ViewPdf/ViewPdf'

const Routes = () => {
  return(
    <Router>
      <Layout>
        <Switch>

          <Route exact path="/signin" component={SignIn} access={ALL} />
          <Route exact path="/register" component={Register} access={ALL} />
          <Route exact path="/reset" component={ResetPasswordSecond} access={ALL} />
          <Route exact path="/reset-password" component={ResetPassword} access={ALL} />
          <Route exact path="/reset-password2" component={ResetPassword} access={ALL} />
          <Route exact path="/filter-client" component={FilterClient} access={ALL} />
          <Route exact path="/old-clients-form" component={OldClientsForm} access={ALL} />
          <Route exact path="/initial-form" component={InitialForm} access={ALL} />
          <Route exact path="/test" component={Test} access={ALL} />
          <Route exact path="/training-videos" component={Training} access={ALL} />
          <Route exact path="/check-form" component={CheckForm} access={ALL} />
          <Route exact path="/protocolo-doce"><PageHtml component={ProgramaDoce}></PageHtml></Route>
          <Route exact path="/contact"><PageHtml component={Contact}></PageHtml></Route>
          <Route exact path="/guia-doce"><PageHtml component={GuiaDoce} /></Route>
          <Route exact path="/doce-faq"><PageHtml component={FaqDoce} /></Route>
          <Route exact path="/view-pdf" component={ViewPdf} access={ALL}/>
          <Route exact path="/" component={Home} access={ALL} />

          <Route exact path='/oauth2/authorize' component={Oauth2} />

          <Route exact path='/403' component={Forbidden} />
          <Route exact path='*' component={NotFound} />

        </Switch>
      </Layout>
    </Router>
  )
}

export default Routes