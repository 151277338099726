import React, { useState } from 'react'
import renderHTML from 'react-render-html';
import {  ChevronLeftRounded } from '@material-ui/icons'
import { Redirect } from 'react-router-dom'

const PageHtml = ({ html, component: Component }) => {
  const [redirectTo, setRedirectTo] = useState(null)
  return(
    redirectTo ? <Redirect to={redirectTo} /> : 
    <div className="page-html-content">
      <div className="back-button" onClick={() => setRedirectTo('/')}><ChevronLeftRounded /></div>
      <div>
        {html && renderHTML(html)}
        {Component && <Component />}
      </div>
    </div>
  )
}
export default PageHtml