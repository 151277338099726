import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const { faq } = require("doce-core").constants;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const FaqDoce = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container>
      <h1>FAQ</h1>

      <FaqWrapper className={classes.root}>
        {faq.map((field, key) => (
          <Accordion
            key={key}
            expanded={expanded === `panel${key}`}
            onChange={handleChange(`panel${key}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${key}-content`}
              id={`panel${key}-header`}
            >
              <Typography>{field.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{field.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </FaqWrapper>
    </Container>
  );
};
export default FaqDoce;
const Container = styled.div`
  padding-top: 75px;
  h1 {
    color: #e26d5a;
    margin-bottom: 30px;
    font-size: 35px;
    font-weight: 500;
  }
`;
const FaqWrapper = styled.div`
  & > div {
    background: #33383f;
    .MuiAccordionSummary-content {
      color: #adadad;
    }
    .MuiAccordionDetails-root {
      p {
        font-size: 13px;
        color: #adadad;
      }
    }
    svg.MuiSvgIcon-root {
      fill: #adadad;
    }
    &.Mui-expanded {
      svg.MuiSvgIcon-root {
        fill: #e26d5a;
      }
      .MuiAccordionSummary-content {
        color: #fff;
      }
    }
  }
`;
