import React, { useState, useEffect } from 'react'
import { Tabs, Tab } from '@material-ui/core';
import { AddRounded, RefreshRounded } from '@material-ui/icons'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import styled from 'styled-components'
import { getClient, setClientSelected, getPhases, setClientSelectedId } from '../../redux/actions/clientsActions'
import { getAnalytics } from '../../redux/actions/analyticsActions'
import { getHistories, reloadHistories } from '../../redux/actions/historyActions'
import { getFeedbacks, reloadFeedbacks } from '../../redux/actions/feedbackActions'
import { setLoading } from '../../redux/actions/globalActions'
import { Redirect } from 'react-router-dom'
import slider from '../../Images/slider.jpg'
import {  SERVER_URL } from '../../utils/constans'
import { getDataInitialForm, topFunction } from '../../utils/functions'
import Plan from './Plan/Plan'
import moment from 'moment'
import "moment/locale/es"
import TodayWeightPanel from '../Shared/TodayWeightPanel';
import ChartData from './Grafica/ChartData'

moment.locale("es")
const Content = ({ clientSelectedId, clientSelected, forceGetHistories, actions }) => {
  const [requestphases, setRequestphases] = useState(false)
  const [requestHistory, setRequestHistory] = useState(false)
  const [chartData, setChartData] = useState({ labels: [], datasets: [], moreDetails: { maxY: 10 } })
  
  useEffect(() => {
    topFunction()
  }, [])
  useEffect(() => {
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSelectedId])

  useEffect(() => {
    getHistories()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSelectedId, forceGetHistories])

  useEffect(() => {
    getFeedbacks()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSelectedId, forceGetHistories])

  useEffect(() => {
    getAnalyticsClient()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSelected?.extraData?.todayWeight])


  const getData = async () => {
    try {
      if((clientSelectedId && clientSelectedId !== clientSelected?.idClient) || !requestphases || !requestHistory){
        await getDataClient()
      }
    } catch (e) {
      console.log('ble -> ', e)
    }
  }

  const getDataClient = async () => {
    actions.setLoading(true)
/*     const payload = {
      id: clientSelectedId,
      data: {
        params: {
          category: false,
          to: "CLIENT"
        }
      }
    } */
    const client = await actions.getClient({ idClient: clientSelectedId })
    if(client){
      const { phases } = await actions.getPhases({id :client.idClient })
      //const { histories } = await actions.getHistories(payload)
      setRequestphases(true)
      setRequestHistory(true)
      actions.setClientSelected({value: {...client, phases }})
      actions.setLoading(false)
    }else if(!client && clientSelectedId){
      actions.setLoading(false)
      actions.setClientSelectedId(null)
    }
  }

  const formatDateAnalytics = (date) => moment(date).format('DD/MM')


  const getAnalyticsClient = async () => {
    const response = await actions.getAnalytics({ id: clientSelectedId })
    const analytics = response.reverse()
    let dataChart = { labels: [], datasets: [], moreDetails: { maxY: 10 } }

    dataChart.datasets = [{ label: 'Peso' , data: [] }]
    analytics.forEach((item) => {
      dataChart.datasets[0].data.push({y: item.data})
      dataChart.labels.push(formatDateAnalytics(item.date))
    })

    dataChart.datasets.forEach((item, i) => {
      item.backgroundColor = "rgba(199,32,32,0.3)";
      item.borderColor = "rgba(199,32,32,1)";
      item.pointBackgroundColor = "rgba(199,32,32,1)";
      item.pointRadius = 3;
      item.lineTension = 0;
      item.borderWidth = 2;
    })

    setChartData(dataChart)
  }

  const getHistories = () => {
    if(clientSelectedId) {
      const payload = {
        id: clientSelectedId,
        data: {
          params: {
            category: false,
            to: "CLIENT"
          }
        }
      }
      actions.getHistories(payload)
    }
  }

  const getFeedbacks = () => {
    if(clientSelectedId) {
      const payload = {
        id: clientSelectedId,
        data: {
          params: {
            category: false,
            to: "CLIENT"
          }
        }
      }
      actions.getFeedbacks(payload)
    }
  }
  
  const [value, setValue] = useState(0);

  const [showTodayWeight, setShowTodayWeight] = useState(false)
/*   const [dataOptionsPanel, setDataOptionsPanel] = useState(null) */


  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue === 1){
      actions.reloadHistories()
    }
  };
/*   const TabPanel = ({children, value, index, ...other}) => (
    <div id={`simple-tabpanel-${index}`} role="tabpanel" hidden={value !== index} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <div p={3}> <div>{children}</div> </div> }
    </div>
  ) */

  const handleTodayWeight = () => {
    //if(clientSelected.extraData.todayWeight.date === moment(new Date()).format("DD-MM-YYYY"))
    setShowTodayWeight(true)
  }

  const getDataMfp = (mfpType) => {
    if(clientSelected?.currentPhase?.step === 'training'){
      let prevPhase = Object.entries(clientSelected?.phases).find(([index, prevPhase]) => prevPhase.phaseNumber === clientSelected.currentPhase.phaseNumber -1)?.[1]
      return prevPhase?.endPhase?.mfp?.[mfpType]
    } else if(clientSelected.currentPhase.step === 'endPhase'){
      return clientSelected?.phases?.[clientSelected?.currentPhase?.idPhase]?.check?.mfp?.[mfpType]
    } else {
      return clientSelected?.phases?.[clientSelected?.currentPhase?.idPhase]?.[[clientSelected?.currentPhase?.step]]?.mfp?.[mfpType]
    }
  }

  const reloadPage = () => {
    window.location.reload()
  }
  
  return(
    !clientSelectedId ? <Redirect to="/signin" /> :
    clientSelected && !clientSelected?.initialForm?.createdDate
      //(!Boolean(clientSelected?.initialForm?.createdDate) || (clientSelected?.initialForm?.createdDate && clientSelected?.initialForm?.validate !== optionsValidateInitialForm[2] )) 
      ? <Redirect to="/filter-client" /> :
    <>
      <div className="panel-content">

        <div className="content-slider">
          <IconRefresh onClick={/*getDataClient*/reloadPage}>
            <RefreshRounded />
          </IconRefresh>
          <div className="slider-home">
            <div className="item-slider">
              <img src={slider} alt="slider"/>
            </div>
          </div>  
          <div className="shadow-bottom"></div>
        </div>

        <div className="header-tabs-app">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="TU PERFIL" id={`simple-tab-${0}`} aria-controls={`simple-tabpanel-${0}`} />
            <Tab label="TU PLAN DOCE" id={`simple-tab-${1}`} aria-controls={`simple-tabpanel-${1}`}/>
          </Tabs>
        </div>


        <div className="container-tabs">
          {value===0 &&
            <div  index={0}>
              {!clientSelected &&
                <div>
                </div>
              }

              {clientSelected && 
                <>
                  <div className="profile-img">
                    {/* <div className="container-img"><img src={convertDataFile(getDataInitialForm({id: "A0r8W8s2ZHpd", client: clientSelected}))} /></div> */}
                    <div className="container-img">
                      <img src={`${SERVER_URL}${getDataInitialForm({ id: "A0r8W8s2ZHpd", client: clientSelected })?.data}` } alt="client data"/>
                    </div>

                  </div>
                  <div className="name-title-client">{clientSelected?.personalData?.nombre} {clientSelected?.personalData?.apellidos}</div>
                  
                  {getDataInitialForm({id: "oicOk66uYPBM", client: clientSelected}) && 
                    <div className="proposito-title-client">
                      <div className="title-proposito">"¿Cuál es tu objetivo y por qué es importante para ti"</div>
                      <i>
                        <span className="quote-left">&#8220;</span>
                        {getDataInitialForm({id: "oicOk66uYPBM", client: clientSelected})}
                        <span className="quote-right">&#8221;</span>
                      </i>
                    </div>
                  }

                  {getDataInitialForm({id: "nsK3lDEDwPVq", client: clientSelected}) && 
                    <div className="proposito-title-client">
                      <div className="title-proposito">"¿Qué estás dispuesto a hacer para convertir tus objetivos en realidad?"</div>
                      <i>
                        <span className="quote-left">&#8220;</span>
                        {getDataInitialForm({id: "nsK3lDEDwPVq", client: clientSelected})}
                        <span className="quote-right">&#8221;</span>
                      </i>
                    </div>
                  }
                  {getDataInitialForm({id: "nsK3lDEDwPVq2", client: clientSelected}) && 
                    <div className="proposito-title-client">
                      <div className="title-proposito">{getDataInitialForm({id: "nsK3lDEDwPVq2", value: 'title'})}</div>
                      <i>
                        <span className="quote-left">&#8220;</span>
                        {getDataInitialForm({id: "nsK3lDEDwPVq2", client: clientSelected})}
                        <span className="quote-right">&#8221;</span>
                      </i>
                    </div>
                  }

                  <div className="container-comparation-data">

                    <div className="indicator-item">
                      <div>Peso inicial</div>
                      <div>{getDataInitialForm({id: "pgcEflgaqTna", client: clientSelected}) ?? "-"} kg</div>
                      <div>{moment(clientSelected.initialForm.createdDate).format('L')}</div>
                    </div>

                    <div className="indicator-item">
                      <div>Peso en el último check</div>
                      <div>{clientSelected?.phases?.[clientSelected?.currentPhase?.idPhase]?.[clientSelected?.currentPhase?.step]?.questions?.averageCurrentWeight ?? '-'} kg</div>
                      <div>{moment(clientSelected?.phases?.[clientSelected?.currentPhase?.idPhase]?.[clientSelected?.currentPhase?.step]?.startDate).format('L') ?? ""}</div>
                    </div>

                  </div>

                  <div className="container-comparation-data">

                    <div className="indicator-item">
                      <div>Cintura inicial</div>
                      <div>{getDataInitialForm({id: "QTPnJtPGXWES", client: clientSelected}) ?? "-"} cm</div>
                      <div>{moment(clientSelected?.initialForm?.createdDate).format('L')}</div>
                    </div>

                    <div className="indicator-item">
                      <div>Cintura en el último check</div>
                      <div>{clientSelected?.phases?.[clientSelected?.currentPhase?.idPhase]?.[clientSelected?.currentPhase?.step]?.questions?.averageWaistCurrent ?? '-'} cm</div>
                      <div>{moment(clientSelected?.phases?.[clientSelected?.currentPhase?.idPhase]?.[clientSelected?.currentPhase?.step]?.startDate).format('L') ?? ""}</div>
                    </div>

                  </div>

                  {chartData?.datasets?.[0]?.data?.length > 0 && <ChartData data={chartData}/>}
                  
                  {clientSelected.isMfp === "Si" &&
                    <>
                      <div className="title-macros">Tus objetivos de macros!!</div>

                      <div className="container-comparation-data">

                        <div className="indicator-item">
                          <div>Media de Kcalorias</div>
                          <div>{getDataMfp('kcal') ?? '-'} kcal</div>
                        </div>

                        <div className="indicator-item">
                          <div>Media de Proteinas</div>
                          <div>{getDataMfp('protein') ?? '-'} gr</div>
                        </div>

                      </div>

                      <div className="container-comparation-data">

                        <div className="indicator-item">
                          <div>Media de Carbohidratos</div>
                          <div>{getDataMfp('carbohydrates') ?? '-'} gr</div>
                        </div>

                        <div className="indicator-item">
                          <div>Media de Grasas</div>
                          <div>{getDataMfp('fats') ?? '-'} gr</div>
                        </div>

                      </div>
                    </>
                  }
                  
                  
                  
                  {clientSelected.extraData.todayWeight.formatedDate !== moment().format("DD/MM/YYYY") ?
                    
                    <div className="container-comparation-data container-peso-diario">
                      <div className="indicator-item" onClick={() => handleTodayWeight()}>
                        <div className="text-indicator">Registra tu peso</div>
                        <div className="add-peso-diario">
                          <AddRounded />
                        </div>
                        <div className="text-indicator">de hoy</div>
                      </div>
                    </div>
                    :
                    <div className="container-comparation-data">
                      <div className="indicator-item">
                        <div>Peso</div>
                        <div>{clientSelected.extraData.todayWeight.weight} kg</div>
                        <div>de hoy</div>
                      </div>
                    </div>
                  }
                  
                  <TodayWeightPanel active={showTodayWeight} onClose={()=>setShowTodayWeight(false)} />

                  <div className="container-comparation-data question-data-text">
                    <div className="indicator-item">
                      <div>¿Qué crees que podría haber ido mejor en tu última fase?</div>
                      <div>{clientSelected?.phases?.[clientSelected?.currentPhase?.idPhase]?.[clientSelected?.currentPhase?.step]?.questions?.improvements ?? ""}</div>
                    </div>
                  </div>

                </>
              }
              
              <div className="version-container">v 1.0</div>
            </div>
          }

          {value===1 && <div  index={1}> 
            <Plan />
          </div>}
        </div>
      </div>
      
    </>
  )
}

const mapStateToProps = ({ clients, history, feedback }) => {
  return {
    clientSelectedId: clients.clientSelectedId,
    clientSelected: clients.clientSelected,
    forceGetHistories: history.forceGetHistories,
    forceGetFeedbacks: feedback.forceGetFeedbacks,
  }
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      getClient,
      setClientSelected,
      setClientSelectedId,
      getPhases,
      getHistories,
      getFeedbacks,
      setLoading,
      reloadHistories,
      reloadFeedbacks,
      getAnalytics
    }, dispatch)	
	}
}
export default connect(mapStateToProps, matchDispatchToProps)(Content)

const IconRefresh = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  > svg > path {
    fill: #fff;
  }
`