const ethosClient = require('doce-client').createClient()

export const feedbackTypes = {
	FORCE_GET_FEEDBACKS: 'FORCE_GET_FEEDBACKS',
	SET_FEEDBACKS: 'SET_FEEDBACKS',
	SET_FEEDBACK_SELECTED: 'SET_FEEDBACK_SELECTED',
}

export const updateFeedback = ({ id, data }) => async (dispatch) => {
	try {
		const { body } = await ethosClient.updateFeedback({ id, data })
		return Promise.resolve(body)
	} catch (error) {
		console.log(error)
	}
}

export const getFeedbacks = ({ id, data }) => async (dispatch) => {
	try {
		const { body } = await ethosClient.getFeedbacks({ id, data })
		const { feedbacks } = body

		dispatch(setFeedbacks(feedbacks))
		return body
	} catch (error) {}
}

export const setFeedbacks = (payload) => ({
	type: feedbackTypes.SET_FEEDBACKS,
	payload,
})

export const reloadFeedbacks = () => ({
	type: feedbackTypes.FORCE_GET_FEEDBACKS,
})

export const setFeedbackSelected = (payload) => ({
	type: feedbackTypes.SET_FEEDBACK_SELECTED,
	payload,
})
