const ADMIN = "ADMIN"
const ANALYST = "ANALYST"

const ALL = ["ALL"]
const ADMIN_ONLY = [ADMIN]
const ANALYST_ONLY = [ANALYST]

export {
  ADMIN,
  ANALYST,
  
  ALL,
  ADMIN_ONLY,
  ANALYST_ONLY
}