import React, { useState } from 'react'
import { buttonsHome } from '../../../utils/constans'
import OptionsPanel from '../../Shared/OptionsPanel'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setClientSelected } from '../../../redux/actions/clientsActions'
import { NotificationImportantRounded } from '@material-ui/icons'
import { Badge } from '@material-ui/core'
import NotificationPanel from '../../Shared/NotificationPanel'
import NotificationPanelFeedback from '../../Shared/NotificationPanelFeedback'
import { Redirect } from 'react-router-dom'

const Plan = ({ clientSelected, clientSelectedId, histories, feedbacks, actions }) => {

  const [showOptionsPanel, setShowOptionsPanel] = useState(false)
  const [dataOptionsPanel, setDataOptionsPanel] = useState(null)
  const [showChat, setShowChat] = useState(false)
  const [showFeedbackHistory, setShowFeedbackHistory] = useState(false)
  const [redirectTo, setRedirectTo] = useState(null)

  const handleButton = (data, click) => {
    if (click?.action==="optionsPanel") {
      setDataOptionsPanel(click.data)
      setShowOptionsPanel(true)
    } else if (click?.action === "actionFeedbackHistory") {
      setShowFeedbackHistory(true)
    } else if (click?.action === "redirectTo") {
      setRedirectTo(click?.redirectTo ?? '/')
    }
  }

  const onCloseOptions = () => {
    setShowOptionsPanel(false)
    setDataOptionsPanel(null)
  }

  return(
    redirectTo ? <Redirect to={redirectTo} /> :
    <>
      <div>
        { /*  HISTOTRY */ }
        <div className="card-btn" onClick={()=>setShowChat(true)}>
          <div className="top-container-card">
            <div className="notification-container">
              {/* <img src={Logo} /> */}
              <Badge color="secondary" variant="dot">
                <NotificationImportantRounded />
              </Badge>
            </div>
            <div className="info-card">
              <div className="title-card">Notificaciones</div>
              <div className="subtitle-card"></div>  
            </div>
          </div>
          <div className="bottom-container-card">Tienes {histories?.length ?? 0} notificaciones nuevas</div>
        </div>

        {/* <Chat /> */}
          <NotificationPanel
            active={showChat}
            onClose={() => setShowChat(false)}
          />
          <NotificationPanelFeedback
            active={showFeedbackHistory}
            onClose={() => setShowFeedbackHistory(false)}
          />

        {Object.entries(buttonsHome).map(([key, data]) => {
          return(
            <div className="card-btn" key={key} onClick={()=>handleButton(data, data.click)}>
              <div className="top-container-card">
                <div className="image-container">
                  <img src={data.img} alt="" />
                </div>
                <div className="info-card">
                  <div className="title-card">{data.titular}</div>
                  <div className="subtitle-card"></div>  
                </div>
              </div>
              <div className="bottom-container-card">{data.texto}</div>
            </div>
          )
        })}
      </div>
      <OptionsPanel active={showOptionsPanel} data={dataOptionsPanel} onClose={onCloseOptions}/>
    </>

  )
}

const mapStateToProps = ({clients, history, feedback}) => ({
  clientSelectedId: clients.clientSelectedId,
  clientSelected: clients.clientSelected,
  histories: history.histories,
  feedbacks: feedback.feedbacks,
});

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      setClientSelected
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(Plan);