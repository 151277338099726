import { VALIDATED_EMPTY } from "../utils/constans"
class Phase {

  idClient = ""
  idPhase = ""
  phaseNumber = 0

  training = {
    created: VALIDATED_EMPTY,
    createdDate: null,
    createdBy: null,
    startDate: null,
    trainingType: "",
    trainingSubType: "",

    nutritionalPlans: { 
      nutritionalPlanTraining: { 
        name: "",
        lastModified: 0,
        size: 0,
        type: "",
        data: null
      },
      nutritionalPlanRest: { 
        name: "",
        lastModified: 0,
        size: 0,
        type: "",
        data: null
      }
     },
  }

  check = {

    created: null,
    createdDate: null,
    createdBy: null,

    validated: null,
    wrongValidation: '',
    validatedDate: null,
    validatedBy: null,

    startDate: null,


    mfp: {
      kcal: '',
      protein: '',
      carbohydrates: '',
      fats: ''
    },


    questions: {
      averageCurrentWeight: "",
      averageWaistCurrent: "",
      personalBest: "",
      neat: "",
      kcal: '',
      protein: '',
      carbohydrates: '',
      fats: '',
      exerciseWaistPainful: "",
      levelAdherence: "",
      whyThatNote: "",
      whatDidWell: "",
      improvements: "",
      tweet: "",
      miniVictory: "",
      priority: "",
      notes: "",
      macrosQuality: "",
    },
    measurements : {
      neck: "",
      chest: "",
      rightArm: "",
      leftArm: "",
      waist1: "",
      waist2: "",
      waist3: "",
      hip: "",
      rightLeg: "",
      leftLeg: "",
      rightTwin: "",
      leftTwin: ""
    }
  }

  endPhase = {

    created: null,
    createdDate: null,
    createdBy: null,

    validated: null,
    validatedDate: null,
    validatedBy: null,

    startDate: null,


    mfp: {
      kcal: '',
      protein: '',
      carbohydrates: '',
      fats: ''
    },

    questions: {
      averageCurrentWeight: "",
      averageWaistCurrent: "",
      personalBest: "",
      neat: "",
      kcal: '',
      protein: '',
      carbohydrates: '',
      fats: '',
      exerciseWaistPainful: "",
      levelAdherence: "",
      whyThatNote: "",
      whatDidWell: "",
      improvements: "",
      tweet: "",
      miniVictory: "",
      priority: "",
      notes: "",
      macrosQuality: "",
    },
    measurements : {
      neck: "",
      chest: "",
      rightArm: "",
      leftArm: "",
      waist1: "",
      waist2: "",
      waist3: "",
      hip: "",
      rightLeg: "",
      leftLeg: "",
      rightTwin: "",
      leftTwin: ""
    }
  }



  constructor(obj){
    if(obj){
      this.idClient = obj.idClient ?? this.idClient
      this.idPhase = obj.idPhase ?? this.idPhase
      this.phaseNumber = obj.phaseNumber ?? this.phaseNumber
      //training
      this.training.trainingType = obj.training?.trainingType ?? this.training.trainingType 
      this.training.trainingSubType = obj.training?.trainingSubType ?? this.training.trainingSubType
      this.training.nutritionalPlans = obj.training?.nutritionalPlans ?? this.training.nutritionalPlans
      this.training.startDate = new Date(obj.training?.startDate) ?? this.training.startDate
      //check data
      this.check.validated = obj.check?.validated ?? this.check.validated
      this.check.startDate = obj.check?.startDate ?? this.check.startDate
      this.check.wrongValidation = obj.check?.wrongValidation ?? this.check.wrongValidation
      this.check.validatedDate = obj.check?.validatedDate ?? this.check.validatedDate
      this.check.validatedBy = obj.check?.validatedBy ?? this.check.validatedBy
      this.check.nutritionFeedack = obj.check?.nutritionFeedack ?? this.check.nutritionFeedack
      this.check.otherFeedack = obj.check?.otherFeedack ?? this.check.otherFeedack
      this.check.mfp = obj.check?.mfp ?? this.check.mfp
      //check questions
      this.check.questions.averageCurrentWeight = obj.check?.questions?.averageCurrentWeight ?? this.check.questions.averageCurrentWeight
      this.check.questions.averageWaistCurrent = obj.check?.questions?.averageWaistCurrent ?? this.check.questions.averageWaistCurrent
      this.check.questions.personalBest = obj.check?.questions?.personalBest ?? this.check.questions.personalBest
      this.check.questions.neat = obj.check?.questions?.neat ?? this.check.questions.neat
      this.check.questions.kcal = obj.check?.questions?.kcal ?? this.check.questions.kcal
      this.check.questions.protein = obj.check?.questions?.protein ?? this.check.questions.protein
      this.check.questions.carbohydrates = obj.check?.questions?.carbohydrates ?? this.check.questions.carbohydrates
      this.check.questions.fats = obj.check?.questions?.fats ?? this.check.questions.fats
      this.check.questions.exerciseWaistPainful = obj.check?.questions?.exerciseWaistPainful ?? this.check.questions.exerciseWaistPainful
      this.check.questions.levelAdherence = obj.check?.questions?.levelAdherence ?? this.check.questions.levelAdherence
      this.check.questions.whyThatNote = obj.check?.questions?.whyThatNote ?? this.check.questions.whyThatNote
      this.check.questions.whatDidWell = obj.check?.questions?.whatDidWell ?? this.check.questions.whatDidWell
      this.check.questions.improvements = obj.check?.questions?.improvements ?? this.check.questions.improvements
      this.check.questions.tweet = obj.check?.questions?.tweet ?? this.check.questions.tweet
      this.check.questions.miniVictory = obj.check?.questions?.miniVictory ?? this.check.questions.miniVictory
      this.check.questions.priority = obj.check?.questions?.priority ?? this.check.questions.priority
      this.check.questions.notes = obj.check?.questions?.notes ?? this.check.questions.notes
      this.check.questions.macrosQuality = obj.check?.questions?.macrosQuality ?? this.check.questions.macrosQuality
      //check measurements
      this.check.measurements.neck = obj.check?.measurements?.neck ?? this.check.measurements.neck
      this.check.measurements.chest = obj.check?.measurements?.chest ?? this.check.measurements.chest
      this.check.measurements.rightArm = obj.check?.measurements?.rightArm ?? this.check.measurements.rightArm
      this.check.measurements.leftArm = obj.check?.measurements?.leftArm ?? this.check.measurements.leftArm
      this.check.measurements.waist1 = obj.check?.measurements?.waist1 ?? this.check.measurements.waist1
      this.check.measurements.waist2 = obj.check?.measurements?.waist2 ?? this.check.measurements.waist2
      this.check.measurements.waist3 = obj.check?.measurements?.waist3 ?? this.check.measurements.waist3
      this.check.measurements.hip = obj.check?.measurements?.hip ?? this.check.measurements.hip
      this.check.measurements.rightLeg = obj.check?.measurements?.rightLeg ?? this.check.measurements.rightLeg
      this.check.measurements.leftLeg = obj.check?.measurements?.leftLeg ?? this.check.measurements.leftLeg
      this.check.measurements.rightTwin = obj.check?.measurements?.rightTwin ?? this.check.measurements.rightTwin
      this.check.measurements.leftTwin = obj.check?.measurements?.leftTwin ?? this.check.measurements.leftTwin
      //endPhase data
      this.endPhase.validated = obj.endPhase?.validated ?? this.endPhase.validated
      this.endPhase.startDate = obj.endPhase?.startDate ?? this.endPhase.startDate
      this.endPhase.wrongValidation = obj.endPhase?.wrongValidation ?? this.endPhase.wrongValidation
      this.endPhase.validatedDate = obj.endPhase?.validatedDate ?? this.endPhase.validatedDate
      this.endPhase.validatedBy = obj.endPhase?.validatedBy ?? this.endPhase.validatedBy
      this.endPhase.nutritionFeedack = obj.endPhase?.nutritionFeedack ?? this.endPhase.nutritionFeedack
      this.endPhase.otherFeedack = obj.endPhase?.otherFeedack ?? this.endPhase.otherFeedack
      this.endPhase.mfp = obj.endPhase?.mfp ?? this.endPhase.mfp
      //endPhase questions
      this.endPhase.questions.averageCurrentWeight = obj.endPhase?.questions?.averageCurrentWeight ?? this.endPhase.questions.averageCurrentWeight
      this.endPhase.questions.averageWaistCurrent = obj.endPhase?.questions?.averageWaistCurrent ?? this.endPhase.questions.averageWaistCurrent
      this.endPhase.questions.personalBest = obj.endPhase?.questions?.personalBest ?? this.endPhase.questions.personalBest
      this.endPhase.questions.neat = obj.endPhase?.questions?.neat ?? this.endPhase.questions.neat
      this.endPhase.questions.kcal = obj.endPhase?.questions?.kcal ?? this.endPhase.questions.kcal
      this.endPhase.questions.protein = obj.endPhase?.questions?.protein ?? this.endPhase.questions.protein
      this.endPhase.questions.carbohydrates = obj.endPhase?.questions?.carbohydrates ?? this.endPhase.questions.carbohydrates
      this.endPhase.questions.fats = obj.endPhase?.questions?.fats ?? this.endPhase.questions.fats
      this.endPhase.questions.exerciseWaistPainful = obj.endPhase?.questions?.exerciseWaistPainful ?? this.endPhase.questions.exerciseWaistPainful
      this.endPhase.questions.levelAdherence = obj.endPhase?.questions?.levelAdherence ?? this.endPhase.questions.levelAdherence
      this.endPhase.questions.whyThatNote = obj.endPhase?.questions?.whyThatNote ?? this.endPhase.questions.whyThatNote
      this.endPhase.questions.whatDidWell = obj.endPhase?.questions?.whatDidWell ?? this.endPhase.questions.whatDidWell
      this.endPhase.questions.improvements = obj.endPhase?.questions?.improvements ?? this.endPhase.questions.improvements
      this.endPhase.questions.tweet = obj.endPhase?.questions?.tweet ?? this.endPhase.questions.tweet
      this.endPhase.questions.miniVictory = obj.endPhase?.questions?.miniVictory ?? this.endPhase.questions.miniVictory
      this.endPhase.questions.priority = obj.endPhase?.questions?.priority ?? this.endPhase.questions.priority
      this.endPhase.questions.notes = obj.endPhase?.questions?.notes ?? this.endPhase.questions.notes
      this.endPhase.questions.macrosQuality = obj.endPhase?.questions?.macrosQuality ?? this.endPhase.questions.macrosQuality
      //endPhase measurements
      this.endPhase.measurements.neck = obj.endPhase?.measurements?.neck ?? this.endPhase.measurements.neck
      this.endPhase.measurements.chest = obj.endPhase?.measurements?.chest ?? this.endPhase.measurements.chest
      this.endPhase.measurements.rightArm = obj.endPhase?.measurements?.rightArm ?? this.endPhase.measurements.rightArm
      this.endPhase.measurements.leftArm = obj.endPhase?.measurements?.leftArm ?? this.endPhase.measurements.leftArm
      this.endPhase.measurements.waist1 = obj.endPhase?.measurements?.waist1 ?? this.endPhase.measurements.waist1
      this.endPhase.measurements.waist2 = obj.endPhase?.measurements?.waist2 ?? this.endPhase.measurements.waist2
      this.endPhase.measurements.waist3 = obj.endPhase?.measurements?.waist3 ?? this.endPhase.measurements.waist3
      this.endPhase.measurements.hip = obj.endPhase?.measurements?.hip ?? this.endPhase.measurements.hip
      this.endPhase.measurements.rightLeg = obj.endPhase?.measurements?.rightLeg ?? this.endPhase.measurements.rightLeg
      this.endPhase.measurements.leftLeg = obj.endPhase?.measurements?.leftLeg ?? this.endPhase.measurements.leftLeg
      this.endPhase.measurements.rightTwin = obj.endPhase?.measurements?.rightTwin ?? this.endPhase.measurements.rightTwin
      this.endPhase.measurements.leftTwin = obj.endPhase?.measurements?.leftTwin ?? this.endPhase.measurements.leftTwin
    }
  }

}

export default Phase